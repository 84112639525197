<template>
  <div>
    <spacer :y="5"/>
    <ul>

      <stack-item id="risk-management-content-approved">
        <contents-box title="リスク報告済">
          <p
            v-if="pagination.approved.total > 0"
            :class="$style.bold"
          >{{ pagination.approved.total }}件</p>
          <pagination
            v-if="pagination.approved.lastPage > 1"
            :page="pagination.approved.page"
            :pageMax="pagination.approved.lastPage"
            :needEmit="true"
            v-on:click-page="movePage($event, 'approved')"
          />
          <loader-simple :flag="flag.loader">
            <div>
              <spacer :y="2"/>
              <ul v-if="riskApplications.approved.length > 0">
                <stack-item
                  v-for="row in riskApplications.approved"
                  :key="row.id"
                >
                  <risk-application-box
                    :riskApplication="row"
                    :targetUserLink="getTargetUserLink(row.target_user_id)"
                    :manageReadFlag="isRiskApplicationNotRefered(row.id)"
                    @risk-application-message-posted="getRiskApplications"
                  >
                    <template v-slot:btn>
                      <btn-container>
                        <basic-btn
                          tag="button"
                          size="sm"
                          v-on:click="clickRefer(row)"
                        >リスク報告書</basic-btn>
                      </btn-container>
                    </template>
                  </risk-application-box>
                </stack-item>
              </ul>
              <p v-if="!flag.loader && riskApplications.approved.length === 0">該当するリスク報告はありません</p>
              <spacer :y="2"/>
              <pagination
                v-if="!flag.loader && pagination.approved.lastPage > 1"
                :page="pagination.approved.page"
                :pageMax="pagination.approved.lastPage"
                :needEmit="true"
                v-on:click-page="movePage($event)"
              />
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>

    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import Spacer from '@/views/components/Spacer.vue';
import RiskApplicationBox from '@/views/components/RiskApplicationBox.vue';
import Pagination from '@/views/components/Pagination.vue';

import cf from '@/mixins/commonFunctions.js';
import raf from '@/mixins/riskApplicationFunctions';

export default {
  name: 'teacher-risk-management',
  mixins: [
    cf,
    raf,
  ],
  components: {
    StackItem,
    ContentsBox,
    LoaderSimple,
    BtnContainer,
    BasicBtn,
    Spacer,
    RiskApplicationBox,
    Pagination,
  },
  data() {
    return {
      flag: {
        loader: true,
      },
      riskApplications: {
        approved: [], // 承認済み
      },
      pagination: {
        approved: {
          page: 1, // 現在ページ
          perPage: 10, // ページ毎件数
          lastPage: 1, // 最後ページ(データ取得の都度更新)
          total: 0, // 総件数(データ取得の都度更新)
        },
      },
      referedIds: [], // 当コンポーネントから詳細を呼び出したリスク報告書id
    };
  },
  beforeCreate() {
    // リスク評価タブ -> [popup]リスク報告書 -> 上部のTOPリンク などの経路で、子画面として表示したwindowがメイン画面として生き続ける場合、window.openのターゲットに再びなってしまうケースがある
    // これを防ぐため、リスク報告書をpopupする基点となる画面の初期化処理でwindow.nameに固定の文字列をセットする
    window.name = 'wtt-teacher-risk-management';
  },
  created() {
    if (this.user && this.user.email) {
      this.getRiskApplications();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getRiskApplications();
        }
      });
    }
  },
  computed: {
    ...mapState(['helper', 'user']),
    isRiskApplicationNotRefered() {
      // 当コンポーネントから詳細表示されていない場合は true（実際のreadFlags値によってNEWを表示するか決定する）
      // 当コンポーネントから詳細表示されている場合は false（NEWを非表示化する）
      return (id) => !this.referedIds.includes(id);
    },
  },
  methods: {
    getTargetUserLink(userId) {
      return `/teacher/history/${userId}`;
    },
    async getRiskApplications() {
      this.flag.loader = true;
      // 表示情報初期化
      this.riskApplications.approved = [];
      try {
        // リスク報告書を取得する
        const response = await this.axios({
          method: 'GET',
          url: '/v1/riskApplications/get/list',
          params: {
            statuses: [
              this.helper.master.labels.riskApplication.status.APPROVED,
            ],
            page: this.pagination.approved.page,
            perPage: this.pagination.approved.perPage,
            with_reviewer_user: 0,
            with_counseling_data: 1,
            school_id: this.user.school[0].id,
          },
        });
        const result = response.data.data; // ページ情報なども含むオブジェクト
        // pagination情報の更新
        this.pagination.approved.lastPage = result.lastPage;
        this.pagination.approved.total = result.total;
        // 実データを格納
        this.riskApplications.approved = result.data;
        // 画面表示の準備完了
        this.flag.loader = false;
      } catch (error) {
        console.error(error);
        alert('画面表示情報の取得に失敗しました');
        throw error;
      }
    },
    clickRefer(riskApplication) {
      const childWindowName = raf.getChildWindowName({
        counselingType: riskApplication.counseling_type,
        foreignId: riskApplication.foreign_id,
      });
      // 別タブで開く
      window.open(`/teacher/risk-applications/${riskApplication.id}`, childWindowName);
      // 詳細表示済みidとして格納する
      this.referedIds.push(riskApplication.id);
    },
    /** ページ移動 */
    movePage(page) {
      if (this.pagination.approved.page === page) {
        return;
      }
      // 現在ページ変更
      this.pagination.approved.page = page;
      // データ取得
      this.getRiskApplications();
      // スクロール
      document.getElementById('risk-management-content-approved').scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" module>
.bold {
  font-weight: bold;
  font-size: 1.5em;
}
</style>

<template>
  <div :class="$style.userlist">
    <spacer :y="5"/>
    <ul>
      <stack-item>
        <contents-box type="inner">
          <div :class="$style.regist">
            <p :class="$style.regist_title">アカウント登録</p>
            <basic-btn to="/operator/regist-user/">登録する</basic-btn>
          </div>
        </contents-box>
      </stack-item>

      <stack-item>
        <contents-box title="検索">
          <form v-on:submit="registKeyword">
            <div :class="$style.wordsearch">
              <div :class="$style.wordsearch_form">
                <word-search-form
                  placeholder="キーワードで検索"
                  v-on:sendValue="receiveValue" />
              </div>
              <i
                :class="$style.wordsearch_btn"
                class="fa-regular fa-magnifying-glass"
                v-on:click="registKeyword"/>
            </div>
          </form>
          <spacer :y="2"/>
          <div>
            <!-- アカウントタイプ -->
            <ul :class="$style.select">
              <li v-for="item in accountTypeItems" :key="item">
                <input
                  type="checkbox"
                  :id="item.name"
                  :value="item.value"
                  v-on:change="updateType($event, 'account')">
                <label :for="item.name">{{ item.label }}</label>
              </li>
            </ul>
            <spacer :y="2"/>
            <!-- 学校タイプ -->
            <ul :class="$style.select">
              <li v-for="item in schoolTypeItems" :key="item">
                <input
                  type="checkbox"
                  :id="item.name"
                  :value="item.value"
                  v-on:change="updateType($event, 'school')">
                <label :for="item.name">{{ item.label }}</label>
              </li>
            </ul>
            <spacer :y="2"/>
            <!-- 非アクティブユーザーの表示 -->
            <ul :class="$style.select">
              <li v-for="item in activeTypeItems" :key="item">
                <input
                  type="checkbox"
                  :id="item.name"
                  :value="item.value"
                  v-on:change="updateType($event, 'active')">
                <label :for="item.name">{{ item.label }}</label>
              </li>
            </ul>
            <spacer :y="2"/>
            <ul :class="$style.select">
              <li v-for="item in temporaryTypeItems" :key="item">
                <input
                  type="checkbox"
                  name="temporary"
                  :id="item.name"
                  :value="item.value"
                  @change="updateType($event, 'temporary')">
                <label :for="item.name">{{ item.label }}</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  name="riskAlert"
                  id="riskAlert"
                  v-model="riskAlert">
                <label for="riskAlert">リスク案件</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  name="kanasort"
                  id="kanasort"
                  v-model="kanasort">
                <label for="kanasort">五十音順</label>
              </li>
            </ul>
          </div>
          <spacer :y="2"/>
          <div :class="$style.birth">
            <dl>
              <dt><i class="fa-solid fa-calendar-days"></i>生年月日</dt>
              <dd>
                <div>
                  <date-small-picker
                    :name="'startBirthday'"
                    v-on:sendDate="receiveDate" />
                </div>
                  <date-small-picker
                    :name="'endBirthday'"
                    v-on:sendDate="receiveDate" />
              </dd>
            </dl>
            <spacer :y="2"/>
            <dl>
              <dt><i class="fa-solid fa-calendar-plus"></i>登録年月日</dt>
              <dd>
                <div>
                  <date-small-picker
                    :name="'startCreatedAt'"
                    v-model="startCreatedAt"
                    v-on:sendDate="receiveDate, autoEnter()" />
                </div>
                  <date-small-picker
                    :name="'endCreatedAt'"
                    v-model="endCreatedAt"
                    v-on:sendDate="receiveDate" />
              </dd>
            </dl>
            <spacer :y="2"/>
            <dl>
              <dt>学校ソート</dt>
              <dd>
                <!-- セレクト -->
                <select
                  :class="$style.select_box"
                  name="school"
                  id="school"
                  v-on:change="updateSchool">
                  <option :value="0" :selected="targetSchool === 0">選択してください</option>
                  <option
                    v-for="n of schoolsList"
                    :key="n"
                    :value="n.id"
                    v-show="targetSchoolType.includes(n.school_type) || targetSchoolType.length === 5"
                    :selected="n.id === targetSchool">{{ n.name }}</option>
                </select>
              </dd>
              <dt :class="$style.school_year">学年</dt>
              <dd>
                <select
                  :class="$style.select_box"
                  name="schoolYear"
                  id="schoolYear"
                  v-on:change="updateSchoolYear">
                  <option :value="null" :selected="schoolYear === 0">選択なし</option>
                  <option
                    v-for="n of 6"
                    :key="n"
                    :value="n">{{ n }}</option>
                  <option :value="0">登録なし</option>
                </select>
              </dd>
            </dl>

          </div>
        </contents-box>
      </stack-item>

      <hr id="scroll-top">
      <spacer :y="3"/>

      <stack-item>
        <contents-box :noHeaderSpacer="true">
          <loader-simple :flag="flag.loader">
            <div :class="$style.label">
              <p>アカウント一覧</p>
              <p :class="$style.large"
                v-if="!flag.loader">{{ `${user.counseling.op.user.customerOptions.total}件` }}</p>
              <!-- <p :class="$style.bulk_edit">
                <basic-btn
                  tag="button"
                  @click="bulkEdit()">学年一括編集</basic-btn>
              </p> -->
            </div>
            <spacer :y="2"/>
            <ul v-if="!flag.loader
              && user.counseling.op.user.customer[page]
              && user.counseling.op.user.customer[page].length">
              <stack-item space="2"
                v-for="(userData, index) in user.counseling.op.user.customer[page]"
                :key="userData"
                :id="userData.id">
                <user-box
                  type="inner"
                  :showId="true"
                  :user="userData"
                  :showMessageBtn="true"
                  :showCocomoniBtn="true"
                  :ownUser="user"
                  :bg="userBoxColor(userData)"
                  :link="`/operator/history/${userData.rawId || userData.id}`"
                  :targetBlank="true">
                  <template v-slot:btn >
                    <div :class="$style.icons">
                      <p
                        :class="$style.invalid_school"
                        v-if="userData.school && userData.school.length && userData.school[0].flag !== 1">学校非アクティブ</p>
                      <basic-btn
                        v-if="userData.school && userData.school.length && userData.school[0].flag === 1"
                        tag="button"
                        type="bdr"
                        size="sm"
                        v-on:click="changeStatusFlag(userData)">{{ userData.flag === 1 ? '非アクティブにする' : 'アクティブにする'}}</basic-btn>
                      <spacer :x="2" v-if="!openIds.includes(userData.id)
                          && userData.flag !== 998
                          && userData.temporary !== 1" :class="$style.only_pc"/>
                      <!-- <basic-btn
                        tag="button"
                        v-if="!openIds.includes(userData.id)
                          && userData.flag !== 998
                          && userData.temporary !== 1"
                        size="sm"
                        addClass="alert"
                        v-on:click="setRiskLevel($event, {
                          id: userData.id,
                          username: userData.username,
                          risks: userData.risk,
                        })">リスク{{ userData.risk.length && userData.risk[userData.risk.length - 1].type !== 11 ? '解除' : '案件' }}</basic-btn> -->
                      <spacer :x="2" v-if="!openIds.includes(userData.id)" :class="$style.only_pc"/>
                      <basic-btn
                        tag="button"
                        v-if="!openIds.includes(userData.id)"
                        size="sm"
                        addClass="danger"
                        v-on:click="deleteUser($event, {
                          id: userData.id,
                          username: userData.username,
                          email: userData.email,
                        })">削除</basic-btn>
                      <spacer :y="1" :class="$style.only_sp"/>
                      <div @click="saveConditions(replaceHighlight(userData.id))">
                        <i
                          v-if="openIds.includes(userData.id)"
                          :class="$style.open"
                          class="fa-solid fa-pen-to-square"></i>
                      </div>
                      <i
                        v-if="openIds.includes(userData.id)"
                        :class="$style.open"
                        class="fa-regular fa-minus"
                        v-on:click="changeOpenIds(userData.id)"></i>
                      <i
                        v-if="!openIds.includes(userData.id)"
                        :class="$style.open"
                        class="fa-regular fa-plus"
                        v-on:click="changeOpenIds(userData.id)"></i>
                    </div>
                  </template>
                  <template v-slot:main v-if="openIds.includes(userData.id)">
                    <spacer :y="3"/>
                    <!-- ひとこと　を非表示 -->
                    <!-- <p>{{ userData.comment }}</p> -->
                    <spacer :y="3"/>
                    <ul :class="$style.detail">
                      <li v-if="userData.school[0].billing_type.billing_type === 2 && userData.temporary">
                        <p :class="$style.content">
                          <i :class="$style.icon" class='fa-regular fa-qrcode'></i>
                          <a
                            :href="`${baseURL}/signup2/?customer=${userData.id}&school=${userData.school[0].id}`"
                            target="_blank">{{ baseURL }}/signup2/?customer={{ userData.id }}&school={{ userData.school[0].id }}</a>
                        </p>
                      </li>
                      <spacer v-if="userData.school[0].billing_type.billing_type === 1 && userData.temporary" :y="2" />
                      <li v-if="userData.school[0].billing_type.billing_type === 2 && userData.temporary">
                        <p :class="$style.content">
                          <img :src="`https://api.qrserver.com/v1/create-qr-code//?size=150x150&data=${baseURL}/signup2/?school=${userData.school[0].id}%26customer=${userData.id}`">
                        </p>
                      </li>
                      <li>
                        <i :class="$style.icon" class="fa-solid fa-right-to-bracket"></i>
                        <p v-if="userData.created_at">{{ formatTimestamp(userData.created_at, 'YYYY年MM月DD日') }} 登録</p>
                      </li>
                      <li>
                        <i :class="$style.icon" class="fa-regular fa-envelope"></i>
                        <p v-if="userData.email" v-html="userData.email"></p>
                        <p v-if="!userData.email">登録なし</p>
                      </li>
                      <li>
                        <i :class="$style.icon" class="fa-regular fa-cake-candles"></i>
                        <p v-if="userData.birthday">{{ formatTimestamp(userData.birthday, 'YYYY年MM月DD日') }}</p>
                        <p v-if="!userData.birthday">登録なし</p>
                      </li>
                      <li>
                        <i :class="$style.icon" class="fa-solid fa-phone"></i>
                        <p v-if="userData.tel" v-html="userData.tel"></p>
                        <p v-if="!userData.tel">登録なし</p>
                      </li>
                      <li>
                        <i :class="[$style.icon, $style.virtual]" class="fa-solid fa-vr-cardboard"></i>
                        <p>
                          <a
                            v-if="userData.virtualCastProp && userData.virtualCastProp.virtual_cast_id"
                            v-bind:href="getVirtualCastUserURL(userData.virtualCastProp.virtual_cast_id)"
                            target="_blank"
                          >{{ getVirtualCastUserURL(userData.virtualCastProp.virtual_cast_id) }}</a>
                          <span v-else>登録なし</span>
                        </p>
                      </li>
                      <li>
                        <i :class="[$style.icon, $style.badge]" class="fa-regular fa-id-badge"></i>
                        <p v-if="userData.student && userData.student.student_id">{{ userData.student.student_id }}</p>
                        <p v-else>登録なし</p>
                      </li>
                      <li>
                        <i :class="$style.icon" class="fa-regular fa-house"></i>
                        <p v-if="userData.address">{{ userData.address.pref }}{{ userData.address.city }}{{ userData.address.address }}{{ userData.address.building }}</p>
                        <p v-if="!userData.address">登録なし</p>
                      </li>
                      <li v-if="userData.school[0].billing_type.billing_type === 2">
                        <i :class="$style.icon" class="fa-solid fa-clock-rotate-left"></i>
                        <div :class="$style.flex_elem">
                          <p>履歴</p>
                          <spacer :x="2"/>
                          <i
                            v-if="!userData.showFlag"
                            :class="$style.icon_polarity"
                            class="SchoolList_count_2I5R7 fa-solid fa-plus"
                            @click="openHistory(index, userData.id)">
                            </i>
                          <i
                            v-else
                            :class="$style.icon_polarity"
                            class="SchoolList_count_2I5R7 fa-solid fa-minus"
                            @click="userData.showFlag = false"></i>
                        </div>
                      </li>
                      <li v-if="userData.showFlag" :class="$style.noflex_list">
                        <i :class="$style.icon" class="fa-solid fa-calculator-simple"></i>
                        <span>カウント編集・使用履歴</span>
                        <div v-if="userData.countHistory">
                          <table v-if="userData.countHistory.length" :class="$style.noflex_table">
                            <tr><td>編集日・予約日</td><td>実施日</td><td>編集者・予約先</td><td>内容</td></tr>
                            <tr v-for="history in userData.countHistory" :key="history">
                              <td v-if="history.registDate">{{history.registDate}}</td>
                              <td v-if="history.reserveDate">{{history.reserveDate}}</td>
                              <td v-if="history.target">{{history.target}}</td>
                              <td v-if="history.countState">{{history.countState}}</td>
                            </tr>
                          </table>
                          <div v-else :class="$style.noflex_nohistory">履歴なし</div>
                        </div>
                      </li>
                      <li>
                        <i :class="$style.icon" class="fa-solid fa-triangle-exclamation"></i>
                        <span>リスク案件履歴</span>
                        <spacer :x="2" />
                        <i
                          v-if="userData && !userData.showRisk"
                          :class="$style.icon_polarity"
                          class="SchoolList_count_2I5R7 fa-solid fa-plus"
                          @click="openRisk(index)">
                          </i>
                        <i
                          v-else
                          :class="$style.icon_polarity"
                          class="SchoolList_count_2I5R7 fa-solid fa-minus"
                          @click="userData.showRisk = false"></i>
                      </li>
                      <li v-if="userData && userData.showRisk" :class="$style.noflex_list">
                        <table v-if="userData.risk.length" :class="$style.risk_table">
                          <tr><td>登録日</td><td :class="$style.risk_table_next">ステータス</td></tr>
                          <tr v-for="risk in userData.risk" :key="risk">
                            <td>{{ formatDate(risk.created_at) }}</td>
                            <td :class="$style.risk_table_next">{{ risk.type === 11 ? '解除' : `${helper.master.labels.riskApplication.riskType[risk.type].label}報告` }}</td>
                          </tr>
                        </table>
                        <p v-else :class="$style.risk_table_next">登録履歴なし</p>
                      </li>
                      <li>
                        <i :class="$style.icon" class="fa-solid fa-user-group"></i>
                        <span>グループ情報</span>
                        <spacer :x="2"/>
                          <i
                            v-if="!userData.showGroup"
                            :class="$style.icon_polarity"
                            class="SchoolList_count_2I5R7 fa-solid fa-plus"
                            @click="openGroup(index)">
                            </i>
                          <i
                            v-else
                            :class="$style.icon_polarity"
                            class="SchoolList_count_2I5R7 fa-solid fa-minus"
                            @click="userData.showGroup = false"></i>
                      </li>
                      <li v-if="userData.showGroup" :class="$style.noflex_list">
                        <div v-if="userData.unit.length" :class="$style.space_left">
                          <ul>
                            <li v-for="group in userData.unit"
                              :key="group">
                              {{ group.label }}
                              </li>
                          </ul>
                        </div>
                        <div v-else :class="$style.space_left">グループ無所属</div>
                      </li>
                    </ul>
                  </template>
                </user-box>
              </stack-item>
            </ul>
          </loader-simple>

          <spacer :y="4"/>
          <pagination
            v-if="!flag.loader
              && user.counseling.op.user.customerOptions.pageMax
              && user.counseling.op.user.customerOptions.pageMax !== 1"
            :page="page"
            :pageMax="user.counseling.op.user.customerOptions.pageMax"
            :path="'/operator/?tab=users'" />
        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import UserBox from '@/views/components/UserBox.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import WordSearchForm from '@/views/components/WordSearchForm.vue';
import DateSmallPicker from '@/views/components/DateSmallPicker.vue';
import cf from '@/mixins/commonFunctions.js';
import moment from 'moment';
import Pagination from '@/views/components/Pagination.vue';

export default {
  name: 'operator-user-list',
  mixins: [cf],
  components: {
    BasicBtn,
    UserBox,
    ContentsBox,
    StackItem,
    LoaderSimple,
    WordSearchForm,
    DateSmallPicker,
    Spacer,
    Pagination,
  },
  data() {
    return {
      flag: {
        loader: true,
        pageQuery: false,
        userGet: true,
      },
      // users: [],
      cloneUsers: [],
      openIds: [],
      keyword: null,
      targetColumn: [],
      startBirthday: null,
      endBirthday: null,
      startCreatedAt: null,
      endCreatedAt: null,
      kanasort: false,
      temporaryTypeItems: [
        {
          name: 'temporaryOnly',
          label: '仮登録',
          value: 1,
          checked: false,
        },
        {
          name: 'doneOnly',
          label: '本登録',
          value: 2,
          checked: false,
        },
      ],
      activeTypeItems: [ // 順番固定 computed?
        {
          name: 'nonActiveUser',
          label: '非アクティブユーザー含む',
          value: 1,
          checked: false,
        },
        {
          name: 'nonActiveSchool',
          label: '非アクティブ学校のユーザー含む',
          value: 2,
          checked: false,
        },
      ],
      accountTypeItems: [
        {
          name: 'user',
          label: '在学生・プログラム参加者',
          value: 1,
          checked: false,
        },
        {
          name: 'parent',
          label: '保護者',
          value: 2,
          checked: false,
        },
        {
          name: 'teacher',
          label: '教職員・企業担当者',
          value: 3,
          checked: false,
        },
      ],
      schoolTypeItems: [
        {
          name: 'personal',
          label: '個人向け',
          value: 0,
          checked: false,
        },
        {
          name: 'elementary',
          label: '小学校',
          value: 1,
          checked: false,
        },
        {
          name: 'juniorHigh',
          label: '中学校',
          value: 2,
          checked: false,
        },
        {
          name: 'high',
          label: '高校',
          value: 3,
          checked: false,
        },
        {
          name: 'univ',
          label: '専門・短大・大学',
          value: 4,
          checked: false,
        },
        {
          name: 'free',
          label: 'フリースクール',
          value: 5,
          checked: false,
        },
      ],
      schoolsList: [],
      targetSchool: 0,
      schoolYear: null,
      riskAlert: false,
      page: 1,
      // pageMax: 1,
      limit: 20,
      // total: 0,
    };
  },
  created() {
    const query = this.$route.query;
    if (query.page) {
      this.page = Number(query.page);
    } else if (query.tab === 'users') {
      // 利用者更新後であれば対象の利用者を表示するためページ数引き継ぎ
      if (this.$store.state.searchConditions && this.$store.state.searchConditions.page >= 2) this.$router.replace(`/operator/?tab=users&page=${this.$store.state.searchConditions.page}`);
      // queryにpageがなければ追加してreplace
      else this.$router.replace('/operator/?tab=users&page=1');
    }
    // 利用者情報更新以外のページから遷移した際に検索条件を引き継がせないため
    if (!this.$store.state.beforePage || this.$store.state.beforePage !== 'UpdateUser') delete this.$store.state.searchConditions;
    this.getUsers();
    this.getSchools();
  },
  unmounted() {
    if (this.$store.state.beforePage) delete this.$store.state.beforePage;
  },
  watch: {
    $route() {
      this.flag.loader = true;
      const query = this.$route.query;
      if (query.page) {
        this.page = Number(query.page);
      } else if (query.tab === 'users') {
        // 利用者更新後であれば対象の利用者を表示するためページ数引き継ぎ
        if (this.$store.state.searchConditions && this.$store.state.searchConditions.page >= 2) this.$router.replace(`/operator/?tab=users&page=${this.$store.state.searchConditions.page}`);
        // queryにpageがなければ追加してreplace
        else this.$router.replace('/operator/?tab=users&page=1');
      }
      this.getUsers();
      this.flag.pageQuery = true;
    },
  },
  computed: {
    ...mapState(['user', 'helper']),
    baseURL() {
      return `${location.protocol}//${location.host}`;
    },
    targetAccountType() {
      let array = [];
      let all = true;
      this.accountTypeItems.forEach((row) => {
        if (row.checked) {
          all = false;
          array.push(row.value);
        }
      });
      if (all) array = [1, 2, 3];
      return array;
    },
    targetSchoolType() {
      let array = [];
      let all = true;
      this.schoolTypeItems.forEach((row) => {
        if (row.checked) {
          all = false;
          array.push(row.value);
        }
      });
      if (all) array = [0, 1, 2, 3, 4, 5];
      return array;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** ハイライトされたIDを数値化 */
    strintToNumber(data) {
      let num;
      if (typeof data === 'string') {
        num = Number(data.replace('<span class="highlight">', '').replace('</span>', ''));
      } else if (typeof data === 'number') {
        num = data;
      }
      return num;
    },

    getSchools() {
      this.axios({
        method: 'GET',
        url: '/v1/school/get/list',
        params: {
          isUsers: 0,
        },
      })
        .then((response) => {
          const res = response.data.data;
          this.schoolsList = res.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    updateSchool(e) {
      this.targetSchool = Number(e.target.value);
      if (!Number(e.target.value)) this.targetSchool = null;
      this.deleteConditions();
    },

    updateSchoolYear(e) {
      this.schoolYear = Number(e.target.value);
      if (e.target.value === '選択なし') this.schoolYear = null;
      this.deleteConditions();
    },

    receiveValue(data) {
      const userIdRegex = new RegExp(/^[0-9０-９]{4}(-|ー|−|―|‐|-|ｰ)[0-9０-９]{1,}(-|ー|−|―|‐|-|ｰ)[0-9０-９]{4}$/);
      if (userIdRegex.test(data.value)) {
        const userId = data.value.split(/[-|ー|−|―|‐|-|ｰ]/);
        this.keyword = String(Number(this.toHalfNumber(userId[2]))); // user.id指定
        this.targetColumn = ['id', 'old_id', 'tel'];
      } else {
        this.keyword = data.value;
        this.targetColumn = ['username', 'kana', 'email', 'tel', 'id', 'old_id'];
      }
      this.deleteConditions();
    },

    toHalfNumber(str) {
      return str.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
    },

    registKeyword(e) {
      e.preventDefault();
      if (this.keyword === '') this.keyword = null;
      this.resetStore();
      this.getUsers();
    },

    /** userBox背景色指定用 */
    userBoxColor(user) {
      let color;
      if ((user.flag !== 1 || user.school) && user.school.length && user.school[0].flag !== 1) {
        color = 'gray';
      } else if (user.flag !== 1) {
        color = 'gray';
      } else if (user.temporary) {
        color = 'silver';
      } else if (user.risk.length && user.risk[user.risk.length - 1].type !== 11) {
        color = 'red';
      } else {
        color = 'white';
      }
      return color;
    },

    /** 誕生日と登録日の始点と終点取得 */
    receiveDate(args) {
      if (args.date === 'Invalid date') this[args.name] = null;
      else this[args.name] = args.date;
      this.deleteConditions();
    },

    /** 登録日年月日の開始点のみ入力されたら終点を自動入力 */
    autoEnter() {
      if (this.startCreatedAt && !this.endCreatedAt) {
        // 終点に今日を自動で入力
        this.endCreatedAt = moment(new Date()).format('YYYY-MM-DD');
      }
    },

    /** タイプによる絞り込み(アカウント・学校) */
    updateType(e, name) {
      this[`${name}TypeItems`].forEach((row) => {
        if (e.target.id === row.name) row.checked = e.target.checked;
      });
      this.deleteConditions();
    },

    searchSchool() {
      const array = [];
      this.schoolsList.forEach((row) => {
        if (this.targetSchoolType.includes(row.school_type)) {
          array.push(row);
        }
      });
      this.schoolsList = array;
    },

    // アカウント課金モデルの履歴のボタンが押されると内容を取得して表示する
    openHistory(index, id) {
      if (this.cloneUsers[index].showFlag) this.cloneUsers[index].showFlag = false;
      else this.cloneUsers[index].showFlag = true;
      this.getCountHistory(index, id);
    },

    /** アカウント課金モデルの利用者毎のカウント追加履歴を取得 */
    getCountHistory(index, id) {
      const params = {
        foreign_id: cf.methods.replaceHighlight(id),
        billing_type: 2,
        sort: true,
        withReserve: true,
        withCreditUser: true,
      };
      this.axios({
        method: 'get',
        url: 'v1/count/history/get/list',
        params,
      })
        .then(async (response) => {
          const res = response.data.countHistories.data;
          this.cloneUsers[index].countHistory = [];
          res.forEach((history) => {
            const userHistory = {};
            // カウント消費履歴
            if (history.reserve) { // reserveが存在していればカウント消費
              // return true;
              userHistory.reserveDate = moment(history.reserve.schedule.date).format('YYYY年MM月DD日');
              if (history.reserve.toUser && history.reserve.toUser.username) userHistory.target = history.reserve.toUser.username;
              else userHistory.target = 'なし';
            }
            // カウント編集履歴
            if (!history.reserve) { // reserveなければカウント追加
              userHistory.reserveDate = moment(history.created_at).format('YYYY年MM月DD日');
              if (history.creditUser && history.creditUser.username) {
                userHistory.target = history.creditUser.account_type >= 30 ? '事務局' : history.creditUser.username;
              } else {
                userHistory.target = '事務局';
              }
            }
            if (history.count) {
              // 事務局、学校管理者からの編集と利用者の利用を分けてラベル付け
              if (history.reserve) {
                userHistory.countState = `${history.consultation_type === 1 ? '心理士' : '医師'} ${history.count}回 使用`;
              }
              if (!history.reserve) {
                userHistory.countState = `${history.consultation_type === 1 ? '心理士' : '医師'} ${history.count}回 ${history.is_add ? '追加' : '削除'}`;
              }
            } else {
              userHistory.countState = '予約キャンセル';
            }
            userHistory.registDate = moment(history.created_at).format('YYYY年MM月DD日');
            this.cloneUsers[index].countHistory.push(userHistory);
          });
          this.cloneUsers[index].countHistory.reverse();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /** 利用者のグループ情報表示 */
    openGroup(index) {
      if (this.cloneUsers[index].showGroup) this.cloneUsers[index].showGroup = false;
      else this.cloneUsers[index].showGroup = true;
    },

    /** 利用者のリスク登録履歴表示 */
    openRisk(index) {
      if (this.cloneUsers[index].showRisk) this.cloneUsers[index].showRisk = false;
      else this.cloneUsers[index].showRisk = true;
    },

    /** 日付の形状操作 */
    formatDate(date) {
      return moment(date).format('YYYY年MM月DD日 HH時mm分');
    },

    getUsers() {
      // 重複した読み込みを防ぐ（createdとwatch等）
      if (!this.flag.userGet) return;
      // すでにstore登録されている場合もスルー
      if (this.user.counseling.op.user.customer[this.page]
          && this.user.counseling.op.user.customer[this.page].length) {
        this.scroll2Top();
        this.flag.loader = false;
        return;
      }

      this.flag.userGet = false;
      this.flag.loader = true;

      let params;
      if (this.$store.state.searchConditions) {
        params = this.$store.state.searchConditions;
        if (params.page !== this.$route.query.page) params.page = Number(this.$route.query.page);
      } else {
        params = {
          orderBy: 'desc',
          includeDeleted: this.activeTypeItems[0].checked ? 1 : 0,
          includeDeletedSchool: this.activeTypeItems[1].checked ? 1 : 0,
          school_id: this.targetSchool,
          school_year: this.schoolYear,
          targetColumn: this.keyword && this.keyword !== '' ? this.targetColumn : null,
          keyword: this.keyword && this.keyword !== '' ? this.keyword : null,
          accountTypes: this.targetAccountType,
          schoolTypes: this.targetSchoolType,
          isRisk: 1,
          riskAlert: this.riskAlert ? 1 : 0,
          betweenBirthday: [this.startBirthday, this.endBirthday],
          betweenCreatedAt: [this.startCreatedAt, this.endCreatedAt],
          temporaryOnly: this.temporaryTypeItems[0].checked ? 1 : 0,
          noTemporary: this.temporaryTypeItems[1].checked ? 1 : 0,
          page: this.page,
          limit: this.riskAlert ? 99999 : this.limit,
        };
        if (this.kanasort) { // 五十音順にチェックがあればあいうえおソート
          params.orderBy = 'asc';
          params.order = 'kana';
        }
      }

      this.axios({
        method: 'GET',
        url: '/v1/user/get/search',
        params,
      })
        .then((response) => {
          const res = response.data;
          const users = res.users.data;
          this.cloneUsers = res.users.data;
          if (users && users.length && this.keyword) {
            const searchTargets = [...this.targetColumn, 'customer_id'];
            users.forEach((user) => {
              searchTargets.forEach((column) => {
                if (user[column] && String(user[column]).includes(this.keyword)) {
                  if (column === 'id') user.rawId = Number(user[column]);
                  user[column] = String(user[column]).replace(new RegExp(this.keyword, 'g'), `<span class="highlight">${this.keyword}</span>`);
                }
              });
            });
          }

          const commitData = {
            type: 'user',
            division: 'customer',
            page: this.page,
            values: users,
            options: {
              name: 'customerOptions',
              items: [
                { key: 'total', value: res.users.total },
                { key: 'pageMax', value: res.users.lastPage },
              ],
            },
          };
          this.$store.commit('user/counseling/putOperatorDatas', commitData);

          // commitData.options.items[1].valueはmaxのページ数に当たる
          const pageMax = commitData.options.items[1].value;
          if (this.page > pageMax) {
            this.$router.push({ query: { tab: 'users', page: 1 } });
          }
          this.flag.loader = false;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(async () => {
          // 更新後であれば検索条件を引き継ぎ更新した利用者までスクロール
          if (this.$store.state.updatedUser && this.$store.state.searchConditions) {
            const elem = document.getElementById(`${this.$store.state.updatedUser}`);
            const position = elem.getBoundingClientRect().top;
            scrollBy(0, position - 77);
            await this.changeOpenIds(Number(this.$store.state.updatedUser));
          } else if (this.flag.pageQuery) this.scroll2Top();
          this.flag.pageQuery = false;
          this.flag.loader = false;
          this.flag.userGet = true;

          delete this.$store.state.updatedUser;
        });
    },

    // 絞り込み条件変更の際にstoreをリセット
    // 新しい条件で再取得
    resetStore() {
      const commitData = {
        type: 'user',
        division: 'customer',
        values: { 1: [] },
        options: {
          name: 'customerOptions',
          items: [
            { key: 'total', value: 0 },
            { key: 'pageMax', value: 1 },
          ],
        },
      };
      this.$store.commit('user/counseling/putOperatorDatas', commitData);
    },

    // 利用者更新を行う場合に検索条件を引き継ぐため、stateに条件を保持
    async saveConditions(id) {
      if (!this.$store.state.searchConditions) {
        const data = await {
          orderBy: 'desc',
          includeDeleted: this.activeTypeItems[0].checked ? 1 : 0,
          includeDeletedSchool: this.activeTypeItems[1].checked ? 1 : 0,
          school_id: this.targetSchool,
          school_year: this.schoolYear,
          targetColumn: this.keyword && this.keyword !== '' ? this.targetColumn : null,
          keyword: this.keyword && this.keyword !== '' ? this.keyword : null,
          accountTypes: this.targetAccountType,
          schoolTypes: this.targetSchoolType,
          betweenBirthday: [this.startBirthday, this.endBirthday],
          betweenCreatedAt: [this.startCreatedAt, this.endCreatedAt],
          page: this.page,
          limit: this.limit,
        };
        this.$store.state.searchConditions = data;
      }
      this.$router.push(`/operator/update-user/${id}/`);
    },

    // 検索条件を変更したときにstate.searchConditionsを削除
    deleteConditions() {
      delete this.$store.state.searchConditions;
    },

    changeOpenIds(id) {
      if (this.openIds.includes(id)) {
        const idx = this.openIds.indexOf(id);
        this.openIds.splice(idx, 1);
      } else {
        this.openIds.push(id);
      }
    },

    scroll2Top() {
      // 上までスクロール
      const elem = document.getElementById('scroll-top');
      if (!elem) return;
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },

    deleteUser(e, args) {
      e.preventDefault();
      const name = cf.methods.replaceHighlight(args.username, 'str');
      const deleteConfirm = confirm(`${name}さんの登録を削除します。よろしいですか？`);
      if (!deleteConfirm) return;

      this.showLoading();
      const id = cf.methods.replaceHighlight(args.id);
      const data = {
        id,
        flag: 999,
      };

      // 目的はメールアドレスの再利用なので、updateに統一
      // メールアドレスに乱数を付与して以後の重複を回避
      if (this.helper.env.name !== 'production') {
        const rdm = Math.floor(Math.random() * (101 - 1) + 1);
        data.email = `${args.email}${rdm}`;
      }

      // qrCustomersテーブルも変更しておかないとsignupできない
      this.axios({
        method: 'POST',
        url: '/v1/qrCustomer/set/updater',
        data: {
          email: args.email,
          updateEmail: data.email,
        },
      })
        .then((res) => { console.log(res.data); })
        .catch((err) => {
          if (err.message) console.log(err.message);
          else console.log(err);
        });

      // userテーブル更新
      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          alert(`${name}さんの登録を削除しました。`);
          // 再読み込み
          this.resetStore();
          this.getUsers();
        })
        .catch((error) => {
          alert(`${name}さんの削除に失敗しました。`);
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    // // リスク評価登録
    // setRiskLevel(e, args) {
    //   e.preventDefault();
    //   const name = cf.methods.replaceHighlight(args.username, 'str');
    //   const riskConfirm = confirm(`${name}さんのリスク案件の${args.risks.length && args.risks[args.risks.length - 1].type !== 11 ? '解除' : '登録'}します。よろしいですか？`);
    //   if (!riskConfirm) return true;

    //   // id検索でハイライトがついていた際に除去
    //   let id = args.id;
    //   if (isNaN(id) && id.includes('<span')) id = cf.methods.replaceHighlight(id);

    //   // typeはリスク登録、解除時に異なるのでuserのリスクレベルによってtypeは変動する
    //   const params = {
    //     type: args.risks.length && args.risks[args.risks.length - 1].type !== 11 ? 11 : 1,
    //     target_id: id,
    //     user_id: this.user.id,
    //   };

    //   this.axios({
    //     method: 'POST',
    //     url: '/v1/risk/set/register',
    //     params,
    //   })
    //     .then((response) => {
    //       const res = response.data.data;
    //       let target;
    //       if (isNaN(args.id) && args.id.includes('<span')) { // id検索によりハイライトがあるとidが一致しないため余計な文字列を除去して数値化
    //         target = this.user.counseling.op.user.customer[this.page].find((user) => cf.methods.replaceHighlight(user.id, 'str') === res.target_id);
    //       } else target = this.user.counseling.op.user.customer[this.page].find((user) => user.id === Number(res.target_id));
    //       target.risk.push({
    //         created_at: res.created_at,
    //         updated_at: res.updated_at,
    //         flag: Number(res.flag),
    //         target_id: Number(res.target_id),
    //         type: Number(res.type),
    //         user_id: Number(res.user_id),
    //       });
    //     })
    //     .catch((error) => {
    //       if (error.message) console.log(error.message);
    //       else console.log(error);
    //     });
    // },

    bulkEdit() {
      const data = this.user;
      const args = {
        modalName: 'bulk-edit-school-year',
        data,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },

    changeStatusFlag(user) {
      const label = user.flag === 1 ? '非アクティブ' : 'アクティブ';
      const name = cf.methods.replaceHighlight(user.username, 'str');
      if (!confirm(`このアカウントを${label}してよろしいですか？\n${name}`)) return;
      const id = cf.methods.replaceHighlight(user.id);
      const data = {
        id,
        flag: user.flag === 1 ? 998 : 1,
        account_type: user.account_type,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          this.resetStore();
          this.getUsers();
          alert(`${label}化が完了しました。`);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          alert('更新に失敗しました。');
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
// .userlist {
//   font-size: 14px;
// }
.regist {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_title {
    font-size: 20px;
    font-weight: bold;
  }
  @include sm-view {
    display: block;
  }
}
.space {
  &_left {
    margin-left: 40px;
  }
}
.bulk_edit {
  margin-left: auto;
}
.wordsearch {
  display: flex;
  align-items: center;
  &_form {
    flex: 1;
  }
  &_btn {
    background-color: var(--orange-main);
    color: #fff;
    margin-left: 10px;
    padding: 15px 30px;
    border-radius: 30px;
    font-weight: bold;
    cursor: pointer;
  }
}
.icons {
  display: flex;
  align-items: center;

  @include sm-view {
    margin-top: 10px;
    display: block;
    text-align: right;
  }
}
.open {
  font-size: 25px;
  font-weight: bold;
  color: var(--gray-side);
  cursor: pointer;
  margin-left: 20px;
  @include sm-view {
    margin-top: 10px;
  }
}
.detail {
  li {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    .icon {
      font-size: 20px;
      color: var(--orange-main);
      margin-right: 10px;
      &_polarity {
        padding: 3px 0 0 3px;
      }
      &.virtual {
        font-size: 18px;
        color: var(--green-main);
      }
      &.badge {
        font-size: 22px;
        margin: 0 12px 0 3px;
      }
    }
  }
}
.invalid_school {
  font-size: 14px;
  padding: 2px 12px;
  border-radius: 4px;
  background-color: var(--gray-main);
  mix-blend-mode: multiply;
}
.noflex {
  &_list {
    display: block !important;
  }
  &_table {
    padding-left: 30px;
    font-size: .8em;
    border-spacing: 0 8px;
    tr {

      td {
        width: 150px;
        border-bottom: 1px solid var(--gray-main);
      }
    }
  }
  &_nohistory {
    padding-left: 30px;
  }
}
.risk_table {
  &_next {
    padding-left: 2em;
  }
}
.only_pc {
  @include sm-view {
    display: none;
  }
}
.only_sp {
  display: none;
  @include sm-view {
    display: block;
  }
}
.flex_elem {
  display: flex;
}
.birth {
  flex: 1;
  dl {
    display: flex;
    align-items: center;
    margin: 0;
  }
  dt {
    width: 7em;
    i {
      margin-right: .5em;
    }
    &.school_year {
      width: 3em;
    }
  }
  dd {
    flex: 1;
    display: flex;
    margin-left: 1em;
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        margin-left: 1em;
        &:before {
          content: "~";
          margin-right: 1em;
        }
      }
    }
  }
  @include sm-view {
    dl {
      display: block;
      margin: 1em 0 .4em;
    }
    dt {
      font-size: 14px;
    }
    dd {
      margin: 0;
    }
  }
}
.select {
  display: flex;
  > li {
    &:not(:first-child) {
      margin-left: 1em;
    }
  }
  &_box {
    padding: 13px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
.label {
  display: flex;
  font-weight: bold;
  font-size: 20px;
  align-items: center;

  @include sm-view {
    font-size: 16px;
  }
  .large {
    margin-left: 1em;
    font-size: 24px;
    @include sm-view {
      font-size: 18px;
    }
  }
}
</style>
<style lang="scss" scoped>
:deep(.highlight) {
  background: linear-gradient(transparent 0%, #FFFF00 0%);
}
</style>

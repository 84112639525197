<template>
  <div :class="$style.container">
    <footer>
      <div :class="$style.flex">
        <router-link
          :to="user.account_type > 30 ? '/operator/'
            : user.account_type > 20 ? '/counselor/'
            : user.account_type > 10 ? '/teacher/'
            : user.account_type ? '/'
            : ''"
          :class="$style.logo">
          <img :src="require('@/assets/images/logo.png')" alt="welcome to talk" />
        </router-link>
      </div>

      <spacer :y="3"/>
      <div :class="$style.addresses">
        <p>株式会社 Welcome to talk</p>
        <address><span :class="$style.zip">〒102-0074</span><span :class="$style.address">東京都千代田区九段南1-5-6</span><span :class="$style.building">りそな九段ビル5階</span><span :class="$style.building">KSフロア</span></address>
        <p><a href="mailto:jimukyoku@welcometotalk.com">jimukyoku@welcometotalk.com</a></p>
      </div>

      <spacer :y="4" v-if="menuType" />
      <ul
        :class="`${$style.flex} ${$style.links}`"
        v-if="menuType === 'customer'">
        <li
          v-for="nav in customerItems"
          :key="nav.label">
          <router-link
            :to="nav.to"
            v-if="nav.to">{{ nav.label }}</router-link>
          <a
            :href="nav.link"
            target="_blank"
            v-if="nav.link">{{ nav.label }}</a>
          <span v-if="!nav.to && !nav.link && nav.label">{{ nav.label }}</span>
        </li>
      </ul>

      <ul
        :class="`${$style.flex} ${$style.links}`"
        v-if="menuType && menuType === 'schoolManager'">
        <li
          v-for="nav in schoolManagerItems"
          :key="nav.label">
          <router-link
            :to="nav.to"
            v-if="nav.to">{{ nav.label }}</router-link>
          <a
            :href="nav.link"
            target="_blank"
            v-if="nav.link">{{ nav.label }}</a>
          <span v-if="!nav.to && !nav.link && nav.label">{{ nav.label }}</span>
        </li>
      </ul>

      <ul
        :class="`${$style.flex} ${$style.links}`"
        v-if="menuType && menuType !== 'customer' && menuType !== 'schoolManager'">
        <li
          v-for="nav in nav[menuType]"
          :key="nav.label">
          <router-link
            :to="{
              path: nav.to,
              query: {
                type: nav.type,
              },
            }"
            v-if="nav.to">{{ nav.label }}</router-link>
          <a
            :href="nav.link"
            target="_blank"
            v-if="nav.link">{{ nav.label }}</a>
          <span v-if="!nav.to && !nav.link && nav.label">{{ nav.label }}</span>
        </li>
      </ul>

      <spacer :y="4"/>
      <p :class="$style.copy">&copy; Welcome to talk Co. Ltd. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'GlobalFooter',
  components: {
    Spacer,
  },
  data() {
    return {
      nav: {
        customer: [
          {
            label: 'プライバシーポリシー',
            to: '/policy/',
            display: 'all',
          },
          {
            label: '利用規約（利用者向け）',
            to: '/terms/',
            display: 'school',
          },
          {
            label: '利用規約（プログラム参加者向け）',
            to: '/terms/',
            display: 'company',
          },
          {
            label: '利用者マニュアル',
            link: '/pdf/3-2-利用者マニュアルver.3.1.pdf',
            display: 'school',
          },
          {
            label: 'プログラム参加者マニュアル',
            link: '/pdf/3-2-利用者マニュアル-プログラム参加者向け-ver.1.0.pdf',
            display: 'company',
          },
          {
            label: 'コーポレートサイト',
            link: 'https://welcometotalk.co.jp',
            display: 'all',
          },
        ],
        counselor: [
          {
            label: 'プライバシーポリシー',
            to: '/policy/',
          },
          {
            label: '医師心理士マニュアル',
            link: '/pdf/3-3-医師心理士マニュアル-ver.3.0.pdf',
          },
          {
            label: 'リスク報告基準',
            link: '/pdf/リスク報告基準.pdf',
          },
          {
            label: 'ココモニ',
            link: '/pdf/who5.pdf',
          },
          {
            label: 'バーチャル健康相談_専門家準備ver.2',
            link: '/pdf/バーチャル健康相談_専門家準備ver.2.pdf',
          },
          {
            label: 'コーポレートサイト',
            link: 'https://welcometotalk.co.jp',
          },
        ],
        schoolManager: [
          {
            label: 'プライバシーポリシー',
            to: '/policy/',
            display: 'all',
          },
          {
            label: '利用規約（教育機関向け）',
            to: '/teacher/terms/',
            display: 'school',
          },
          {
            label: '利用規約（導入企業向け）',
            to: '/teacher/terms/',
            display: 'company',
          },
          {
            label: '利用規約（利用者向け）',
            to: '/terms/',
            display: 'school',
          },
          {
            label: '利用規約（プログラム参加者向け）',
            to: '/terms/',
            display: 'company',
          },
          {
            label: '学校管理者マニュアル',
            link: '/pdf/3-1-学校管理者マニュアルver.3.2.pdf',
            display: 'school',
          },
          {
            label: '企業管理者マニュアル',
            link: '/pdf/3-1-企業管理者マニュアル-ver.1.1.pdf',
            display: 'company',
          },
          {
            label: '利用者マニュアル',
            link: '/pdf/3-2-利用者マニュアルver.3.1.pdf',
            display: 'school',
          },
          {
            label: 'プログラム参加者マニュアル',
            link: '/pdf/3-2-利用者マニュアル-プログラム参加者向け-ver.1.0.pdf',
            display: 'company',
          },
          // {
          //   label: '利用者マニュアル（個人向け）',
          //   link: '',
          // },
          {
            label: 'コーポレートサイト',
            link: 'https://welcometotalk.co.jp',
            display: 'all',
          },
        ],
        manager: [
          {
            label: 'プライバシーポリシー',
            to: '/policy/',
          },
          {
            label: '利用規約（教育機関向け）',
            to: '/teacher/terms/',
          },
          {
            label: '利用規約（利用者向け）',
            to: '/terms/',
          },
          {
            label: '利用規約（導入企業向け）',
            to: '/teacher/terms/',
            type: 'company',
          },
          {
            label: '利用規約（プログラム参加者向け）',
            to: '/terms/',
            type: 'program',
          },
          {
            label: '利用規約（個人向け）',
            to: '/terms/',
            type: 'individual',
          },
          {
            label: '学校管理者マニュアル',
            link: '/pdf/3-1-学校管理者マニュアルver.3.2.pdf',
          },
          {
            label: '利用者マニュアル',
            link: '/pdf/3-2-利用者マニュアルver.3.1.pdf',
          },
          // {
          //   label: '利用者マニュアル（個人向け）',
          //   link: '',
          // },
          {
            label: '医師心理士マニュアル',
            link: '/pdf/3-3-医師心理士マニュアル-ver.3.0.pdf',
          },
          {
            label: '企業管理者マニュアル',
            link: '/pdf/3-1-企業管理者マニュアル-ver.1.1.pdf',
          },
          {
            label: 'プログラム参加者マニュアル',
            link: '/pdf/3-2-利用者マニュアル-プログラム参加者向け-ver.1.0.pdf',
          },
          {
            label: 'リスク報告基準',
            link: '/pdf/リスク報告基準.pdf',
          },
          {
            label: 'バーチャル健康相談_専門家準備ver.2',
            link: '/pdf/バーチャル健康相談_専門家準備ver.2.pdf',
          },
          {
            label: 'コーポレートサイト',
            link: 'https://welcometotalk.co.jp',
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(['user']),
    menuType() {
      let type = 'customer';
      const customerTypes = [1, 2, 3];
      const counselorTypes = [21, 22];
      const schoolManagerTypes = [11, 12];
      const managerTypes = [31];
      if (this.user.account_type) {
        if (customerTypes.includes(this.user.account_type)) {
          type = 'customer';
        } else if (counselorTypes.includes(this.user.account_type)) {
          type = 'counselor';
        } else if (schoolManagerTypes.includes(this.user.account_type)) {
          type = 'schoolManager';
        } else if (managerTypes.includes(this.user.account_type)) {
          type = 'manager';
        }
      } else {
        type = null;
      }
      return type;
    },
    schoolManagerItems() {
      if (this.user.school && this.user.school.length && this.user.school[0].is_company) {
        return this.nav.schoolManager.filter((item) => ['all', 'company'].includes(item.display));
      }
      return this.nav.schoolManager.filter((item) => ['all', 'school'].includes(item.display));
    },
    customerItems() {
      if (this.user.school && this.user.school.length && this.user.school[0].is_company) {
        return this.nav.customer.filter((item) => ['all', 'company'].includes(item.display));
      }
      return this.nav.customer.filter((item) => ['all', 'school'].includes(item.display));
    },
  },
};
</script>

<style lang="scss" module>
.container {
  color: #fff;
  background-color: var(--orange-main);
  padding: 80px 0;
  footer {
    display: block;
    width: 100%;
    margin: auto;
    padding: 0 var(--main-contents-gutter);
    max-width: calc(var(--main-contents-width) + var(--main-contents-gutter) * 2);
  }
}
.flex {
  display: flex;
  justify-content: center;
}
.links {
  $m: 1em;
  flex-wrap: wrap;
  li {
    line-height: 3;
    a {
      color: #fff;
      &:hover {
        text-decoration: underline;
      }
    }
    margin-right: $m;
    &:not(:last-child) {
      &:after {
        content: "/";
        margin-left: $m;
      }
    }
  }
  @include sm-view {
    font-size: 13px;
    display: block;
    li {
      width: auto;
      line-height: 2;
      margin-right: 0;
      text-align: center;
      &:not(:last-child) {
        &:after {
          content: none;
        }
      }
    }
  }
}
.logo {
  width: 200px;
  display: block;
  transition: .4s all;
  &:hover {
    opacity: .6;
  }
}
.addresses {
  text-align: center;
  line-height: 1.8;
  address {
    font-style: normal;
    span {
      &:not(:first-child) {
        margin-left: .5em;
      }
    }
  }
  a {
    font-size: 14px;
    color: white;
    &:hover {
      text-decoration: underline;
    }
  }
  @include sm-view {
    font-size: 14px;
    address {
      font-size: 13px;
      .building {
        display: block;
      }
    }
    a {
      font-size: 13px;
    }
  }
}
.copy {
  font-size: 12px;
  text-align: center;
  @include sm-view {
    font-size: 11px;
  }
}
</style>

/**
* ログイン管理
*/

import axios from '@/plugins/axios';
import router from '@/router';
import cf from '@/mixins/commonFunctions';

// 閲覧にログインが不要のページ名（配下含め）
const nonRequiredLoginPageNames = [
  'login',
  'signup',
  'signup2',
  'forgot-password',
  'reset-password',
  'training',
  'lti',
];

const acceptAllAccount = [
  'login',
  'signup',
  'forgot-password',
  'reset-password',
  'account',
  'edit',
  'sitemap',
  'policy',
  'terms',
  'teacher/terms',
  'training',
];


const actions = {
  /** state.isLoginの変更 */
  changeLoginStatus({ commit }, bool) {
    commit('changeLoginStatus', bool);
  },


  /** ログインしているかのチェック */
  check({ commit, dispatch, state }) {
    const ls = cf.getLocalStorage('wtt');
    // 現在の環境チェック
    dispatch('helper/checkEnv', null, { root: true });

    const currentPath = router.options.history.location;

    // localStorageが存在しない（未サインナップ）
    if (!ls || !ls.jwt) {
      commit('changeCheckedStatus', true);
      dispatch('user/setUserData', { hasntJWT: true }, { root: true });

      let isRedirect = true; // 編集後にtrueに戻す
      nonRequiredLoginPageNames.some((pageName) => {
        if (currentPath.includes(pageName)) { isRedirect = false; return true; }
        return false;
      });

      // ログインページへリダイレクト
      if (isRedirect) {
        alert('本ページを閲覧するにはログインしている必要があります。\nログインページへリダイレクトします。');
        const current = router.options.history;
        const ref = { path: current.location };
        // ログイン後に元いたページへ戻すためpathとqueryを保持しておく
        cf.saveLocalStorage({ loginRef: JSON.stringify(ref) }, 'wtt');
        dispatch('redirect2Login');
      }

      return;
    }

    // ログインstateがなく、かつJWTがあれば認証APIを叩く
    if (!state.isLogin && ls.jwt) {
      dispatch('authCheck', {
        type: 'check',
        jwt: ls.jwt,
      });
    }
  },


  /**
   * ログインページのsubmit押下
   * API通信してIDとPASSをpost
   * 認証に成功したらJWT(token)をlocalStorageへ保存
   * @param obj data
   *   email:    str
   *   password: str
   */
  submit({ commit, dispatch }, data) {
    axios({
      method: 'post',
      url: '/v1/user/login',
      data,
    })
      .then((response) => {
        const res = response.data;
        if (res && res.token) {
          // tokenをlocalStorageに保存
          cf.saveLocalStorage({
            jwt: res.token,
            supportType: 'login',
          }, 'wtt');

          // リダイレクト用パラメータが存在すればlocation.hrefでリダイレクト
          if (data.redirectParams) {
            let link = data.redirectParams.path;
            if (data.redirectParams.query) {
              Object.keys(data.redirectParams.query).forEach((key, i) => {
                const prefix = i === 0 ? '?' : '&';
                link += `${prefix}${key}=${data.redirectParams.query[key]}`;
              });
            }
            location.href = link;
            return;
          }

          // 取得したtokenを元にログインチェック
          dispatch('authCheck', {
            type: 'login',
            isRegist: data.isRegist,
            jwt: res.token,
            // redirectParams: data.redirectParams || null,
          });
        }
      })
      .catch((error) => {
        dispatch('loginFailed');
        if (error.response) {
          const errRes = error.response.data;
          const mailaddress = data.mail;
          console.log(errRes);
          // ログイン失敗時にエラー内容を表示 アカウントなし：1 パスワード違い：2
          if (errRes.detail === `E_USER_NOT_FOUND: Cannot find user with email as ${mailaddress}`) {
            commit('changeLoginErrorStatus', 1);
          } else if (errRes.detail === 'E_PASSWORD_MISMATCH: Cannot verify user password') {
            commit('changeLoginErrorStatus', 2);
          }
          if (errRes.isBanned) {
            commit('changeBannedStatus', { isBanned: errRes.isBanned, unbannedTime: errRes.unbannedTime });
          } else if (errRes.failedStatus) {
            commit('changeBannedStatus', { isBanned: errRes.failedStatus.isBanned, unbannedTime: errRes.failedStatus.unbannedTime });
          } else {
            commit('changeBannedStatus', { isBanned: false, unbannedTime: null });
          }
        } else console.log(error);
      });
  },


  /**
   * auth情報を使ってログインチェックし
   * 取得したユーザーデータをstateへ格納
   * @param obj data
   *   jwt: obj
   *   type: 'check' || 'login'
   * logincheckではlocalstorageから
   * loginsubmitでは取得したjwtをdataに格納して渡す
   */
  authCheck({ commit, dispatch }, data) {
    const ls = cf.getLocalStorage('wtt');
    /** jwtからauthorization生成 */
    const Authorization = `${data.jwt.type} ${data.jwt.token}`;
    axios({
      method: 'GET',
      url: '/v1/user/loginCheck',
      headers: { Authorization },
    })
      .then((response) => {
        // user.flagが1以外はログイン無効
        const res = response.data.data;
        if (res.flag !== 1
            || (res.school && res.school.length && res.school[0].flag !== 1)) {
          // ログイン時には失敗の挙動
          if (data.type === 'login') dispatch('loginFailed');
          // localStorageのjwtを削除
          cf.deleteLocalStorage('wtt', 'jwt');
          commit('changeLoginStatus', false);
          return;
        }

        // authcheck後にstateを更新
        commit('changeLoginStatus', true);
        // ユーザー情報をstate.userにセット（user/index.js）
        dispatch('user/setUserData', res, { root: true });
        // システムマスターの判別
        dispatch('helper/checkSystemMaster', null, { root: true });
        dispatch('helper/checkAccountingAccount', null, { root: true });
        // 星座の判別
        dispatch('helper/checkUserSeiza', null, { root: true });
        // ログイン制限state解除
        commit('changeBannedStatus', { isBanned: false, unbannedTime: null });
        // カウンセリング情報取得
        dispatch('user/counseling/getCounseling', null, { root: true });
        // 医師・心理士のシフト
        if ([21, 22].includes(res.account_type)) {
          // 事務局はタブ選択時に取得・学校管理者・利用者は不要
          // カウンセラーのみ自身のシフトを取得
          dispatch('schedule/setSchedule', { target_user_id: res.id }, { root: true });
        }
        // 祝日をstore登録
        dispatch('holiday/setHoliday', null, { root: true });

        // 全アカウントアクセス可能か判定
        let acceptAll = false;
        acceptAllAccount.forEach((word) => {
          if (router.options.history.location.includes(word)) {
            acceptAll = true;
          }
        });

        const path = router.options.history.location;
        const Atype = res.account_type;
        let type = '';
        if (Atype > 30) type = 'operator/';
        else if (Atype > 20) type = 'counselor/';
        else if (Atype > 10) type = 'teacher/';

        if (!acceptAll) {
          // 利用者はrouter.push・それ以外はリダイレクト
          if (type === 'operator/' && !path.includes('/operator')) {
            location.href = '/operator/';
          } else if (type === 'counselor/' && !path.includes('/counselor')) {
            location.href = '/counselor/';
          } else if (type === 'teacher/' && !path.includes('/teacher')) {
            location.href = 'teacher';
          } else if (type === '' && (path.includes('/operator') || path.includes('/counselor') || path.includes('/teacher'))) {
            router.push({ path: '/' });
          }
        }

        // ログイン時
        if (data.type === 'login') {
          let redirectPath = '';
          // リファラの有無でリダイレクト先が変わる
          if (ls && ls.loginRef) {
            const ref = JSON.parse(ls.loginRef).path;
            if (ref.includes('/operator')) {
              location.href = '/operator/';
            } else if (ref.includes('/counselor')) {
              location.href = '/counselor/';
            } else if (ref.includes('/teacher')) {
              location.href = '/teacher/';
            } else if (ref.includes('/lti')) {
              // ltiの場合はトップへ遷移
              router.push('/');
            } else {
              redirectPath = type === '' ? ref : `/${type}`;
              router.push(redirectPath);
            }
          } else if (type === '') {
            // 利用者は.push
            redirectPath = `/${type}`;
            router.push(redirectPath);
          } else {
            // 利用者以外はベーシック認証を出すためlocation.href
            location.href = `/${type}`;
          }
        }
      })
      .catch((error) => {
        console.log('ログイン認証エラー');
        if (error.response) console.log(error.response.data);
        else console.log(error);
        // ログイン画面へリダイレクトするかのフラグ
        let isRedirect2Login = true;
        if (data.type === 'check') {
          // ログインチェック時
          const status = error.response.status;
          if (status === 401) { // 期限切れ
            // ログインチェックが実行されたURL
            const originURL = location.href;
            // URLに指定ページ名が含まれていればフラグ有効化
            nonRequiredLoginPageNames.some((pageName) => {
              if (originURL.includes(pageName)) {
                isRedirect2Login = false;
                return true;
              }
              return false;
            });

            if (isRedirect2Login) { // ログイン画面へリダイレクト
              alert('ログイン有効期限が切れています。\nログイン画面へリダイレクトします');
              const current = router.options.history;
              const ref = { path: current.location };
              // ログイン後に元いたページへ戻すためpathとqueryを保持しておく
              cf.saveLocalStorage({ loginRef: JSON.stringify(ref) }, 'wtt');
              dispatch('redirect2Login');
            } else { // 非ログインとしてログインページへリダイレクト
              dispatch('redirect2Login');
              cf.deleteLocalStorage('wtt', 'loginRef');
            }

            // localStorageのjwtを削除
            cf.deleteLocalStorage('wtt', 'jwt');
          } else if (status === 403) {
            // DBとの接続に失敗
            alert('データの取得に失敗しました。リロードをお試しください');
          }
        } else if (data.type === 'login') {
          // ログイン直後（取得直後のjwtなので論理的には失敗する事ないはず）
          // （user.flagが1以外の場合はこちらに入る）
          if (data.jwt && !error.response) {
            // ユーザのflagが1以外の場合
            dispatch('loginFailed');
            commit('changeLoginStatus', false);
          } else {
            alert('ログインで問題が発生しました\nお手数ですが管理者へお知らせください');
          }
          // localStorageのjwtを削除
          cf.deleteLocalStorage('wtt', 'jwt');
        }
      })
      .finally(() => {
        commit('changeCheckedStatus', true);
      });
  },


  /** ログイン失敗 */
  loginFailed({ commit }) {
    commit('changeVibrationStatus', true);
    setTimeout(() => { commit('changeVibrationStatus', false); }, 500);
  },


  /** 有効期限切れ時のログイン画面リダイレクト */
  redirect2Login() {
    router.push({ path: '/login/' });
  },


  /** トップへリダイレクト */
  redirect2Top() {
    router.push({ path: '/' });
  },


  /** ログアウト */
  logout({ commit, dispatch }) {
    // localStorageの更新
    cf.deleteLocalStorage('wtt', 'jwt');
    cf.deleteLocalStorage('wtt', 'loginRef');
    cf.deleteLocalStorage('wtt', 'lot');
    cf.deleteLocalStorage('wtt', 'saveState');

    dispatch('helper/reset', null, { root: true });
    commit('changeLoginStatus', false);

    // ログインページへリダイレクト
    router.push({ path: '/login/' });

    // 全storeリセットのため強制リロード
    setTimeout(() => { router.go(router.currentRoute.path); }, 500);
  },
};

const mutations = {
  /** チェック済みステート */
  changeCheckedStatus(state, bool) {
    state.isChecked = bool;
  },

  /** ログインステート */
  changeLoginStatus(state, bool) {
    state.isLogin = bool;
  },

  /** ログイン失敗時のバイブフラグステート */
  changeVibrationStatus(state, bool) {
    state.vibration = bool;
  },

  /** ログイン制限時のフラグ・日時ステート */
  changeBannedStatus(state, { isBanned: bool, unbannedTime: datetime }) {
    state.isBanned = bool;
    state.unbannedTime = datetime;
  },

  /** ログイン失敗時のエラー内容ステート */
  changeLoginErrorStatus(state, number) {
    if (number === 0) {
      state.isFaild = 0;
    } else {
      state.isFaild = number;
    }
  },
};

const state = {
  isChecked: false,
  isLogin: false,
  vibration: false,
  isBanned: false,
  unbannedTime: null,
  isFaild: 0,
};


export default {
  namespaced: true,
  actions,
  mutations,
  state,
};

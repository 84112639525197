<template>
  <div>
    <spacer :y="5"/>
    <ul>

      <stack-item id="risk-application-approval-content-tasks">
        <contents-box title="リスク評価申請">
          <p
            v-if="pagination.tasks.total > 0"
            :class="$style.bold"
          >{{ pagination.tasks.total }}件</p>
          <pagination
            v-if="pagination.tasks.lastPage > 1"
            :page="pagination.tasks.page"
            :pageMax="pagination.tasks.lastPage"
            :needEmit="true"
            v-on:click-page="movePage($event, 'tasks')"
          />
          <spacer :y="2"/>
          <loader-simple :flag="flag.loader">
            <div>
              <ul v-if="riskApplications.tasks.length > 0">
                <stack-item
                  v-for="row in riskApplications.tasks"
                  :key="row.id"
                >
                  <risk-application-box
                    :riskApplication="row"
                    :targetUserLink="getTargetUserLink(row.target_user_id)"
                    :forceShowNew="isForceShowNew(row)"
                  >
                    <template
                      v-if="showApprovalBtn(row)"
                      v-slot:btn
                    >
                      <btn-container>
                        <basic-btn
                          tag="button"
                          size="sm"
                          v-on:click="clickApproval(row)"
                        >内部評価</basic-btn>
                      </btn-container>
                    </template>
                  </risk-application-box>
                </stack-item>
              </ul>
              <p v-if="!flag.loader && riskApplications.tasks.length === 0">該当するリスク報告はありません</p>
              <spacer :y="2"/>
              <pagination
                v-if="!flag.loader && pagination.tasks.lastPage > 1"
                :page="pagination.tasks.page"
                :pageMax="pagination.tasks.lastPage"
                :needEmit="true"
                v-on:click-page="movePage($event, 'tasks')"
              />
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>

      <stack-item id="risk-application-approval-content-approved">
        <contents-box title="承認（リスク報告済）">
          <p
            v-if="pagination.approved.total > 0"
            :class="$style.bold"
          >{{ pagination.approved.total }}件</p>
          <pagination
            v-if="pagination.approved.lastPage > 1"
            :page="pagination.approved.page"
            :pageMax="pagination.approved.lastPage"
            :needEmit="true"
            v-on:click-page="movePage($event, 'approved')"
          />
          <loader-simple :flag="flag.loader">
            <div>
              <spacer :y="2"/>
              <ul v-if="riskApplications.approved.length > 0">
                <stack-item
                  v-for="row in riskApplications.approved"
                  :key="row.id"
                >
                  <risk-application-box
                    :riskApplication="row"
                    :targetUserLink="getTargetUserLink(row.target_user_id)"
                  >
                    <template v-slot:btn>
                      <btn-container>
                        <basic-btn
                          tag="button"
                          size="sm"
                          v-on:click="clickRefer(row)"
                        >リスク報告書</basic-btn>
                      </btn-container>
                    </template>
                  </risk-application-box>
                </stack-item>
              </ul>
              <p v-if="!flag.loader && riskApplications.approved.length === 0">該当するリスク報告はありません</p>
              <spacer :y="2"/>
              <pagination
                v-if="!flag.loader && pagination.approved.lastPage > 1"
                :page="pagination.approved.page"
                :pageMax="pagination.approved.lastPage"
                :needEmit="true"
                v-on:click-page="movePage($event, 'approved')"
              />
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>

      <stack-item id="risk-application-approval-content-denied">
        <contents-box title="差し戻し">
          <p
            v-if="pagination.denied.total > 0"
            :class="$style.bold"
          >{{ pagination.denied.total }}件</p>
          <pagination
            v-if="pagination.denied.lastPage > 1"
            :page="pagination.denied.page"
            :pageMax="pagination.denied.lastPage"
            :needEmit="true"
            v-on:click-page="movePage($event, 'denied')"
          />
          <loader-simple :flag="flag.loader">
            <div>
              <spacer :y="2"/>
              <ul v-if="riskApplications.denied.length > 0">
                <stack-item
                  v-for="row in riskApplications.denied"
                  :key="row.id"
                >
                  <risk-application-box
                    :riskApplication="row"
                    :targetUserLink="getTargetUserLink(row.target_user_id)"
                  >
                    <template v-slot:btn>
                      <btn-container>
                        <basic-btn
                          tag="button"
                          size="sm"
                          v-on:click="clickRefer(row)"
                        >リスク報告書</basic-btn>
                      </btn-container>
                    </template>
                  </risk-application-box>
                </stack-item>
              </ul>
              <p v-if="!flag.loader && riskApplications.denied.length === 0">該当するリスク報告はありません</p>
              <spacer :y="2"/>
              <pagination
                v-if="!flag.loader && pagination.denied.lastPage > 1"
                :page="pagination.denied.page"
                :pageMax="pagination.denied.lastPage"
                :needEmit="true"
                v-on:click-page="movePage($event, 'denied')"
              />
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>

    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';

import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import Spacer from '@/views/components/Spacer.vue';
import RiskApplicationBox from '@/views/components/RiskApplicationBox.vue';
import Pagination from '@/views/components/Pagination.vue';

import cf from '@/mixins/commonFunctions.js';
import raf from '@/mixins/riskApplicationFunctions';

const PAGINATION_DEFAULT = {
  page: 1, // 現在ページ
  perPage: 10, // ページ毎件数
  lastPage: 1, // 最後ページ(データ取得の都度更新)
  total: 0, // 総件数(データ取得の都度更新)
};

export default {
  name: 'counselor-risk-management',
  mixins: [
    cf,
    raf,
  ],
  components: {
    StackItem,
    ContentsBox,
    LoaderSimple,
    BtnContainer,
    BasicBtn,
    Spacer,
    RiskApplicationBox,
    Pagination,
  },
  data() {
    return {
      flag: {
        loader: true,
      },
      riskApplications: {
        tasks: [], // 申請済み（=内部評価待ち、下書き含む）
        approved: [], // リスク報告済み
        denied: [], // 差し戻し済み
      },
      pagination: {
        tasks: cloneDeep(PAGINATION_DEFAULT),
        approved: cloneDeep(PAGINATION_DEFAULT),
        denied: cloneDeep(PAGINATION_DEFAULT),
      },
      childWindowNames: [],
    };
  },
  created() {
    if (this.user && this.user.email) {
      this.getRiskApplications();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getRiskApplications();
        }
      });
    }
  },
  beforeUnmount() {
    // コールバック関数を解除
    this.childWindowNames.forEach((childWindowName) => {
      const functionName = raf.getEmitFunctionName(childWindowName);
      if (window[functionName]) {
        delete window[functionName];
      }
    });
  },
  computed: {
    ...mapState(['helper', 'user']),
  },
  methods: {
    getTargetUserLink(userId) {
      return `/counselor/history/user/${userId}`;
    },
    isForceShowNew(riskApplication) {
      // 内部評価申請状態の場合のみ表示（下書きにはNEWを表示しない）
      return riskApplication.status === this.helper.master.labels.riskApplication.status.REVIEWED;
    },
    async getRiskApplications(target = null) { // target: 取得対象プロパティ
      this.flag.loader = true;
      // 表示情報初期化
      if (target !== null) {
        this.riskApplications[target] = [];
      } else {
        this.riskApplications = {
          tasks: [],
          approved: [],
          denied: [],
        };
      }
      // 内部関数を定義
      const _getData = async (statuses, pagination) => {
        const response = await this.axios({
          method: 'GET',
          url: '/v1/riskApplications/get/list',
          params: {
            statuses,
            with_reviewer_user: 0,
            with_counseling_data: 1,
            page: pagination.page,
            perPage: pagination.perPage,
          },
        });
        const result = response.data.data; // ページ情報なども含むオブジェクト
        // pagination情報の更新
        pagination.lastPage = result.lastPage;
        pagination.total = result.total;
        // 実データの返却
        return result.data;
      };
      // 画面表示するステータスごとに取得
      try {
        await Promise.all([
          // 申請済み（=内部評価待ち）情報の取得
          (async () => {
            if (target && target !== 'tasks') {
              return;
            }
            this.riskApplications.tasks = await _getData([
              this.helper.master.labels.riskApplication.status.REVIEWED,
              this.helper.master.labels.riskApplication.status.APPROVER_DRAFTED,
            ], this.pagination.tasks);
          })(),
          // リスク報告済み情報の取得
          (async () => {
            if (target && target !== 'approved') {
              return;
            }
            this.riskApplications.approved = await _getData([
              this.helper.master.labels.riskApplication.status.APPROVED,
            ], this.pagination.approved);
          })(),
          // 差し戻し済み情報の取得
          (async () => {
            if (target && target !== 'denied') {
              return;
            }
            this.riskApplications.denied = await _getData([
              this.helper.master.labels.riskApplication.status.DENIED,
            ], this.pagination.denied);
          })(),
        ]);
        this.flag.loader = false;
      } catch (error) {
        console.error(error);
        alert('画面表示情報の取得に失敗しました');
        throw error;
      }
    },
    clickApproval(riskApplication) {
      const childWindowName = this.getChildWindowName(riskApplication, true);
      // 別タブで開く
      window.open(`/counselor/risk-applications/${riskApplication.id}/approval`, childWindowName);
    },
    clickRefer(riskApplication) {
      const childWindowName = this.getChildWindowName(riskApplication, false);
      // 別タブで開く
      window.open(`/counselor/risk-applications/${riskApplication.id}`, childWindowName);
    },
    /**
     * このメソッドは子windowでロードされたRiskApplicationFormコンポーネントから実行される。
     * RiskApplicationForm上でリスク報告申請書へのアクションが行われた時、このメソッドを呼び出すことで情報の最新化を行う。
     */
    postUpdateRequest() {
      this.getRiskApplications(); // 再初期化する
    },
    /**
     * 子window名を決定返却するとともに、コールバック関数をセットする
     * @param riskApplication リスク報告書情報
     * @param needSetup セットする必要があるか
     */
    getChildWindowName(riskApplication, needSetup) {
      const childWindowName = raf.getChildWindowName({
        counselingType: riskApplication.counseling_type,
        foreignId: riskApplication.foreign_id,
      });
      if (needSetup) {
        // コールバック関数をセット
        const functionName = raf.getEmitFunctionName(childWindowName);
        if (!window[functionName]) {
          window[functionName] = this.postUpdateRequest;
        }
        // 子画面名を控えておく
        if (!this.childWindowNames.includes()) {
          this.childWindowNames.push(childWindowName);
        }
      }
      return childWindowName;
    },
    showApprovalBtn(riskApplication) {
      if (riskApplication.status !== this.helper.master.labels.riskApplication.status.APPROVER_DRAFTED) {
        return true;
      }
      // 承認者下書き状態の場合は、自身が下書きをしたものにのみボタンを表示する
      return riskApplication.approver_user_id === this.user.id;
    },
    /** ページ移動 */
    movePage(page, type) {
      if (this.pagination[type].page === page) {
        return;
      }
      // 現在ページ変更
      this.pagination[type].page = page;
      // データ取得
      this.getRiskApplications(type);
      // スクロール
      document.getElementById(`risk-application-approval-content-${type}`).scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" module>
.bold {
  font-weight: bold;
  font-size: 1.5em;
}
</style>

<template>
  <div>
    <spacer :y="5"/>
    <ul>
      <stack-item>
        <contents-box title="CSV出力">
          <div :class="$style.date">
            <dl>
              <dt><i class="fa-solid fa-calendar-days"></i>期間</dt>
              <dd>
                <div>
                  <date-small-picker
                    :name="'startDate'"
                    v-model="startDate"
                    v-on:sendDate="receiveDate" />
                </div>
                <date-small-picker
                  :name="'endDate'"
                  v-model="endDate"
                  v-on:sendDate="receiveDate" />
              </dd>
              <!-- <dd :class="$style.csv_btn">
                <basic-btn
                  tag="button"
                  :disabled="flag.loader.reserves"
                  @click="beforeGenerateCSV">
                  <div :class="$style.output_btn">
                    <loader-simple
                      :flag="flag.loader.generateBtn"
                      v-if="flag.loader.generateBtn"
                      :isDisableMinHight="true"
                      :mini="true"/>
                  </div>
                  <sapn>出力する</sapn>
                </basic-btn>
              </dd> -->

              <dd :class="$style.csv_btn">
                <basic-btn
                  tag="button"
                  :disabled="flag.loader.reserves || flag.loader.generateBtn"
                  @click="getCsvData">
                  <div :class="$style.output_btn">
                    <loader-simple
                      :flag="flag.loader.generateBtn"
                      v-if="flag.loader.generateBtn"
                      :isDisableMinHight="true"
                      :mini="true"/>
                  </div>
                  <sapn>出力する</sapn>
                </basic-btn>
              </dd>
            </dl>

            <spacer :y="4" />
            <dl :class="$style.select">
              <dt>ダウンロード対象</dt>
              <div v-if="!isAccountingAccount" :class="[$style.checkBox, $style.flexCheckBox]">
                <div>
                  <input
                    type="checkbox"
                    id="csvDLreserveAndVirtualReserve"
                    v-model="flag.csvDL.reserveAndVirtualReserve">
                  <label for="csvDLreserveAndVirtualReserve">オンライン・バーチャル</label>
                </div>
                <spacer :x="2"/>
                <div>
                  <input
                    type="checkbox"
                    id="csvDLreserve"
                    v-model="flag.csvDL.reserve">
                  <label for="csvDLreserve">オンライン</label>
                </div>
                <spacer :x="2"/>
                <div>
                  <input
                    type="checkbox"
                    id="csvDLvirtualReserve"
                    v-model="flag.csvDL.virtualReserve">
                  <label for="csvDLvirtualReserve">バーチャル</label>
                </div>
                <spacer :x="2"/>
                <div>
                  <input
                    type="checkbox"
                    id="csvDLtext"
                    v-model="flag.csvDL.text">
                  <label for="csvDLtext">テキスト</label>
                </div>
                <spacer :x="2"/>
                <div>
                  <input
                    type="checkbox"
                    id="csvDLcocomoni"
                    v-model="flag.csvDL.cocomoni">
                  <label for="csvDLcocomoni">ココモニ</label>
                </div>
                <spacer :x="2"/>
              </div>
              <div :class="[isAccountingAccount ? $style.checkBox : '']">
                <input
                  type="checkbox"
                  id="csvDLaccounting"
                  v-model="flag.csvDL.accounting">
                <label for="csvDLaccounting">経理用</label>
              </div>
            </dl>
            <spacer :y="2"/>
            <dl :class="$style.select">
              <dt>除外</dt>
              <div :class="$style.checkBox">
                <input
                  type="checkbox"
                  id="excludeCancel"
                  v-model="flag.excludeCancel"
                  @change="switchSortContents">
                <label for="excludeCancel">キャンセルは除外する</label>
              </div>
              <spacer :x="2"/>
              <div>
                <input
                  type="checkbox"
                  id="excludeText"
                  v-model="flag.excludeTest"
                  @change="switchSortContents">
                <label for="excludeText">テストアカウントを除外する</label>
              </div>
            </dl>
            <spacer :y="2" />
            <dl :class="$style.select">
              <dt>学校・企業ソート</dt>
              <dd>
                <!-- セレクト -->
                <select
                  :class="$style.select_box"
                  name="school"
                  id="school"
                  v-on:change="updateSchool">
                  <option :value="0" :selected="targetSchool === 0">すべて</option>
                  <option
                    v-for="n of schools"
                    :key="n"
                    :value="n.id"
                    :selected="n.id === targetSchool">{{ n.name }}</option>
                </select>
              </dd>
            </dl>
            <spacer :y="2" />
            <dl :class="$style.select">
              <dt>医師・心理士ソート</dt>
              <dd>
                <!-- セレクト -->
                <select
                  :class="$style.select_box"
                  name="counselor"
                  id="counselor"
                  v-on:change="updateCounselor">
                  <option :value="0" :selected="targetCounselor === 0">すべて</option>
                  <option
                    v-for="counselor of counselors"
                    :key="counselor"
                    :value="counselor.id"
                    :selected="counselor.id === targetCounselor ">{{ counselor.username }}</option>
                </select>
              </dd>
            </dl>
          </div>
        </contents-box>
      </stack-item>

      <spacer :y="3"/>
      <hr id="scroll-top" v-if="!isAccountingAccount">
      <stack-item v-if="!isAccountingAccount">
        <contents-box title="オンライン・バーチャル健康相談">
          <div :class="$style.history_search">
            <spacer :y="2" />
            <div :class="$style.date">
              <dl>
                <dt><i class="fa-solid fa-calendar-days"></i>申込期間</dt>
                <dd>
                  <div>
                    <date-small-picker
                      :name="'historyStartDate'"
                      v-model="historyStartDate"
                      v-on:sendDate="historyReceiveDate" />
                  </div>
                    <date-small-picker
                      :name="'historyEndDate'"
                      v-model="historyEndDate"
                      v-on:sendDate="historyReceiveDate" />
                </dd>
              </dl>
              <div v-if="!historyStartDate && historyEndDate">
                <spacer :y="1"/>
                <p :class="$style.alert_text">申込期間の開始日を選択してください</p>
              </div>
              <spacer :y="2"/>
              <dl>
                <div v-if="historyStartDate && historyEndDate && !(fitlyPeriod)" :class="$style.alert_text">正しい期間を選択してください</div>
              </dl>
              <spacer :y="2"/>
              <dl :class="$style.select">
                <dt>除外</dt>
                <div :class="$style.checkBox">
                  <input
                    type="checkbox"
                    id="historyExcludeCancel"
                    v-model="flag.historyExcludeCancel"
                    @change="switchSortContentsHistory">
                  <label for="historyExcludeCancel">キャンセルは除外する</label>
                </div>
                <spacer :x="2"/>
                <div>
                  <input
                    type="checkbox"
                    id="historyExcludeTest"
                    v-model="flag.historyExcludeTest"
                    @change="switchSortContentsHistory">
                  <label for="historyExcludeTest">テストアカウントを除外する</label>
                </div>
              </dl>
              <spacer :y="2" />
              <dl :class="$style.select">
                <dt>学校・企業ソート</dt>
                <dd>
                  <select
                    :class="$style.select_box"
                    name="school"
                    id="school"
                    v-on:change="historyUpdateSchool">
                    <option :value="0" :selected="historyTargetSchool === 0">すべて</option>
                    <option
                      v-for="n of schools"
                      :key="n"
                      :value="n.id"
                      :selected="n.id === historyTargetSchool">{{ n.name }}</option>
                  </select>
                </dd>
              </dl>
              <spacer :y="2" />
              <dl :class="$style.select">
                <dt>医師・心理士ソート</dt>
                <dd>
                  <select
                    :class="$style.select_box"
                    name="counselor"
                    id="counselor"
                    v-on:change="historyUpdateCounselor">
                    <option :value="0" :selected="historyTargetCounselor === 0">すべて</option>
                    <option
                      v-for="counselor of counselors"
                      :key="counselor"
                      :value="counselor.id"
                      :selected="counselor.id === historyTargetCounselor ">{{ counselor.username }}</option>
                  </select>
                </dd>
              </dl>
            </div>
            <spacer :y="4" />
            <div style="text-align: center;">
              <basic-btn
                tag="button"
                :disabled="flag.loader.reserves || flag.loader.generateBtn"
                @click="getReserves(true)">
                <sapn>検索</sapn>
              </basic-btn>
            </div>
            <spacer :y="4" />
          </div>

          <loader-simple :flag="flag.loader.reserves" >
            <div v-if="!flag.loader.reserves">
              <div v-if="!historyStartDate
                && !historyEndDate
                && !flag.historyExcludeCancel
                && !flag.historyExcludeTest
                && !historyTargetSchool
                && !historyTargetCounselor">
                <p v-if="uncheckedCount && !flag.totalCount">未確認の報告書 <span :class="$style.bold">{{ uncheckedCount }}件</span></p>
                <p v-if="!uncheckedCount && !flag.totalCount">未確認の報告書はありません。</p>
              </div>
              <p v-else-if="flag.totalCount">該当する履歴 <span :class="$style.bold"> {{ user.counseling.op.reserve.pastOptions.reservesTotal }}件</span></p>

              <pagination
                v-if="!flag.loader.reserves
                  && user.counseling.op.reserve.pastOptions.pageMax
                  && user.counseling.op.reserve.pastOptions.pageMax !== 1"
                :page="page"
                :pageMax="user.counseling.op.reserve.pastOptions.pageMax"
                :path="'/operator/?tab=history'" />

              <spacer :y="2" v-if="user.counseling.op.reserve.past[page].length" />
              <ul v-if="user.counseling.op.reserve.past[page].length">
                <stack-item
                  v-for="row in user.counseling.op.reserve.past[page]"
                  :key="row.id"
                  :class="$style.check_status_wrap"
                  :id="`reserve_${row.id}`">
                  <user-box
                    type="inner" :showId="true"
                    :user="row.fromUser"
                    :ownUser="user"
                    :showMessageBtn="true"
                    :showCocomoniBtn="true"
                    :showCancel="row.flag === 901"
                    :showCancelOnTheDay="row.flag === 902"
                    :bg="row.flag === 1 || row.flag === 11 ? userBoxColor(row.fromUser) : 'gray'"
                    :link="`/operator/history/${row.fromUser.id}`"
                    :targetBlank="true">
                    <template v-slot:btn>
                      <div :class="[$style.clickarea, $style.open]" v-on:click="changeOpenIds(row)">
                        <i
                          v-if="openIds.includes(row.id)"
                          :class="$style.icon"
                          class="fa-regular fa-minus"></i>
                        <i
                          v-if="!openIds.includes(row.id)"
                          :class="$style.icon"
                          class="fa-regular fa-plus"></i>
                      </div>
                    </template>
                    <template v-slot:main>
                      <div :class="$style.drawer">
                        <spacer :y="2"/>
                        <div>
                          <ul :class="$style.detail">
                            <li>
                              <label-box label="担当" color="gray" size="sm" :class="$style.label"/>
                              <p :class="[$style.content, $style.counseling_wrap]">
                                {{ row.toUser.username }}（{{ row.toUser.account_type === 21 ? '医師' : '心理士' }}）
                                <i
                                  v-if="row._counselingType.counseling_type === 1"
                                  class="fa-regular fa-laptop"
                                  :class="[
                                    $style.counseling_icon,
                                    $style.online,
                                  ]"
                                ><i :class="$style.counseling_icon_inner" class="fa-regular fa-user-headset"/></i>
                                <i
                                  v-if="row._counselingType.counseling_type === 3"
                                  class="fa-solid fa-vr-cardboard"
                                  :class="[
                                    $style.counseling_icon,
                                    $style.virtual,
                                  ]"
                                ></i>
                              </p>
                            </li>
                            <li>
                              <label-box label="実施日" color="gray" size="sm" :class="$style.label"/>
                              <p :class="$style.content">{{ formatTimestamp(row.schedule.date, 'YYYY/MM/DD HH:00〜HH:45') }}</p>
                            </li>
                            <li>
                              <label-box label="お申込日時" color="gray" size="sm" :class="$style.label"/>
                              <p :class="$style.content">{{ formatTimestamp(row.created_at, 'YYYY/MM/DD HH:mm') }}</p>
                            </li>
                          </ul>
                        </div>
                        <div v-if="openIds.includes(row.id)">
                          <spacer :y="2"/>
                          <ul :class="$style.detail">

                            <li v-if="!isVirtual(row) && row.meeting">
                              <label-box label="URL" color="gray" size="sm" :class="$style.label"/>
                              <p :class="$style.content"><a :href="row.meeting.join_url" target="_blank">{{ row.meeting.join_url }}</a></p>
                            </li>
                            <li v-if="isVirtual(row)">
                              <label-box label="URL" color="gray" size="sm" :class="$style.label"/>
                              <p :class="$style.content"><a :href="getVirtualCastMedicalRooomURL(row.toUser.virtualCastProp.virtual_cast_room_key)" target="_blank">{{ getVirtualCastMedicalRooomURL(row.toUser.virtualCastProp.virtual_cast_room_key) }}</a></p>
                            </li>
                            <li v-if="isVirtual(row)">
                              <label-box label="Virtual Cast ID" color="gray" size="sm" :class="$style.label"/>
                              <div>
                                <p :class="$style.content">{{ row.fromUser.virtualCastProp.virtual_cast_id }}</p>
                                <p :class="$style.virtualcast_url"><a :href="getVirtualCastUserURL(row.fromUser.virtualCastProp.virtual_cast_id)" target="_branc">{{ getVirtualCastUserURL(row.fromUser.virtualCastProp.virtual_cast_id) }}</a></p>
                              </div>
                            </li>
                            <li>
                              <label-box label="お悩み" color="gray" size="sm" :class="$style.label"/>
                              <div :class="[$style.value, $style.type]">
                                <p
                                  v-for="item in row.type"
                                  :key="item">{{ helper.master.labels.reserve.type[item] }}<span v-if="row.other_text && item === 6">（{{ row.other_text }}）</span></p>
                              </div>
                            </li>
                            <li v-if="row.memos.customer.length">
                              <label-box label="自由欄" color="gray" size="sm" :class="$style.label"/>
                              <p :class="$style.content">{{ row.memos.customer[0].memo }}</p>
                            </li>
                            <li>
                              <label-box label="ココモニ" color="gray" size="sm" :class="$style.label"/>
                              <div :class="$style.content">
                                <p :class="$style.who5_score"><span :class="$style.who5_score_label">WHO-5</span>{{ row.scoreWho5 }}点</p>
                                <ul :class="$style.who5">
                                  <li v-for="(item, i) in helper.master.who5.items" :key="item">
                                    <p>{{ item.label }}</p>
                                    <p :class="$style.who5_answer">{{ helper.master.who5.labels[row.who5Answer.answers[i]] }}</p>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="n2br">
                              <label-box :label="isCompany(row) ? '企業コメント' : '学校コメント'" color="gray" size="sm" :class="$style.label"/>
                              <div :class="$style.content">
                                <p :class="$style.content" v-if="row.memos.teacher.length"
                                v-html="row.memos.teacher[0].memo"></p>
                                <p :class="$style.content" v-if="!row.memos.teacher.length">記入なし</p>
                              </div>
                            </li>
                            <li v-if="row.cancel_reason">
                              <label-box label="キャンセル理由" color="gray" size="sm" :class="$style.label"/>
                              <div :class="$style.content">
                                <p :class="$style.content">{{ helper.master.labels.reserve.cancel_reason[row.cancel_reason] }}{{ getAccountTypeLabel(row) }}</p>
                                <dl :class="$style.comment" v-if="row.memos.cancel.length">
                                  <dt>コメント</dt>
                                  <dd>{{ row.memos.cancel[0].memo }}</dd>
                                </dl>
                              </div>
                            </li>
                            <!-- <li>
                              <pre> {{ row.memos.operator[0].memo }} </pre>
                            </li> -->
                            <li class="n2br">
                              <label-box label="事務局メモ" color="gray" size="sm" :class="$style.label"/>
                              <p
                                :class="$style.content"
                                v-if="row.memos.operator.length && editId !== row.id"
                                v-html="row.memos.operator[0].memo"/>
                              <p :class="$style.content" v-else-if="row.id !== editId">登録なし</p>
                              <div v-else :class="$style.textarea_wrap">
                                <textarea
                                  name="memo"
                                  id="memo"
                                  rows="5"
                                  :class="$style.textarea"
                                  v-model="memo"/>
                                <spacer :y="1"/>
                                <btn-container>
                                  <basic-btn
                                    tag="button"
                                    size="sm"
                                    v-on:click="registMemo(row)">保存</basic-btn>
                                    <spacer :x="2"/>
                                  <basic-btn
                                    tag="button"
                                    size="sm"
                                    type="bdr"
                                    v-on:click="editStart(null)">やめる</basic-btn>
                                </btn-container>
                              </div>
                              <i
                                v-if="row.id !== editId"
                                class="fa-regular fa-pen-to-square"
                                :class="$style.editIcon"
                                v-on:click="editStart(row)"/>
                            </li>
                          </ul>
                          <spacer :y="3"
                            v-if="!row.report && row.flag === 11"/>
                          <btn-container v-if="!row.report && row.flag === 11">
                            <basic-btn
                              size="sm"
                              tag="button"
                              addClass="danger"
                              @click="cancel(row)">当日キャンセル</basic-btn>
                          </btn-container>
                          <spacer :y="3"
                            v-if="row.report && row.report.flag === 1"/>
                          <btn-container v-if="row.report && row.report.flag === 1">
                            <a :href="`/operator/report/${row.id}`" target="_blank" v-on:click="registReadFlag(row.id)">
                              <basic-btn tag="button">報告書兼意見書</basic-btn>
                            </a>
                          </btn-container>
                          <spacer :y="3"
                            v-if="row.report && row.report.flag === 2"/>
                          <btn-container v-if="row.report && row.report.flag === 2">
                            <a :href="`/operator/report/${row.id}`" target="_blank">
                              <basic-btn tag="button">報告書兼意見書(下書き)</basic-btn>
                            </a>
                          </btn-container>
                          <!-- <btn-container
                            :class="$style.deleteReserve">
                            <basic-btn
                              tag="button"
                              addClass="danger"
                              v-on:click="deleteReserve(row)">この履歴を削除する</basic-btn>
                          </btn-container> -->
                        </div>
                      </div>
                    </template>
                  </user-box>
                  <div
                    :class="[$style.check_status, statusLabel(row).class]"
                    v-if="statusLabel(row).isShow">{{ statusLabel(row).label }}</div>
                </stack-item>

                <spacer :y="3"/>
                <!-- 学校、医師・心理士の選択が変更されたときはページネーションが押されて、存在しないページ数に遷移しないようにする-->
              </ul>
              <!-- <div v-if="!reserves.length">該当する履歴はありません。</div> -->
              <pagination
                v-if="!flag.loader.reserves
                  && user.counseling.op.reserve.pastOptions.pageMax
                  && user.counseling.op.reserve.pastOptions.pageMax !== 1"
                :page="page"
                :pageMax="user.counseling.op.reserve.pastOptions.pageMax"
                :path="'/operator/?tab=history'" />
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import UserBox from '@/views/components/UserBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import DateSmallPicker from '@/views/components/DateSmallPicker.vue';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';
import Encoding from 'encoding';
import Pagination from '@/views/components/Pagination.vue';

export default {
  name: 'operator-history',
  mixins: [cf],
  components: {
    StackItem,
    ContentsBox,
    UserBox,
    LabelBox,
    LoaderSimple,
    Spacer,
    BasicBtn,
    BtnContainer,
    DateSmallPicker,
    Pagination,
  },
  data() {
    return {
      flag: {
        loader: {
          reserves: true,
          texts: true,
          generateBtn: false,
          totalCount: false,
        },
        pageQuery: false,
        excludeCancel: false,
        historyExcludeCancel: false,
        progressCountUnchecked: false,
        excludeTest: false,
        historyExcludeTest: false,
        update: false,
        historyUpdate: false,
        csvDL: {
          reserveAndVirtualReserve: true,
          reserve: true,
          virtualReserve: true,
          text: true,
          accounting: true,
          cocomoni: true,
        },
        reserveGet: true,
      },
      startDate: moment(new Date()).startOf('month').subtract(1, 'months').add(15, 'days')
        .format('YYYY-MM-DD'),
      endDate: moment(new Date()).startOf('month').add(14, 'days').format('YYYY-MM-DD'),
      historyStartDate: null,
      historyEndDate: null,
      editId: null,
      memo: null,
      reserves: [],
      csvReserves: [],
      texts: [],
      openIds: [],
      uncheckedCount: 0,
      sortedUncheckedCount: 0,
      schools: [],
      page: 1,
      pageMax: 1,
      limit: 10,
      counselors: [],
      // 学校、医師・心理士ごとの絞り込みをつけたため初期値をnullに変更
      targetSchool: null,
      targetCounselor: null,
      historyTargetCounselor: null,
      historyTargetSchool: null,
      sortedData: null,
    };
  },
  created() {
    const query = this.$route.query;
    if (query.page) {
      this.page = Number(query.page);
    } else if (query.tab === 'history') {
      // queryにpageがなければ追加してreplace
      this.$router.replace('/operator/?tab=history&page=1');
      return;
    }

    // CSV出力用のデータは出力するボタンが押された時のみ実行するよう変更
    this.getReserves();
    this.getSchools();
    this.getCounselor();
    // 期間の初期値を設定
    this.startDate = moment(new Date()).startOf('month').format('YYYY-MM-DD');
    this.endDate = moment(new Date()).endOf('month').format('YYYY-MM-DD');

    if (this.user.email) {
      this.initAccountingAccount();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/isAccountingAccount') {
          this.initAccountingAccount();
        }
      });
    }
  },
  computed: {
    ...mapState(['helper', 'user']),
    fitlyPeriod() {
      return moment(this.historyEndDate).isAfter(moment(this.historyStartDate));
    },
    isAccountingAccount() {
      return this.helper.isAccountingAccount || false;
    },
  },
  watch: {
    $route(to, old) {
      // ページネーションクリック時
      const query = this.$route.query;
      this.flag.loader.reserves = true;
      if (query.page) {
        this.page = Number(query.page);
      } else if (query.tab === 'history') {
        // queryにpageがなければ追加してreplace
        this.$router.replace('/operator/?tab=history&page=1');
        return;
      }
      if (to.query.page !== old.query.page && old.query.page && to.query.page) this.flag.pageQuery = true;
      this.getReserves();
    },
  },
  mounted() {
    // storeを監視して未確認予約件数をカウントし直し
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'user/update') {
        this.countUnchecked();
      }
    });
  },
  methods: {
    initAccountingAccount() {
      if (!this.isAccountingAccount) return;
      this.flag.csvDL = {
        reserveAndVirtualReserve: false,
        reserve: false,
        virtualReserve: false,
        text: false,
        accounting: true,
        cocomoni: false,
      };
    },
    async getCsvData() {
      this.flag.loader.generateBtn = true;

      const params = {
        start: this.startDate,
        end: this.endDate,
        text: this.flag.csvDL.text,
        online: this.flag.csvDL.reserve,
        virtual: this.flag.csvDL.virtualReserve,
        onlineAndVirtual: this.flag.csvDL.reserveAndVirtualReserve,
        accounting: this.flag.csvDL.accounting,
        cocomoni: this.flag.csvDL.cocomoni,
        exclusionCancel: this.flag.excludeCancel,
        excludeTest: this.flag.excludeTest,
        targetSchool: this.targetSchool,
        targetCounselor: this.targetCounselor,
      };
      this.axios({
        method: 'GET',
        url: '/v1/csv/get/csvData',
        params,
      })
        .then(async (response) => {
          const res = response.data.data;
          /**
           * safariでは1つのボタンでの並列ダウンロードを2つに制限しているため、
           * useragentでブラウザを判定してsafariであればsetTimeoutを使用する
           */
          let isSafari = false;
          const userAgent = window.navigator.userAgent;
          if (userAgent.includes('Safari')) isSafari = true;

          if (isSafari) {
            if (this.flag.csvDL.text) {
              await this.dlTextCsv(res.text);
            }

            if (this.flag.csvDL.reserve) {
              setTimeout(() => this.dlOnlineCsv(res.online, 'online'), 100);
            }

            if (this.flag.csvDL.virtualReserve) {
              setTimeout(() => this.dlOnlineCsv(res.virtual, 'virtual'), 150);
            }

            if (this.flag.csvDL.reserveAndVirtualReserve) {
              setTimeout(() => this.dlOnlineAndVirtualCsv(res.onlineAndVirtual), 200);
            }

            if (this.flag.csvDL.accounting) {
              setTimeout(() => this.dlAccounting(res.accounting), 250);
            }

            if (this.flag.csvDL.cocomoni) {
              setTimeout(() => this.generateCocomoniCSV(), 300);
            }
          } else {
            if (this.flag.csvDL.text) {
              await this.dlTextCsv(res.text);
            }

            if (this.flag.csvDL.reserve) {
              this.dlOnlineCsv(res.online, 'online');
            }

            if (this.flag.csvDL.virtualReserve) {
              this.dlOnlineCsv(res.virtual, 'virtual');
            }

            if (this.flag.csvDL.reserveAndVirtualReserve) {
              this.dlOnlineAndVirtualCsv(res.onlineAndVirtual);
            }

            if (this.flag.csvDL.accounting) {
              this.dlAccounting(res.accounting);
            }

            if (this.flag.csvDL.cocomoni) {
              this.generateCocomoniCSV();
            }
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loader.generateBtn = false;
        });
    },

    dlOnlineCsv(datas, fileNamePrefix) {
      const onlineDatas = datas.sort((a, b) => moment(b.schedule_date) - moment(a.schedule_date));
      const regionsMaster = this.helper.master.regions;
      const outputColumn = [
        '利用者ID',
        '予約ID',
        '医師心理士種別',
        '医師・心理士名',
        '報告書・指導区分',
        'オンライン健康相談実施日',
        '予約回目（キャンセル含む）',
        '実施日時区分1(13:00-15:00）',
        '実施日時区分2(16:00-18:00）',
        '実施日時区分3(19:00-21:00）',
        'お申込日時',
        '利用者登録日時',
        '性別',
        '相談時年齢',
        '学年',
        '利用者学校名',
        '使用カウント数',
        '予約キャンセル',
        '当日キャンセル',
        '友人との人間関係',
        '授業や成績のこと',
        '進路の悩み',
        '家族のこと',
        'こころの健康のこと',
        'その他',
        'その他記入欄',
        'WHO-5採点',
        '過去テキスト健康相談回数',
        'パワーメータ採点',
        '登録地域',
        '都道府県',
      ];
      const arr = [[...outputColumn]];

      onlineDatas.forEach((data) => {
        // キャンセルフラグの判定
        let usedCount = data.counselor_account_type === 22 ? 1 : 2;
        if (data.reserve_flag === 901) {
          usedCount = 'キャンセル';
        }
        // customer_idの成形
        let schoolId = data.school_id;
        let userId = data.user_id;
        if (data.school_old_id) schoolId = data.school_old_id;
        if (data.user_old_id) userId = data.user_old_id;
        const customerId = `${('0000' + schoolId).slice(-4)}-${data.account_type}-${('0000' + userId).slice(-4)}`; // eslint-disable-line
        // 相談時間の特定
        const scheduleHour = moment(data.schedule_date).format('HH');
        // 報告書・指導区分の特定
        let observeLabel = '報告書未記入';
        if (data.reserve_flag === 11 && data.observe) observeLabel = '経過観察';
        if (data.reserve_flag === 11 && data.re_counseling) observeLabel = '再カウンセリング';
        if (data.reserve_flag === 11 && data.re_online) observeLabel = '再健康相談';
        if (data.reserve_flag === 11 && data.medical_cooperation) observeLabel = '医療連携';
        // 登録地域の特定
        const regionIndex = regionsMaster.find((elem) => elem.label === data.pref);

        arr.push([
          customerId,
          data.reserve_id,
          data.counselor_account_type === 22 ? '心理士' : '医師',
          data.counselor_name,
          observeLabel,
          moment(data.schedule_date).format('YYYY-MM-DD HH:mm'),
          data.useCount,
          scheduleHour && scheduleHour >= 13 && scheduleHour <= 15 ? 1 : 0,
          scheduleHour && scheduleHour >= 16 && scheduleHour <= 18 ? 1 : 0,
          scheduleHour && scheduleHour >= 19 && scheduleHour <= 21 ? 1 : 0,
          moment(data.reserved_at).format('YYYY-MM-DD HH:mm'),
          moment(data.user_regist_date).format('YYYY-MM-DD HH:mm'),
          data.user_gender === 1 ? '男性' : '女性',
          data.user_age,
          data.school_year,
          data.school_name,
          usedCount,
          ((data.reserve_flag === 901) || (data.reserve_flag === 902)) ? 1 : '',
          data.reserve_flag === 902 ? 1 : '',
          JSON.parse(data.counseling_types).includes(1) ? 1 : '',
          JSON.parse(data.counseling_types).includes(2) ? 1 : '',
          JSON.parse(data.counseling_types).includes(3) ? 1 : '',
          JSON.parse(data.counseling_types).includes(4) ? 1 : '',
          JSON.parse(data.counseling_types).includes(5) ? 1 : '',
          JSON.parse(data.counseling_types).includes(6) ? 1 : '',
          data.others_texts || '',
          JSON.parse(data.who5_answers).reduce((sum, result) => sum + result) || '0',
          data.textUseCount || '0',
          this.user.cocomoni[data.user_id] || '',
          regionIndex.value,
          data.pref,
        ]);
      });

      this.downloadCSV(arr, `${fileNamePrefix}_${this.startDate}_${this.endDate}.csv`);
    },

    dlOnlineAndVirtualCsv(datas) {
      const onlineDatas = datas.sort((a, b) => moment(b.schedule_date) - moment(a.schedule_date));
      const regionsMaster = this.helper.master.regions;
      const outputColumn = [
        '形式',
        '利用者ID',
        '予約ID',
        '医師心理士種別',
        '医師・心理士名',
        '報告書・指導区分',
        'オンライン健康相談実施日',
        '予約回目（キャンセル含む）',
        '実施日時区分1(13:00-15:00）',
        '実施日時区分2(16:00-18:00）',
        '実施日時区分3(19:00-21:00）',
        'お申込日時',
        '利用者登録日時',
        '性別',
        '相談時年齢',
        '学年',
        '利用者学校名',
        '使用カウント数',
        '予約キャンセル',
        '当日キャンセル',
        '友人との人間関係',
        '授業や成績のこと',
        '進路の悩み',
        '家族のこと',
        'こころの健康のこと',
        'その他',
        'その他記入欄',
        'WHO-5採点',
        '過去テキスト健康相談回数',
        'パワーメータ採点',
        '登録地域',
        '都道府県',
      ];
      const arr = [[...outputColumn]];

      onlineDatas.forEach((data) => {
        // キャンセルフラグの判定
        let usedCount = data.counselor_account_type === 22 ? 1 : 2;
        if (data.reserve_flag === 901) {
          usedCount = 'キャンセル';
        }
        // customer_idの成形
        let schoolId = data.school_id;
        let userId = data.user_id;
        if (data.school_old_id) schoolId = data.school_old_id;
        if (data.user_old_id) userId = data.user_old_id;
        const customerId = `${('0000' + schoolId).slice(-4)}-${data.account_type}-${('0000' + userId).slice(-4)}`; // eslint-disable-line
        // 相談時間の特定
        const scheduleHour = moment(data.schedule_date).format('HH');
        // 報告書・指導区分の特定
        let observeLabel = '報告書未記入';
        if (data.reserve_flag === 11 && data.observe) observeLabel = '経過観察';
        if (data.reserve_flag === 11 && data.re_counseling) observeLabel = '再カウンセリング';
        if (data.reserve_flag === 11 && data.re_online) observeLabel = '再健康相談';
        if (data.reserve_flag === 11 && data.medical_cooperation) observeLabel = '医療連携';
        // 登録地域の特定
        const regionIndex = regionsMaster.find((elem) => elem.label === data.pref);

        arr.push([
          data.counseling_type === 3 ? 'バーチャル' : 'オンライン', // 形式
          customerId,
          data.reserve_id,
          data.counselor_account_type === 22 ? '心理士' : '医師',
          data.counselor_name,
          observeLabel,
          moment(data.schedule_date).format('YYYY-MM-DD HH:mm'),
          data.useCount,
          scheduleHour && scheduleHour >= 13 && scheduleHour <= 15 ? 1 : 0,
          scheduleHour && scheduleHour >= 16 && scheduleHour <= 18 ? 1 : 0,
          scheduleHour && scheduleHour >= 19 && scheduleHour <= 21 ? 1 : 0,
          moment(data.reserved_at).format('YYYY-MM-DD HH:mm'),
          moment(data.user_regist_date).format('YYYY-MM-DD HH:mm'),
          data.user_gender === 1 ? '男性' : '女性',
          data.user_age,
          data.school_year,
          data.school_name,
          usedCount,
          ((data.reserve_flag === 901) || (data.reserve_flag === 902)) ? 1 : '',
          data.reserve_flag === 902 ? 1 : '',
          JSON.parse(data.counseling_types).includes(1) ? 1 : '',
          JSON.parse(data.counseling_types).includes(2) ? 1 : '',
          JSON.parse(data.counseling_types).includes(3) ? 1 : '',
          JSON.parse(data.counseling_types).includes(4) ? 1 : '',
          JSON.parse(data.counseling_types).includes(5) ? 1 : '',
          JSON.parse(data.counseling_types).includes(6) ? 1 : '',
          data.others_texts || '',
          JSON.parse(data.who5_answers).reduce((sum, result) => sum + result) || '0',
          data.textUseCount || '0',
          this.user.cocomoni[data.user_id] || '',
          regionIndex.value,
          data.pref,
        ]);
      });

      this.downloadCSV(arr, `online_virtual_${this.startDate}_${this.endDate}.csv`);
    },

    dlTextCsv(datas) {
      const textDatas = datas.sort((a, b) => moment(b.accepted_at) - moment(a.accepted_at));
      const regionsMaster = this.helper.master.regions;
      const outputColumn = [
        '利用者ID',
        '予約者種別',
        '回答心理士名',
        'テキスト健康相談 回答日時',
        'テキスト健康相談 受付日時',
        '受付回目',
        '受付日時区分1(6:00-12:00）',
        '受付日時区分2(12:00-18:00）',
        '受付日時区分3(18:00-0:00）',
        '受付日時区分4(0:00-6:00）',
        '利用者学校名',
        '性別',
        '相談時年齢',
        '学年',
        '友人との人間関係',
        '授業や成績のこと',
        '進路の悩み',
        '家族のこと',
        'こころの健康のこと',
        'その他',
        'その他記入欄',
        'WHO-5採点',
        'パワーメータ採点',
        '登録地域',
        '都道府県',
      ];
      const arr = [[...outputColumn]];

      textDatas.forEach((data) => {
        // customer_idの成形
        let schoolId = data.school_id;
        let userId = data.user_id;
        if (data.school_old_id) schoolId = data.school_old_id;
        if (data.user_old_id) userId = data.user_old_id;
        const customerId = `${('0000' + schoolId).slice(-4)}-${data.account_type}-${('0000' + userId).slice(-4)}`; // eslint-disable-line
        // 予約者種別の特定
        let userType = '生徒';
        if (data.account_type === 2) userType = '保護者';
        if (data.account_type === 3) userType = '教職員';
        // 受付時間の特定
        const acceptedHour = moment(data.accepted_at).format('HH');

        // 登録地域の特定
        const regionIndex = regionsMaster.find((elem) => elem.label === data.pref);

        arr.push([
          customerId,
          userType,
          data.flag === 11 || data.flag === 21 ? data.answer[0].user_name : '未回答', // 下書きがあれば回答ありと判定していた
          data.flag === 11 || data.flag === 21 ? moment(data.replided_at).format('YYYY-MM-DD HH:mm') : '未回答', // 下書きがあれば回答ありと判定していた
          moment(data.accepted_at).format('YYYY-MM-DD HH:mm'),
          data.useCount,
          acceptedHour >= 6 && acceptedHour <= 11 ? 1 : 0,
          acceptedHour >= 12 && acceptedHour <= 17 ? 1 : 0,
          acceptedHour >= 18 && acceptedHour <= 23 ? 1 : 0,
          acceptedHour >= 0 && acceptedHour <= 5 ? 1 : 0,
          data.school_name,
          data.gender === 1 ? '男性' : '女性',
          this.calcCustomerAge(data.accepted_at, data.user_birthday) || '',
          data.school_year,
          JSON.parse(data.counseling_types).includes(1) ? 1 : '',
          JSON.parse(data.counseling_types).includes(2) ? 1 : '',
          JSON.parse(data.counseling_types).includes(3) ? 1 : '',
          JSON.parse(data.counseling_types).includes(4) ? 1 : '',
          JSON.parse(data.counseling_types).includes(5) ? 1 : '',
          JSON.parse(data.counseling_types).includes(6) ? 1 : '',
          data.others_text || '',
          JSON.parse(data.who5_answers).reduce((sum, result) => sum + result) || '0',
          this.user.cocomoni[data.user_id] || '',
          regionIndex.value,
          data.pref,
        ]);
      });

      this.downloadCSV(arr, `texts_${this.startDate}_${this.endDate}.csv`);
    },

    dlAccounting(datas) {
      const accDatas = datas.sort((a, b) => moment(b.schedule_date) - moment(a.schedule_date));
      const outputColumn = ['形式', '利用者ID', '医師・心理士名', 'オンライン健康相談実施日', '利用者学校名', '使用カウント数', '当日キャンセル'];
      const arr = [[...outputColumn]];

      accDatas.forEach((data) => {
        // customer_idの成形
        let schoolId = data.school_id;
        let userId = data.user_id;
        if (data.school_old_id) schoolId = data.school_old_id;
        if (data.user_old_id) userId = data.user_old_id;
        const customerId = `${('0000' + schoolId).slice(-4)}-${data.account_type}-${('0000' + userId).slice(-4)}`; // eslint-disable-line
        // 使用カウント数の集計
        let usedCount = data.counselor_account_type === 21 ? 2 : 1;
        if (data.reserve_flag === 901) usedCount = 'キャンセル';

        arr.push([
          data.counseling_type === 3 ? 'バーチャル' : 'オンライン', // 形式
          customerId,
          data.counselor_name,
          moment(data.schedule_date).format('YYYY-MM-DD HH:mm'),
          data.school_name,
          usedCount,
          data.reserve_flag === 902 ? 1 : '',
        ]);
      });

      this.downloadCSV(arr, `accounting_${this.startDate}_${this.endDate}.csv`);
    },

    isCompany(row) {
      return Boolean(row && row.fromUser && row.fromUser.school.length && row.fromUser.school[0].is_company);
    },

    // 該当予約までスクロール
    scroll2OpenItem() {
      // クエリでidが渡ってきたとき（ホームのステータスから飛んだとき）
      // this.openIdsにidを格納しその予約までスクロール
      const query = this.$route.query;
      if (query && query.open) {
        const id = Number(query.open);
        this.changeOpenIds({ id });
        this.$nextTick(() => {
          const elem = document.getElementById(`reserve_${id}`);
          const position = elem.getBoundingClientRect().top;
          scrollBy(0, position - 77);
        });
      }
    },

    scroll2Top() {
      // 上までスクロール
      const elem = document.getElementById('scroll-top');
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },

    getSchools() {
      this.axios({
        method: 'GET',
        url: '/v1/school/get/list',
        params: {
          isUsers: 0,
          isAddress: 0,
        },
      })
        .then((response) => {
          const res = response.data.data;
          this.schools = res.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    // 絞り込み条件変更の際にstoreをリセット
    // 新しい条件で再取得
    resetStore() {
      const commitData = {
        type: 'reserve',
        division: 'past',
        values: { 1: [] },
        options: {
          name: 'pastOptions',
          items: [
            { key: 'reservesTotal', value: 0 },
            { key: 'pageMax', value: 1 },
          ],
        },
      };
      this.$store.commit('user/counseling/putOperatorDatas', commitData);
    },

    updateSchool(e) {
      this.targetSchool = Number(e.target.value);
      if (!Number(e.target.value)) this.targetSchool = null;
      this.flag.update = true;
    },

    historyUpdateSchool(e) {
      this.historyTargetSchool = Number(e.target.value);
      if (!Number(e.target.value)) this.historyTargetSchool = null;
      this.flag.historyUpdate = true;
      this.page = 1;
      // this.resetStore();
      // this.getReserves();
    },

    // 医師・心理士の情報を取得
    getCounselor() {
      const params = {
        accountTypes: [21, 22],
        order: 'kana',
        isAddress: 0,
        isSchool: 0,
        isReadflags: 0,
      };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.counselors = res.users.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    updateCounselor(e) {
      this.targetCounselor = Number(e.target.value);
      if (!Number(e.target.value)) this.targetCounselor = null;
      this.flag.update = true;
    },

    historyUpdateCounselor(e) {
      this.historyTargetCounselor = Number(e.target.value);
      if (!Number(e.target.value)) this.historyTargetCounselor = null;
      this.flag.historyUpdate = true;
    },

    switchSortContents() {
      this.flag.update = true;
    },

    switchSortContentsHistory() {
      this.flag.historyUpdate = true;
    },

    /** userBox背景色指定用 */
    userBoxColor(user) {
      let color;
      if (user.risk.length && user.risk[user.risk.length - 1].type !== 11) {
        color = 'red';
      } else {
        color = 'white';
      }
      return color;
    },

    calcCustomerAge(target, birth) {
      const birthDate = moment(birth);
      const targetDate = moment(target);
      const age = targetDate.year() - birthDate.year();
      if (!age) return 'エラー';
      const thisYearsBirthDay = `${targetDate.year()}-${birthDate.month() + 1}-${birthDate.date()}`;
      const thisYearsBirthDate = moment(thisYearsBirthDay);
      const calcAge = age + ((thisYearsBirthDate.isAfter(targetDate)) ? -1 : 0);
      return calcAge;
    },

    /** 期間の始点と終点取得 */
    receiveDate(args) {
      if (args.date === 'Invalid date') this[args.name] = null;
      else this[args.name] = args.date;
      this.flag.update = true;
    },

    /** 履歴絞り込み期間の始点と終点取得 */
    historyReceiveDate(args) {
      if (args.date === 'Invalid date') this[args.name] = null;
      else this[args.name] = args.date;
      if (this.historyStartDate && !this.historyEndDate) this.historyEndDate = moment(new Date()).format('YYYY-MM-DD');
      this.flag.historyUpdate = true;
    },

    /** csv出力前確認 */
    async beforeGenerateCSV() {
      if (moment(this.startDate).isAfter(moment(this.endDate))) {
        return alert('期間の開始日と終了日を確認してください');
      }
      if (!this.flag.csvDL.reserve && !this.flag.csvDL.text && !this.flag.csvDL.accounting && !this.flag.csvDL.cocomoni) {
        return alert('ダウンロード対象を選択してください');
      }
      this.flag.loader.generateBtn = true;

      // ダウンロード対象に応じて必要なデータを取得
      if (this.flag.csvDL.accounting || (this.flag.csvDL.reserve && this.flag.csvDL.text)) {
        await this.getInitReserves();
        await this.getTexts();
      } else if (this.flag.csvDL.reserve) {
        await this.getInitReserves();
      } else if (this.flag.csvDL.text) {
        await this.getTexts();
      }

      if (this.flag.csvDL.reserve) {
        await this.generateOnlineCSV();
      }
      if (this.flag.csvDL.text) {
        await this.generateTextCSV();
      }
      if (this.flag.csvDL.accounting) {
        await this.generateAccountingCSV();
      }
      if (this.flag.csvDL.cocomoni) {
        await this.generateCocomoniCSV();
      }
      this.flag.loader.generateBtn = false;
    },

    /** オンライン健康相談csv出力 */
    generateOnlineCSV() {
      const outputColumn = [
        '利用者ID',
        '予約ID',
        '医師心理士種別',
        '医師・心理士名',
        '報告書・指導区分',
        'オンライン健康相談実施日',
        '予約回目（キャンセル含む）',
        '実施日時区分1(13:00-15:00）',
        '実施日時区分2(16:00-18:00）',
        '実施日時区分3(19:00-21:00）',
        'お申込日時',
        '利用者登録日時',
        '性別',
        '相談時年齢',
        '学年',
        '利用者学校名',
        '使用カウント数',
        '予約キャンセル',
        '当日キャンセル',
        '友人との人間関係',
        '授業や成績のこと',
        '進路の悩み',
        '家族のこと',
        'こころの健康のこと',
        'その他',
        'その他記入欄',
        'WHO-5採点',
        '過去テキスト健康相談回数',
        'パワーメータ採点',
        '登録地域',
        '都道府県',
      ];
      const reservesClone = cloneDeep(this.csvReserves);
      const arr = [[...outputColumn]];
      const testSchools = [13, 15];
      const consultationCounter = {};

      // 同一ユーザーの相談回数を集計
      reservesClone.forEach((target) => {
        if (Object.prototype.hasOwnProperty.call(consultationCounter, target.from_user_id)) consultationCounter[target.from_user_id] += 1;
        else consultationCounter[target.from_user_id] = 1;
      });

      // 配列をを2度回すのでなくカウントを持たせるほうがスマートではあるがひとまずこの方法で
      reservesClone.forEach((target) => {
        target.user_count = consultationCounter[target.from_user_id];
        if (consultationCounter[target.from_user_id] >= 2) consultationCounter[target.from_user_id] -= 1;
      });

      // 設定された絞り込み条件を適用して該当する予約のみを抽出
      const targetReserve = reservesClone.filter((reserve) => {
        const reserveDate = moment(reserve.created_at).startOf('day');
        const startDate = moment(this.startDate);
        const endDate = moment(this.endDate);
        return !(this.flag.excludeCancel && reserve.flag === 901)
        && !(this.flag.excludeTest && testSchools.includes(reserve.fromUser.school[0].id))
        && (
          ((this.startDate && this.endDate) && reserveDate.isBetween(startDate, endDate, null, '[]'))
          || ((this.startDate && !this.endDate) && reserveDate.isSameOrAfter(startDate))
          || ((!this.startDate && this.endDate) && reserveDate.isSameOrBefore(endDate))
          || (!this.startDate && !this.endDate)
        )
        && (!this.targetSchool
          || (reserve.fromUser
            && reserve.fromUser.school.length
            && reserve.fromUser.school[0].id === this.targetSchool))
        && (!this.targetCounselor
          || (reserve.toUser
            && reserve.toUser.username.length
            && reserve.toUser.id === this.targetCounselor));
      });

      const userMaster = this.helper.master.labels.users;
      const regionsMaster = this.helper.master.regions;

      // 指定された配列に出力
      targetReserve.forEach((target) => {
        /** 報告書・指導区分の表記 */
        let observeLabel = '';
        if (target.report && target.report.observe) observeLabel += '経過観察, ';
        if (target.report && target.report.re_counseling) observeLabel += '再カウンセリング, ';
        if (target.report && target.report.re_online) observeLabel += '再健康相談, ';
        if (target.report && target.report.medical_cooperation) observeLabel += '医療連携, ';
        // 「,」の数に応じてラベルを整形
        const commaCount = (observeLabel.match(/,/g) || []).length;
        if (commaCount === 1) {
          observeLabel = observeLabel.replace(/,/g, '');
        } else if (commaCount > 1) {
          observeLabel = observeLabel.slice(0, -2);
        }
        if (!target.report) observeLabel = '報告書未記入';
        if (target.report && !observeLabel.length) observeLabel = '異常なし';

        /** 実施日時のフォーマット(時のみの数値を取得) */
        const scheduleHour = target.schedule ? Number(moment(target.schedule.date).format('HH')) : 0;

        /** 使用カウント表記  flagが1: 未実行と999: 削除は予約として取得されない */
        let countLabel;
        if (target.countHistory && target.flag !== 901) { // 11:完了・902:当日キャンセルはカウント消費
          countLabel = target.countHistory.count;
        } else if (target.flag === 901) { // 901:事前キャンセルはカウント消費なし
          countLabel = 'キャンセル';
        } else { // ここへの振り分けは原則無いはず
          countLabel = '';
        }

        /** お悩み配列 (友人との人間関係~その他) */
        let onayamiArray = [];
        Object.keys(this.helper.master.labels.reserve.type).forEach((num) => {
          if (target.type && target.type.length) {
            const number = Number(num);
            if (target.type.includes(number)) {
              onayamiArray.push(1);
            } else {
              onayamiArray.push(0);
            }
          } else {
            onayamiArray = [0, 0, 0, 0, 0, 0];
          }
        });

        const userRegions = regionsMaster.find((region) => {
          if (!target.fromUser.address || !target.fromUser.address.pref) return false;
          if (region.label === target.fromUser.address.pref) {
            return true;
          }
          return false;
        });

        arr.push([
          target.fromUser ? target.fromUser.customer_id : '',
          target.id,
          target.toUser && target.toUser.account_type ? userMaster.account_type[target.toUser.account_type] : '',
          target.toUser ? target.toUser.username : '',
          observeLabel,
          target.schedule ? moment(target.schedule.date).format('YYYY-MM-DD HH:mm') : '',
          target.user_count, // ユーザーの累積利用回数
          scheduleHour && scheduleHour >= 13 && scheduleHour <= 15 ? 1 : 0,
          scheduleHour && scheduleHour >= 16 && scheduleHour <= 18 ? 1 : 0,
          scheduleHour && scheduleHour >= 19 && scheduleHour <= 21 ? 1 : 0,
          moment(target.created_at).format('YYYY-MM-DD HH:mm'),
          target.fromUser ? moment(target.fromUser.created_at).format('YYYY-MM-DD HH:mm') : '',
          target.fromUser && target.fromUser.gender ? userMaster.gender[target.fromUser.gender] : '未設定',
          target.customer_age || '',
          target.school_year,
          target.fromUser && target.fromUser.school.length && target.fromUser.school[0] ? target.fromUser.school[0].name : '',
          countLabel,
          target.flag === 901 || target.flag === 902 ? 1 : 0,
          target.flag === 902 ? 1 : 0,
          ...onayamiArray,
          target.other_text || '',
          target.scoreWho5 || '0',
          target.textCounselingDoneCount || '0',
          this.user.cocomoni[target.from_user_id] || '',
          userRegions ? userRegions.value : '',
          target.fromUser.address && target.fromUser.address.pref ? target.fromUser.address.pref : '',
        ]);
      });

      this.downloadCSV(arr, `online_${this.startDate}_${this.endDate}.csv`);
    },

    /** テキスト健康相談csv出力 */
    generateTextCSV() {
      const outputColumn = [
        '利用者ID',
        '予約者種別',
        '回答心理士名',
        'テキスト健康相談 回答日時',
        'テキスト健康相談 受付日時',
        '受付回目',
        '受付日時区分1(6:00-12:00）',
        '受付日時区分2(12:00-18:00）',
        '受付日時区分3(18:00-0:00）',
        '受付日時区分4(0:00-6:00）',
        '利用者学校名',
        '性別',
        '相談時年齢',
        '学年',
        '友人との人間関係',
        '授業や成績のこと',
        '進路の悩み',
        '家族のこと',
        'こころの健康のこと',
        'その他',
        'その他記入欄',
        'WHO-5採点',
        'パワーメータ採点',
        '登録地域',
        '都道府県',
      ];
      const textsClone = cloneDeep(this.texts);
      const arr = [[...outputColumn]];
      const testSchools = [13, 15];
      const consultationCounter = {};

      // 同一ユーザーの相談回数を集計
      textsClone.forEach((target) => {
        if (Object.prototype.hasOwnProperty.call(consultationCounter, target.user_id)) consultationCounter[target.user_id] += 1;
        else consultationCounter[target.user_id] = 1;
      });

      // reserve同様こちらも2度配列を回すのは良くないが今のところこれで対応
      textsClone.forEach((target) => {
        target.user_count = consultationCounter[target.user_id];
        if (consultationCounter[target.user_id] >= 2) consultationCounter[target.user_id] -= 1;
      });

      // 設定された絞り込み条件を適用して該当する相談のみを抽出
      const targetText = textsClone.filter((text) => {
        // if (!text.done || text.messages.length < 2) return false; // 回答が来ていない相談はcsv出力しない
        // const scheduleDate = moment(text.messages[1].updated_at).startOf('day'); // 下書きの場合があるためcreated_atではなくupdated_a
        const targetDate = moment(text.messages[0].created_at).startOf('day'); // 受付日基準
        const startDate = moment(this.startDate);
        const endDate = moment(this.endDate);
        return (
          ((this.startDate && this.endDate) && targetDate.isBetween(startDate, endDate, null, '[]'))
          || ((this.startDate && !this.endDate) && targetDate.isSameOrAfter(startDate))
          || ((!this.startDate && this.endDate) && targetDate.isSameOrBefore(endDate))
          || (!this.startDate && !this.endDate)
        )
        && !(this.flag.excludeTest && testSchools.includes(text.customer.school[0].id))
        && (!this.targetSchool
          || (text.customer
            && text.customer.school.length
            && text.customer.school[0].id === this.targetSchool));
      });

      const userMaster = this.helper.master.labels.users;
      const regionsMaster = this.helper.master.regions;

      // 指定された配列に出力
      targetText.forEach((target) => {
        /** 受付日時のフォーマット(時のみの数値を取得) */
        const scheduleHour = target.messages.length ? Number(moment(target.messages[0].created_at).format('HH')) : 0;

        /** お悩み配列 (友人との人間関係~その他) */
        let onayamiArray = [];
        Object.keys(this.helper.master.labels.reserve.type).forEach((num) => {
          if (target.type && target.type.length) {
            const number = Number(num);
            if (target.type.includes(number)) {
              onayamiArray.push(1);
            } else {
              onayamiArray.push(0);
            }
          } else {
            onayamiArray = [0, 0, 0, 0, 0, 0];
          }
        });

        const userRegions = regionsMaster.find((region) => {
          if (!target.customer.address || !target.customer.address.pref) return false;
          if (region.label === target.customer.address.pref) {
            return true;
          }
          return false;
        });

        arr.push([
          target.customer ? target.customer.customer_id : '',
          target.customer && target.customer.account_type ? userMaster.account_type[target.customer.account_type] : '',
          target.messages.length >= 2 && target.messages[1].user ? target.messages[1].user.username : '未回答',
          target.messages.length >= 2 ? moment(target.messages[1].updated_at).format('YYYY-MM-DD HH:mm') : '未回答', // 下書きの場合があるためcreated_atではなくupdated_at
          target.messages.length ? moment(target.messages[0].created_at).format('YYYY-MM-DD HH:mm') : '',
          target.user_count,
          scheduleHour >= 6 && scheduleHour <= 11 ? 1 : 0,
          scheduleHour >= 12 && scheduleHour <= 17 ? 1 : 0,
          scheduleHour >= 18 && scheduleHour <= 23 ? 1 : 0,
          scheduleHour >= 0 && scheduleHour <= 5 ? 1 : 0,
          target.customer && target.customer.school.length && target.customer.school[0] ? target.customer.school[0].name : '',
          target.customer && target.customer.gender ? userMaster.gender[target.customer.gender] : '未設定',
          target.messages.length ? this.calcCustomerAge(target.messages[0].created_at, target.customer.birthday) : '',
          target.school_year,
          ...onayamiArray,
          target.other_text || '',
          target.scoreWho5 || '0',
          this.user.cocomoni[target.customer.id] || '',
          userRegions ? userRegions.value : '',
          target.customer.address && target.customer.address.pref ? target.customer.address.pref : '',
        ]);
      });

      this.downloadCSV(arr, `texts_${this.startDate}_${this.endDate}.csv`);
    },

    /** 経理用csv出力 */
    generateAccountingCSV() {
      const outputColumn = ['利用者ID', '医師・心理士名', 'オンライン健康相談実施日', '利用者学校名', '使用カウント数', '当日キャンセル'];
      const reservesClone = cloneDeep(this.csvReserves);
      const arr = [[...outputColumn]];
      const testSchools = [13, 15];

      // 設定された絞り込み条件を適用して該当する予約のみを抽出
      const targetReserve = reservesClone.filter((reserve) => {
        const scheduleDate = moment(reserve.schedule.date).startOf('day');
        const startDate = moment(this.startDate);
        const endDate = moment(this.endDate);
        return !(this.flag.excludeCancel && reserve.flag === 901)
        && !(this.flag.excludeTest && testSchools.includes(reserve.fromUser.school[0].id))
        && (
          ((this.startDate && this.endDate) && scheduleDate.isBetween(startDate, endDate, null, '[]'))
          || ((this.startDate && !this.endDate) && scheduleDate.isSameOrAfter(startDate))
          || ((!this.startDate && this.endDate) && scheduleDate.isSameOrBefore(endDate))
          || (!this.startDate && !this.endDate)
        )
        && (!this.targetSchool
          || (reserve.fromUser
            && reserve.fromUser.school.length
            && reserve.fromUser.school[0].id === this.targetSchool))
        && (!this.targetCounselor
          || (reserve.toUser
            && reserve.toUser.username.length
            && reserve.toUser.id === this.targetCounselor));
      });

      // 指定された配列に出力
      targetReserve.forEach((target) => {
        // flagが1: 未実行と999: 削除は予約として取得されない
        let countLabel;
        if (target.countHistory && target.flag !== 901) { // 11:完了・902:当日キャンセルはカウント消費
          countLabel = target.countHistory.count;
        } else if (target.flag === 901) { // 901:事前キャンセルはカウント消費なし
          countLabel = 'キャンセル';
        } else { // ここへの振り分けは原則無いはず
          countLabel = '';
        }

        arr.push([
          target.fromUser ? target.fromUser.customer_id : '',
          target.toUser ? target.toUser.username : '',
          target.schedule ? moment(target.schedule.date).format('YYYY-MM-DD HH:mm') : '',
          target.fromUser && target.fromUser.school.length && target.fromUser.school[0] ? target.fromUser.school[0].name : '',
          countLabel,
          target.flag === 902 ? 1 : '',
        ]);
      });

      this.downloadCSV(arr, `accounting_${this.startDate}_${this.endDate}.csv`);
    },

    /** ココモニ利用履歴出力 */
    async generateCocomoniCSV() {
      // ココモニ利用履歴を取得
      const params = {
        between: [
          moment(this.startDate).format('YYYY-MM-DD 00:00:00'),
          moment(this.endDate).format('YYYY-MM-DD 23:59:59'),
        ],
        isCustomer: 1,
        isScore: 1,
        targetDays: -1,
        order: 'created_at',
      };
      let cocomoniData;
      await this.axios({
        method: 'GET',
        url: '/v1/mimosys/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          cocomoniData = res.data.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });

      if (!cocomoniData.length) {
        return alert('期間内に利用履歴がなかったため、ココモニ利用履歴CSVはダウンロードされませんでした。');
      }
      const outputColumn = ['利用者ID', '実施時間', '元気圧', '活量値', 'ステンスコア', '登録地域', '都道府県'];
      const arr = [[...outputColumn]];
      const regionsMaster = this.helper.master.regions;

      // 設定された絞り込み条件を適用して該当するココモニのみを抽出(日付はbackendで絞り込み済)
      // できれば複数行できれいに書きたいがlintに怒られるのでワンライナー
      const targetCocomoni = cocomoniData.filter((cocomoni) => !this.targetSchool || (cocomoni.customer && cocomoni.customer.school.length && cocomoni.customer.school[0].id === this.targetSchool));

      // 指定された配列に出力
      targetCocomoni.forEach((target) => {
        const userRegions = regionsMaster.find((region) => {
          if (!target.customer.address || !target.customer.address.pref) return false;
          if (region.label === target.customer.address.pref) {
            return true;
          }
          return false;
        });

        arr.push([
          target.customer ? target.customer.customer_id : '',
          moment(target.created_at).format('YYYY-MM-DD HH:mm:ss'),
          target.vitality,
          target.mental_activity,
          target.graphScore.score,
          userRegions ? userRegions.value : '',
          target.customer.address && target.customer.address.pref ? target.customer.address.pref : '',
        ]);
      });

      this.downloadCSV(arr, `cocomoni_${this.startDate}_${this.endDate}.csv`);
    },

    // csv化、Encoding
    downloadCSV(items, filename) {
      const label = '"';
      const csv = items.map((row) => row.map((str) => label + (str ? String(str).replace(/"/g, '""') : '') + label))
        .map((row) => row.join(',')).join('\n');
      const shiftJisCSV = Encoding.convert(csv, 'sjis', 'utf-8');

      this.downloadCsvBlob(shiftJisCSV, filename);
    },

    // ダウンロード
    downloadCsvBlob(blob, fileName) {
      if (window.navigator.msSaveOrOpenBlob) {
        // IE, Edge
        const blob1 = new Blob([blob], { type: 'text/csv' });
        window.navigator.msSaveOrOpenBlob(blob1, fileName);
      } else {
        // Chrome, Firefox, Safari
        const url = URL.createObjectURL(new Blob([blob], { type: 'text/csv' }));
        const linkEl = document.createElement('a');
        linkEl.href = url;
        linkEl.setAttribute('download', fileName);
        document.body.appendChild(linkEl);
        linkEl.click();
        URL.revokeObjectURL(url);
        linkEl.parentNode.removeChild(linkEl);
      }
    },

    statusLabel(reserve) {
      const object = {
        isShow: false,
        label: '',
        class: '',
      };
      if (this.user.readflags && !this.user.readflags.reserveReports.includes(reserve.id)) {
        object.isShow = true;
        if (reserve.flag === 901 || reserve.flag === 902) {
          object.label = 'キャンセル未確認';
          object.class = this.$style.blue;
        } else if (reserve.report && reserve.report.flag === 1) {
          object.label = '報告書未確認';
          object.class = this.$style.pink;
        } else if (reserve.report && reserve.report.flag === 2) {
          object.label = '報告書下書き';
          object.class = this.$style.ten_pink;
        } else {
          object.label = '報告書未記入';
          object.class = this.$style.orange;
        }
      }
      return object;
    },

    async getInitReserves() {
      const params = {
        flags: [1, 11, 901, 902],
        getDoneCount: 1,
        order: 'date',
        counseling_type: -1, // オンライン・バーチャル
      };
      await this.axios({
        method: 'GET',
        url: '/v1/reserve/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.csvReserves = res.reserves.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
        });
    },

    getReserves(btnFlag = null) { // ボタンからの検索はstoreに登録されていても有効にしたいため引数をとることにした
      // 重複した読み込みを防ぐ（createdとwatch等）
      if (!this.flag.reserveGet) return;
      // すでにstore登録されている場合もスルー
      if (this.user.counseling.op.reserve.past[this.page]
          && this.user.counseling.op.reserve.past[this.page].length
          && !btnFlag) {
        this.scroll2OpenItem();
        this.countSortedUnchecked();
        this.flag.loader.reserves = false;
        return;
      }

      if (btnFlag) { // ボタンから履歴が更新されたら報告書の未確認の件数ではなくトータルの件数を表示するようフラグ変更
        this.resetStore();
        this.flag.totalCount = true;
      }

      this.flag.reserveGet = false;
      this.flag.loader.reserves = true;
      let flags = [11, 901, 902];
      // キャンセルアカウントを除外
      if (this.flag.historyExcludeCancel) {
        flags = [11];
      }
      // 絞り込みボタンが押された時にpageを１に戻して、データがないと表示されるのを防ぐ
      if (this.flag.historyUpdate) {
        this.page = 1;
        this.flag.historyUpdate = false;
        const query = this.$route.query;
        // queryにもページを反映させる
        if (query.tab === 'history') {
          this.$router.replace('/operator/?tab=history&page=1');
        }
      }
      const page = this.page;
      const excludeTest = this.flag.historyExcludeTest;
      if (this.historyEndDate) this.historyEndDate = moment(this.historyEndDate).format('YYYY-MM-DD 23:59:59');
      const params = {
        flags,
        to_user_id: this.historyTargetCounselor,
        school_id: this.historyTargetSchool,
        isRisk: 1,
        start: this.historyStartDate,
        end: this.historyEndDate,
        excludeTest,
        getDoneCount: 1,
        page,
        limit: this.limit,
        counseling_type: -1, // オンライン・バーチャル
        order: 'date', // schedules.date の降順
      };
      this.axios({
        method: 'GET',
        url: '/v1/reserve/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          cf.downSortReserves(res.reserves.data);
          const commitData = {
            type: 'reserve',
            division: 'past',
            page: this.page,
            values: res.reserves.data,
            options: {
              name: 'pastOptions',
              items: [
                { key: 'reservesTotal', value: res.reserves.total },
                { key: 'pageMax', value: res.reserves.lastPage },
              ],
            },
          };
          this.$store.commit('user/counseling/putOperatorDatas', commitData);

          this.countUnchecked();
          this.scroll2OpenItem();
          this.countSortedUnchecked();
          this.flag.loader.reserves = false;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          if (this.flag.pageQuery) this.scroll2Top();
          this.flag.pageQuery = false;
          this.flag.reserveGet = true;
        });
    },

    countSortedUnchecked() {
      this.user.counseling.op.reserve.past[this.page].forEach((row) => {
        if (row.flag === 11 && row.report && row.report.flag === 1 && this.user.readflags && !this.user.readflags.reserveReports.includes(row.id)) {
          this.sortedUncheckedCount += 1;
        }
      });
    },

    async getTexts() {
      const params = {
        target_type: 1,
        includeDraft: 1,
      };
      await this.axios({
        method: 'GET',
        url: '/v1/messagegroup/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.groups.data;
          this.texts = res;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loader.texts = false;
        });
    },

    countUnchecked() {
      // 初回読み込み時にgetReservesとmountedからで2回走らないようにする
      if (this.flag.progressCountUnchecked) return;
      this.flag.progressCountUnchecked = true;
      this.uncheckedCount = 0;
      this.sortedUncheckedCount = 0;
      const excludeTest = this.flag.excludeTest;
      let flags = [11, 901, 902];
      // キャンセルアカウントを除外
      if (this.flag.excludeCancel) {
        flags = [11];
      }

      const params = {
        flags,
        to_user_id: this.targetCounselor,
        school_id: this.targetSchool,
        excludeTest,
        start: this.startDate,
        end: this.endDate,
      };
      this.axios({
        method: 'GET',
        url: '/v1/reserve/get/ids',
        params,
      })
        .then((response) => {
          const res = response.data;
          res.reserves.unfilteredData.forEach((row) => {
            if (row.flag === 11 && row.report && row.report.flag === 1 && this.user.readflags && !this.user.readflags.reserveReports.includes(row.id)) {
              this.uncheckedCount += 1;
            }
          });
          res.reserves.filteredData.forEach((row) => {
            if (row.flag === 11 && row.report && row.report.flag === 1 && this.user.readflags && !this.user.readflags.reserveReports.includes(row.id)) {
              this.sortedUncheckedCount += 1;
            }
          });
          this.flag.loader.reserves = false;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.progressCountUnchecked = false;
        });
    },

    changeOpenIds(reserve) {
      // キャンセルのものはここでreadflagを登録
      if ((reserve.flag === 901 || reserve.flag === 902) && this.user.readflags && !this.user.readflags.reserveReports.includes(reserve.id)) {
        this.registReadFlag(reserve.id);
      }
      if (this.openIds.includes(reserve.id)) {
        const idx = this.openIds.indexOf(reserve.id);
        this.openIds.splice(idx, 1);
      } else {
        this.openIds.push(reserve.id);
      }
    },

    registReadFlag(reserveId) {
      const data = {
        model_type: 4, // オンライン健康相談報告書
        user_id: this.user.id,
        foreign_id: reserveId,
      };
      this.axios({
        method: 'POST',
        url: '/v1/readflag/set/register',
        data,
      })
        .then(() => {
          this.$store.dispatch('user/update');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    deleteReserve(reserve) {
      if (!confirm(`この予約を削除してよろしいですか。\n利用者：${reserve.fromUser.username}\n担当：${reserve.toUser.username}\n実施日：${this.formatTimestamp(reserve.schedule.date, 'YYYY/MM/DD HH:00')}`)) return;

      const data = {
        id: reserve.id,
        flag: 999,
      };

      this.axios({
        method: 'POST',
        url: '/v1/reserve/set/updater',
        data,
      })
        .then(() => {
          this.getReserves();
          this.getInitReserves();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    editStart(reserve) {
      if (reserve) {
        this.editId = reserve.id;
        if (reserve.memos.operator.length) this.memo = reserve.memos.operator[0].memo;
        else this.memo = null;
      } else {
        this.editId = null;
        this.memo = null;
      }
    },

    registMemo(row) {
      const data = {
        id: row.memos.operator.length ? row.memos.operator[0].id : null,
        memo: this.memo,
        user_id: this.user.id,
        reserve_id: row.id,
      };
      const endpoint = data.id ? 'updater' : 'register';
      this.axios({
        method: 'POST',
        url: `/v1/reserve/memo/set/${endpoint}`,
        data,
      })
        .then(() => {
          this.editId = null;
          this.memo = null;
          this.getReserves();
          this.getInitReserves();
          alert('メモを更新しました。');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    isVirtual(reserve) {
      const counselingTypeVirtual = 3;
      if (reserve._counselingType) {
        return reserve._counselingType.counseling_type === counselingTypeVirtual;
      }
      if (reserve.type && reserve.type.counseling_type) {
        return reserve.type.counseling_type === counselingTypeVirtual;
      }
      return false;
    },

    cancel(reserve) {
      const args = {
        modalName: 'reserveCancel',
        data: {
          reserve_id: reserve.id,
          counseling_type: this.isVirtual(reserve) ? 3 : 1,
          schedule: reserve.schedule,
          operatorAsCounselor: true,
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: null });
    },
  },
};
</script>

<style lang="scss" module>
.output_btn {
  position: relative;
  top: 10px;
}

.history_search {
  &_title {
    font-size: 1.1em;
    font-weight: bold;
  }
}
.list {
  display: flex;
  align-items: center;
  cursor: pointer;

  &_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.label {
  width: 130px;
  margin-right: 20px;
}
.text_wrap {
  margin-left: 15px;
}
.id {
  font-size: 12px;
}
.clickarea {
  cursor: pointer;
}
.open {
  text-align: right;
  @include sm-view {
    margin-top: 10px;
  }
}
.icon {
  font-size: 20px;
  color: var(--gray-side);
}
.textarea {
  background-color: var(--gray-sub);
  padding: 13px;
  border: none;
  border-radius: 4px;
  resize: vertical;
  width: 100%;
  outline: none;
  &_wrap {
    flex: 1;
  }
  @include sm-view {
    margin-top: 5px;
  }
}
.editIcon {
  margin-left: 6px;
  margin-top: 6px;
}
.detail {
  li {
    display: flex;
    align-items: flex-start;
    .content {
      // font-size: 14px;
      margin-top: 3px;
      flex: 1;
      word-break: break-all;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  @include sm-view {
    font-size: 14px;
    li {
      display: block;
      .label {
        width: 100%;
        margin: 0 0 .5em;
        text-align: left;
        padding: .5em;
      }
      &:not(:first-child) {
        margin-top: 1.4em;
      }
    }
  }
}
.who5 {
  margin-top: 16px;
  li {
    justify-content: space-between;
  }
  &_score {
    &_label {
      margin-right: 12px;
    }
  }
}
.who5_answer {
  font-weight: bold;
}
.types {
  margin-right: 20px;
}
// .cancel {
//   background-color: silver;
// }
.checkBox {
  margin-left: 2em;
}
.check_status {
  position: absolute;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 4px;
  top: -3px;
  right: -3px;
  &_wrap {
    position: relative;
  }
  &.orange {
    background-color: var(--orange-main);
  }
  &.ten_pink {
    background-color: #f77e9e;
  }
  &.pink {
    background-color: var(--pink);
  }
  &.blue {
    background-color: var(--blue-main);
  }
}
.date {
  flex: 1;
  dl {
    display: flex;
    align-items: center;
    margin: 0;
  }
  dt {
    width: 9em;
    i {
      margin-right: .5em;
    }
  }
  dd {
    flex: 1;
    display: flex;
    margin-left: 2em;
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        margin-left: 1em;
        &:before {
          content: "~";
          margin-right: 1em;
        }
      }
    }
  }
  @include sm-view {
    dl {
      display: block;
      margin: 1em 0 .4em;
    }
    dt {
      font-size: 14px;
    }
    dd {
      margin: 0;
    }
  }
}
.alert_text {
  font-size: .8em;
  color: red;
}
.csv_btn {
  justify-content: flex-end;
}
.select {
  display: flex;
  > dt {
    width: 9em;
  }
  > dd {
    margin-inline-start: 0;
    align-items: center;
  }
  &_box {
    width: 300px;
    padding: 13px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;
    margin-left: 2em;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
.bold {
  font-weight: bold;
  font-size: 1.5em;
}
.deleteReserve {
  margin-top: 24px;
}
.value {
  flex: 1;
  margin-top: 3px;
  &.type {
    display: flex;
    flex-wrap: wrap;
    p {
      white-space: nowrap;
      &:not(:last-child) {
        &::after {
          margin: 0 5px;
          content: '/';
        }
      }
    }
  }
}
.virtualcast_url {
  font-size: 10px;
}
.counseling {
  display: flex;
  align-items: center;
  &_wrap {
    display: flex;
    align-items: center;
  }
  &_icon {
    font-size: 25px !important;
    position: relative;
    &_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      font-size: 10px !important;
    }
  }
}
.counseling_icon {
  &.online {
    color: var(--orange-main);
  }
  &.virtual {
    color: var(--green-main);
  }
}

.comment {
  font-size: 14px;
  margin-top: .6em;
  dt {
    font-weight: bold;
  }
  dd {
    margin: .2em 0 0 0;
  }
}
.flexCheckBox {
  display: flex;
  align-items: center;
  label {
    margin-right: 10px;
  }
}
</style>

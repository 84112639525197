<template>
  <div id="top" :class="isSignup ? $style.isSignup : ''"
    v-if="terms">
    <spacer :y="5"/>
    <h2>{{ terms.title }}</h2>
    <spacer :y="2"/>
    <p>{{ terms.subtitle }}</p>
    <spacer :y="2"/>
    <p v-for="d in terms.description" :key="d">{{ d }}</p>
    <spacer :y="4"/>
    <ul :class="$style.contents">
      <li v-for="(row) in terms.sections" :key="row" :class="$style.content">
        <div :class="$style.title">{{ row.title }}</div>
        <spacer :y="1"/>
        <div>
          <p v-for="d in row.description" :key="d">{{ d }}</p>
        </div>
        <ul :class="$style.list" v-if="row.list">
          <li v-for="contents in row.list" :key="contents">
            <p>{{ contents.title }}</p>
            <ul :class="$style.numlist" v-if="contents.numlist">
              <li v-for="num in contents.numlist" :key="num">
                <p>{{ num.title }}</p>
                <ul :class="$style.alphabetlist" v-if="num.alphabetlist">
                  <li v-for="a in num.alphabetlist" :key="a">
                    <p>{{ a.alphabet }} ) {{ a.title }}</p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <spacer :y="5"/>
    <div>
      <p>【附則】</p>
      <p v-for="date in terms.date" :key="date">{{ date }}</p>
    </div>
    <spacer :y="5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import userTerms from '@/data/terms/user.yaml';
import userTermsToCustomer from '@/data/terms/userToCustomer.yaml';
import teacherTerms from '@/data/terms/teacher.yaml';
import companyTerms from '@/data/terms/company.yaml';
import userTermsToCompany from '@/data/terms/userToCompany.yaml';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'terms',
  props: {
    isSignup: {
      type: Boolean,
    },
    schoolId: {
      type: Number,
    },
    isCompany: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Spacer,
  },
  data() {
    return {
      terms: null,
    };
  },
  created() {
    if (this.isSignup) {
      // サインナップ時はユーザー未登録
      this.terms = this.isCompany ? userTermsToCompany : userTerms;
    } else if (this.user.email) {
      this.getContents();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getContents();
        }
      });
    }
  },
  watch: {
    $route() {
      this.getContents();
    },
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    getContents() {
      const query = this.$route.query;
      if (query.type) {
        const type = query.type;
        switch (type) {
          case 'company': this.terms = companyTerms; break;
          case 'program': this.terms = userTermsToCompany; break;
          case 'individual': this.terms = userTermsToCustomer; break;
          default: this.terms = userTerms; break;
        }
        this.$scrollTo('#top', {
          offset: -300,
          duration: 200,
        });
        return;
      }

      if (this.user.school && this.user.school.length && this.user.school[0].is_company && this.$route.path.includes('teacher')) {
        this.terms = companyTerms;
      } else if (this.$route.path.includes('teacher')) {
        this.terms = teacherTerms;
      } else if (this.user.school && this.user.school.length && this.user.school[0].is_company) {
        this.terms = userTermsToCompany;
      } else if (!this.user.school.length
        || (this.user.school.length && this.user.school[0].name !== '個人向け')) {
        this.terms = userTerms;
      } else {
        this.terms = userTermsToCustomer;
      }

      this.$scrollTo('#top', {
        offset: -300,
        duration: 200,
      });
    },
  },
};
</script>

<style lang="scss" module>
.title {
  font-weight: bold;
}
.contents {
  .content {
    &:not(:first-child) {
      margin-top: 40px;
    }
  }
}
.list {
  li {
    padding-left: 30px;
    position: relative;
    counter-increment: bracketscnt;

    &::before {
        position: absolute;
        left: 0;
        content: counter(bracketscnt) " ) ";
    }

    .numlist {
      margin: 10px 0;
      li {
        padding-left: 30px;
        position: relative;
        counter-increment: numcnt;

        &::before {
            position: absolute;
            left: 0;
            content: counter(numcnt) ". ";
        }

        .alphabetlist {
          margin: 10px 0;
          li {
            counter-increment: alphabet;
            padding-left: 0;
            &::before {
              content: none;
            }
          }
        }
      }
    }
  }
}
.isSignup {
  font-size: 12px;
  h2 {
    margin-top: -1.8em;
  }
}

</style>

<template>
  <div v-if="helper.master.labels">
    <spacer :y="5"/>
    <ul>
      <stack-item>
        <contents-box
          :title="`${helper.master.labels.organizationTypes[is_company]}基本情報更新`"
          id="confirm">
          <div :class="$style.prev_right"><prev-btn /></div>
          <spacer :y="2" />
          <loader-simple :flag="flag.loader">
            <div v-show="!flag.loader">
              <div :class="$style.wrapper">
                <div
                  v-for="row in organizationTypes"
                  :key="row">
                  <div v-if="school && school.id" :class="$style.row">
                    <div :class="$style.label_wrap">
                      <p :class="$style.label">学校ID：{{ school.id }}</p>
                    </div>
                  </div>
                  <div :class="$style.row">
                    <div :class="$style.label_wrap">
                      <p :class="$style.label">{{ row.label }}</p>
                      <p v-if="row.required" :class="$style.required">※必須項目</p>
                    </div>
                    <div
                      :class="$style.detail"
                      v-if="row.type === 'radio'">
                      <ul :class="$style.radio">
                        <li
                          v-for="opt in row.options"
                          :key="opt">
                          <input
                            type="radio"
                            :name="row.name"
                            :id="opt.name"
                            :disabled="flag.isConfirm"
                            :value="opt.value"
                            v-model="this[row.name]">
                          <label :for="opt.name">{{ opt.label }}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div :class="$style.row" v-for="row in contents" :key="row">
                  <div :class="$style.label_wrap">
                    <p :class="$style.label">
                      {{ is_company && row.clabel ? row.clabel : row.label }}
                    </p>

                    <p v-if="row.required" :class="$style.required">※必須項目</p>
                  </div>
                  <!-- ラジオボタン（課金モデル） -->
                  <div :class="$style.detail" v-if="row.type === 'radio' && row.name === 'billing_type'">
                    <ul :class="$style.radio">
                      <li v-for="opt in row.options" :key="opt">
                        <input
                          type="radio"
                          :name="row.name"
                          :id="opt.name"
                          :disabled="flag.isConfirm"
                          :value="opt.value"
                          v-model="row.value"
                          v-on:change="updateBillingTypeValue">
                        <label :for="opt.name">{{ opt.label }}</label>
                      </li>
                    </ul>
                    <div>
                      <spacer :y="2" />
                      <ul :class="$style.contract">
                        <li v-for="row in measuredDatas" :key="row"
                          :class="$style.contract_list">
                          <div v-if="judgeDisplay(row)" :class="$style.label_wrap">
                            <p :class="$style.label">{{ row.label }}</p>
                            <p v-if="row.required" :class="$style.required">※必須項目</p>
                          </div>
                          <div v-if="row.inputType === 'radio'">
                            <ul v-if="row.cycle && contents[0].value === 1" :class="$style.radio">
                              <li v-for="opt in row.cycle" :key="opt">
                                <input
                                  type="radio"
                                  :name="row.label"
                                  :id="opt.label"
                                  :value="opt.value"
                                  v-model="row.value">
                                <label :for="opt.label">{{ opt.label }}</label>
                              </li>
                            </ul>
                          </div>
                          <div v-else-if="row.name === 'contractDate'"><!-- 契約年月日 -->
                            <ul :class="$style.select_wrap">
                              <li>
                                <select
                                  :class="$style.select"
                                  name="contractDate"
                                  id="year"
                                  @change="updateContractDate"
                                  v-model="row.values.year">
                                <option :value="0"></option>
                                <option
                                  v-for="n of 20"
                                  :key="n"
                                  :value="optionYear(n)">{{ optionYear(n) }}</option>
                                </select>年
                              </li>
                              <li>
                                <select
                                  :class="$style.select"
                                  name="contractDate"
                                  id="month"
                                  @change="updateContractDate"
                                  v-model="row.values.month">
                                <option :value="0"></option>
                                <option
                                  v-for="n of 12"
                                  :key="n"
                                  :value="n">{{ n }}</option>
                                </select>月
                              </li>
                              <li>
                                <select
                                  :class="$style.select"
                                  name="contractDate"
                                  id="day"
                                  @change="updateContractDate"
                                  v-model="row.values.day">
                                <option :value="0"></option>
                                <option
                                  v-for="n of 31"
                                  :key="n"
                                  :value="n">{{ n }}</option>
                                </select>日
                              </li>
                            </ul>
                          </div>
                          <input v-else
                            :class="$style.text"
                            :type="row.inputType"
                            v-model="row.value">
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!-- ラジオボタン（上記以外） -->
                  <div :class="$style.detail" v-if="row.type === 'radio' && row.name !== 'billing_type' && row.name !== 'course'">
                    <ul :class="$style.radio">
                      <li v-for="opt in row.options" :key="opt">
                        <input
                          type="radio"
                          :name="row.name"
                          :id="opt.name"
                          :disabled="flag.isConfirm"
                          :value="opt.value"
                          v-model="row.value">
                        <label :for="opt.name">{{ opt.label }}</label>
                      </li>
                    </ul>
                  </div>

                  <!-- テキスト -->
                  <div :class="$style.detail" v-if="row.type === 'text'">
                    <div>
                      <input
                        :class="$style.text"
                        :name="row.name"
                        :disabled="flag.isConfirm"
                        :placeholder="is_company && row.cplaceholder
                          ? row.cplaceholder
                          : row.placeholder"
                        type="text" v-model="row.value">
                    </div>
                    <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
                  </div>

                  <div :class="$style.detail" v-if="row.name === 'mail'">
                    <div>
                      <input
                        :class="$style.text"
                        :disabled="flag.isConfirm"
                        placeholder="メールアドレス"
                        v-model.trim="$v.mail.$model"
                        id="mail"
                        type="email"
                        name="mail"
                        v-on:input="invalidsCheck('mail', $v.mail.$invalid)">
                      <p
                        :class="$style.required"
                        v-if="$v.mail.$dirty
                          && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
                    </div>
                  </div>

                  <div :class="$style.detail" v-if="row.name === 'emailUser'">
                    <div>
                      <input
                        :class="$style.text"
                        :disabled="flag.isConfirm"
                        placeholder="メールアドレス"
                        v-model.trim="$v.mailUser.$model"
                        id="mailUser"
                        type="emailUser"
                        name="mailUser"
                        v-on:input="invalidsCheck('mailUser', $v.mailUser.$invalid)">
                      <p
                        :class="$style.required"
                        v-if="$v.mailUser.$dirty
                          && $v.mailUser.required.$invalid">メールアドレスを入力してください</p>
                      <p
                        :class="$style.required"
                        v-if="$v.mailUser.$dirty
                          && $v.mailUser.email.$invalid">正しいメールアドレスの形式で入力してください</p>
                    </div>
                  </div>
                </div>

                <div :class="$style.row">
                  <div :class="$style.label_wrap">
                    <p :class="$style.label">住所</p>
                    <p :class="$style.required">※必須項目</p>
                  </div>
                  <!--住所-->
                  <div :class="$style.detail">
                    <spacer :y="2"/>
                    <Address
                      @sendValue="receiveValue"
                      :items="items"
                      :isConfirm="flag.isConfirm"
                      ref="address" />
                  </div>
                </div>

                <div :class="$style.row">
                  <div :class="$style.label_wrap">
                    <p :class="$style.label">メモ（事務局のみ閲覧可）</p>
                  </div>
                  <div :class="$style.detail">
                    <spacer :y="2"/>
                    <textarea
                      :class="$style.textarea"
                      v-model="schoolMemo"
                      @change="flag.changeSchoolMemo = true"
                      :disabled="flag.isConfirm"
                      placeholder="備考"
                      rows="5"></textarea>
                  </div>
                </div>
              </div>
              <spacer :y="5"/>
              <btn-container>
                <basic-btn
                  tag="button"
                  v-if="!flag.isConfirm"
                  v-on:click="changeFlag">入力内容を確認する</basic-btn>
                <basic-btn
                  tag="button"
                  v-if="flag.isConfirm"
                  v-on:click="changeFlag">編集画面に戻る</basic-btn>
                <spacer :x="2"/>
                <basic-btn
                  tag="button"
                  v-if="flag.isConfirm"
                  v-on:click="update">入力内容を確定する</basic-btn>
              </btn-container>
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>

      <stack-item v-if="flag.csvForm">
        <contents-box title="利用者CSVインポート">
          <p>※CSVリストに空欄や不要データがある場合、該当行はスキップされ仮登録されません<br>「csvファイル名:ファイル送信日(弊社お受取日)_学園名_学校名_アカウント数_学年<br>例:230906_wtt_sussakurahighschool_350_2」 ※全て英数半角</p>
          <div
            :class="$style.read_csv"
            v-on:dragover="dragover"
            v-on:drop="updateFile">
            <input
              :class="$style.file_input"
              type="file"
              id="file"
              accept=".csv"
              v-on:change="updateFile">
            <p :class="$style.assistance">ドラッグ＆ドロップでもファイルを追加できます</p>
          </div>
          <div v-if="usersFile">
            <spacer :y="3" />
            <p :class="$style.file_name">{{ planeFileName }}</p>
            <spacer :y="2"/>
            <btn-container>
              <basic-btn
                tag="button"
                v-on:click="registSchoolList">登録する</basic-btn>
              <spacer :x="3"/>
              <basic-btn
                tag="button"
                v-on:click="deleteSchoolList">キャンセル</basic-btn>
            </btn-container>
          </div>
        </contents-box>
        <spacer :y="3"/>

        <contents-box title="メール配信制御">
          <div :class="$style.userCounts">
            <dl>
              <dt>本登録</dt>
              <dd>{{ userCounts.registed }} / <span>{{ userCounts.registed + userCounts.unregist }}</span></dd>
            </dl>
            <dl>
              <dt>仮登録</dt>
              <dd>{{ userCounts.unregist }} / <span>{{ userCounts.registed + userCounts.unregist }}</span></dd>
            </dl>
          </div>
          <spacer :y="1"/>
          <p :class="$style.assistance">該当のcsvファイルを選択し、「本登録お願いメール」または「リマインドメール」を配信します。</p>
          <p :class="$style.assistance">登録お願いメールはcsvリスト全員に配信が完了すると実行できなくなります。<br>リマインドメールは(本登録が済んでいない)仮登録者に配信されます。<br> ※リマインドメールは、配信時の仮登録者全てに、実行のたびに送信されます。</p>
          <p
            v-if="school.lti"
            :class="[$style.assistance, $style.danger]">※LTI対応校は仮登録者のメールアドレスを登録していないため、メール配信制御は行えません。</p>
          <spacer :y="1"/>
          <div :class="$style.selectCSV">
            <select
              :class="$style.select"
              v-model="selectedFilename"
              @change="getRemindFailedCount">
              <option :value="0">csvファイルを選択してください</option>
              <option
                v-for="n in importFiles"
                :key="n"
                :value="n">{{ n.name }}</option>
            </select>
            <div
              :class="$style.notes"
              v-if="selectedFilename && countFailedActivate">
              <spacer :y="1"/>
              <p :class="$style.bold">本登録メール送信失敗: {{ countFailedActivate }}件</p>
              <p>再度「本登録お願いメール送信」を実行してください。失敗した利用者さまにのみメールが送信されます。</p>
            </div>
            <div
              :class="$style.notes"
              v-if="selectedFilename && failedReminds.length">
              <spacer :y="1"/>
              <p :class="$style.bold">リマインドメール送信失敗: {{ failedReminds.length }}件</p>
              <p>「リマインドメール再送信」を実行してください。以下利用者さまにのみリマインドメールが送信されます。</p>
              <spacer :y="1"/>
              <ul>
                <li v-for="row in failedReminds"
                  :key="row.id">{{ row.targetUser.customer_id }} {{ row.targetUser.username }}</li>
              </ul>
            </div>

            <div
              :class="$style.remind_history"
              v-if="selectedFilename && selectedFilename.importRemind.length">
              <spacer :y="2" />
              <p :class="$style.unregistered">リマインドメール送信履歴</p>
              <table>
                <tr>
                  <td>送信日時</td>
                  <td :class="$style.remind_history_row">送信件数</td>
                </tr>
                <tr v-for="row in selectedFilename.importRemind" :key="row">
                  <td>{{ fromatDay(row.updated_at) }}</td>
                  <td :class="$style.remind_history_row">{{ row.target_count }} / <span>{{ selectedFilename.users.length }}</span>件</td>
                </tr>
              </table>
            </div>
          </div>
          <spacer :y="2"/>
          <btn-container>
            <basic-btn
              tag="button"
              :disabled="(!selectedFilename || countFailedActivate === false) || school.lti"
              v-on:click="activate(selectedFilename.users)">本登録お願いメール</basic-btn>
            <spacer :x="2"/>
            <basic-btn
              tag="button"
              :disabled="(!selectedFilename || failedReminds.length) || school.lti"
              v-on:click="unregistCheck(selectedFilename.users)">リマインドメール</basic-btn>
            <spacer :x="2" v-if="selectedFilename || failedReminds.length" />
            <basic-btn
              tag="button"
              v-if="selectedFilename && failedReminds.length"
              @click="resendRemind">リマインドメール再送信</basic-btn>
          </btn-container>

          <div v-if="unregistUsers.length">
            <spacer :y="2"/>
            <p :class="$style.unregistered">仮登録者リスト ダウンロード</p>
            <spacer :y="1"/>
            <p :class="$style.assistance">上記の選択csvファイルのうち、現時点での仮登録者リストをダウンロードします。</p>
            <spacer :y="2"/>
            <btn-container>
              <basic-btn
                tag="a"
                :href="downloadUrl"
                :download="fileName"
                :class="[$style.downloadBTN, !selectedFilename ? $style.disabled : '']">ダウンロード</basic-btn>
            </btn-container>
          </div>

          </contents-box>
      </stack-item>

      <!-- アカウント課金 -->
      <stack-item v-if="school && school.billing_type && billing_type === 'account'">
        <contents-box title="インポートcsv 利用回数">
          <p :class="$style.assistance">選択したCSVファイルに紐づく利用者の利用可能回数を一括で調整できます。<br>
            ※デフォルト回数　心理士10回/医師2回<br>
            ※年度末3/31に自動的リセット（契約始期に問わず）<br>
            ※CSVファイルを選択せず追加を行うと学校に所属する利用者全員に一括で編集を行えます
          </p>

          <spacer :y="2" />
          <div :class="$style.flex_elem">
            <b>調整履歴</b>
            <spacer :x="1" />
            <p
              v-if="!flag.openBulkCountHistory"
              :class="$style.icon_gray"
              @click="changeOpenBulkCountHistory"><i class="fa-solid fa-plus"></i></p>
            <p
              v-if="flag.openBulkCountHistory"
              :class="$style.icon_gray"
              @click="changeOpenBulkCountHistory"><i class="fa-solid fa-minus"></i></p>
          </div>
          <div v-if="flag.openBulkCountHistory">
            <spacer :y="3" />
            <div v-if="!bulkCountHistorys.length">調整履歴はありません</div>
            <div v-else :class="[$style.categories, $style.max_width]">
              <dl :class="$style.category">
                <dt>編集日</dt><dt>医師・心理士分類</dt><dt>内容</dt><dt>範囲</dt>
              </dl>
              <!-- <dl :class="[$style.category, $style.pointer]"
                @click="jumpUserList(history.import_file)"
                v-for="history in bulkCountHistorys" :key="history"> -->
              <dl :class="$style.category"
                v-for="history in bulkCountHistorys" :key="history">
                <dd v-for="data in Object.values(history)" :key="data">
                  <p :class="$style.width_break">{{ data }}</p>
                </dd>
              </dl>
            </div>
            <spacer :y="3" />
          </div>

          <spacer :y="2"/>
          <div :class="$style.flex_elem">
            <select
              :class="$style.select"
              v-model="creditTargetFile"
              @change="updateCreditTargets">
              <option :value="null">選択してください</option>
              <option :value="0">全体</option>
              <option
                v-for="n in importFiles"
                :key="n"
                :value="n">{{ n.name }}</option>
            </select>
            <spacer :x="8" />
            <limit-counter
            :flag="flag"
            :school="school"
            :isOperator="true"
            :isBulkEdit="true"
            @updateTarget="updateTargetCount"
            :targetFile="creditTargetFile"
            :creditTarget="creditTargets" />
          </div>
        </contents-box>
      </stack-item>

      <!-- 学校へのクレジットは共通 -->
      <stack-item v-if="school">
        <contents-box title="オンライン健康相談" icon="<i class='fa-solid fa-coins'></i>">
          <loader-simple :flag="flag.loader">
            <div
              :class="$style.label_wrap"
              v-if="school">
              <p :class="$style.assistance">学校が購入したクレジットを付与します</p>
            </div>
            <spacer :y="2"/>

            <div :class="$style.label_wrap">
              <p :class="$style.label">オンライン・バーチャル　保有クレジット: {{ this.schoolCredit }}</p>
            </div>
            <spacer :y="2"/>

            <div v-if="!flag.loader">
              <div :class="$style.count">
                <input
                  :class="$style.text_count"
                  name="count"
                  :disabled="flag.isConfirm"
                  v-model="credit"
                  placeholder="クレジットを入力"
                  type="number"
                  min="1">
                <spacer :y="2"/>
                <p :class="$style.label">クレジット</p>
                <spacer :y="4"/>
                <basic-btn
                  tag="button"
                  @click="setCredit(1)">追加</basic-btn>
                <spacer :y="4"/>
                <basic-btn
                  tag="button"
                  addClass="danger"
                  @click="setCredit(0)">削除</basic-btn>
              </div>
            </div>
          </loader-simple>
          <div v-if="school && school.billing_type && billing_type === 'account'">
            <spacer :y="5" />
            <div :class="$style.label_wrap">
              <p :class="$style.label">個人を対象にクレジットを編集します</p>
            </div>
            <p :class="$style.assistance">※学校の保有クレジットには影響しません</p>
            <spacer :y="2" />
            <limit-counter
              :flag="flag"
              :school="school"
              :creditTarget="creditTargets"
              :isOperator="true"
              :sustainableAddCreditHistory="true"
              @updateTarget="regetTargets" />
            <spacer :y="2"/>
            <div :class="$style.sortBox">
              <div :class="$style.top">
                <div :class="$style.search_wrap">
                  <word-search-form
                    :class="$style.search_word"
                    v-on:sendValue="wordReceiveValue"
                    :placeholder="isCompany ? '利用者を検索' : '生徒を検索'" />
                  <div :class="$style.search_button">
                    <basic-btn
                      tag="button"
                      @click="getUsers()">検索</basic-btn>
                  </div>
                </div>
                <div v-if="!isCompany" :class="$style.birth">
                  <spacer :y="2" />
                  <dl>
                    <dt><i class="fa-solid fa-graduation-cap"></i>学年</dt>
                    <dd>
                      <div>
                        <select
                          :class="$style.select_box"
                          v-model="schoolYear"
                          name="schoolYear"
                          id="schoolYear"
                          @change="updateSchoolYear">
                          <option :value="null">選択なし</option>
                          <option
                            v-for="n of setSchoolYear()"
                            :key="n"
                            :value="n">{{ n }}</option>
                        </select>
                      </div>
                    </dd>
                  </dl>
                </div>
                <div v-if="flag.exeSearch">
                <div>
                  <spacer :y="2"/>
                  <input type="checkbox" id="bulkChek" v-model="bulkChek">
                  <label for="bulkChek">一括チェック</label>
                  <spacer :y="2"/>
                </div>
                <stack-item v-for="row in creditTargets" :key="row.id">
                  <div :class="$style.check_user">
                    <input
                      :class="$style.user_check_box"
                      type="checkbox"
                      :id="`checked_${row.id}`"
                      :value="row.id"
                      v-model="creditTargetIds"
                      @change="updateTargets" />
                    <label
                      :for="`checked_${row.id}`"
                      :class="$style.check_user_box">
                      <user-box
                        :showId="true"
                        :showCocomoniBtn="true"
                        :ownUser="user"
                        v-if="users.length"
                        :user="row"
                        saveTab="students"
                        :bg="userBoxColor(row)">
                      </user-box>
                    </label>
                  </div>
                </stack-item>
                <stack-item v-for="row in filteredUsers" :key="row.id">
                  <div :class="$style.check_user">
                    <input
                      :class="$style.user_check_box"
                      type="checkbox"
                      :id="`stillCheck_${row.id}`"
                      :value="row.id"
                      v-model="creditTargetIds"
                      @change="updateTargets" />
                    <label
                      :for="`stillCheck_${row.id}`"
                      :class="$style.check_user_box">
                      <user-box
                        :showId="true"
                        :showCocomoniBtn="true"
                        :ownUser="user"
                        v-if="users.length"
                        :user="row"
                        saveTab="students"
                        :bg="userBoxColor(row)">
                      </user-box>
                    </label>
                  </div>
                </stack-item>
              </div>
              </div>
            </div>
          </div>
        </contents-box>
      </stack-item>

      <stack-item v-if="school && school.billing_type.billing_type === 1">
        <contents-box title="テキスト健康相談" icon="<i class='fa-sharp fa-light fa-abacus'></i>">
          <loader-simple :flag="flag.loader">
            <div
              :class="$style.label_wrap"
              v-if="school">
              <p :class="$style.assistance">学校が購入したテキスト利用回数を付与します</p>
            </div>
            <spacer :y="2"/>

            <div :class="$style.label_wrap">
              <p :class="$style.label">テキスト　利用可能回数: {{ this.schoolTextCredit }}</p>
            </div>
            <spacer :y="2"/>

            <div v-if="!flag.loader">
              <div :class="$style.count">
                <input
                  :class="$style.text_count"
                  name="count"
                  :disabled="flag.isConfirm"
                  v-model="textCredit"
                  placeholder="カウントを入力"
                  type="number"
                  min="1">
                <spacer :y="2"/>
                <p :class="$style.label">回</p>
                <spacer :y="4"/>
                <basic-btn
                  tag="button"
                  @click="setTextCredit(1)">追加</basic-btn>
                <spacer :y="4"/>
                <basic-btn
                  tag="button"
                  addClass="danger"
                  @click="setTextCredit(0)">削除</basic-btn>
              </div>
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>

      <stack-item>
        <contents-box title="主管理者情報">
          <loader-simple :flag="flag.loader">
            <div v-if="!flag.loader">
              <ul :class="$style.users">
                <li
                  v-for="user in school.mainUser"
                  :key="user.id">
                  <div :class="$style.user_wrap">
                    <contents-box type="inner">
                      <div v-if="mainValues.id !== user.id">
                        <ul :class="$style.user">
                          <li
                            v-for="item in userItems"
                            :key="item"
                            v-show="item.name !== 'kana'">
                            <p :class="$style.user_value" v-if="user[item.name]"><i :class="item.icon"/>{{ user[item.name] }}<span v-if="item.name === 'username' && user.kana">（{{ user.kana }}）</span></p>
                            <p :class="$style.user_value" v-else><i :class="item.icon"/>登録なし</p>
                          </li>
                        </ul>
                        <spacer :y="2"/>
                        <btn-container align="right">
                          <basic-btn
                            tag="button"
                            size="sm"
                            v-on:click="editStart(user, 'main')">編集</basic-btn>
                        </btn-container>
                      </div>
                      <div v-else>
                        <ul>
                          <li
                            v-for="item in userItems"
                            :key="item">
                            <div :class="$style.label_wrap">
                              <p :class="$style.label">{{ item.label }}</p>
                            </div>
                            <div :class="$style.detail">
                              <input
                                v-if="item.name === 'email'"
                                :class="$style.text"
                                id="mainUser"
                                type="email"
                                name="mainUser"
                                v-model.trim="$v.mainUser.$model"
                                v-model="mainValues[item.name]">
                              <input
                                v-else
                                type="text"
                                v-model="mainValues[item.name]"
                                :class="$style.text">
                              <p v-if="item.name === 'email'
                                && $v.mainUser.$dirty
                                && $v.mainUser.email.$invalid"
                                :class="$style.required">正しいメールアドレスの形式で入力してください</p>
                            </div>
                          </li>
                        </ul>
                        <spacer :y="2"/>
                        <btn-container>
                          <basic-btn
                            tag="button"
                            size="sm"
                            v-on:click="updateUser('main')">更新</basic-btn>
                          <spacer :x="2"/>
                          <basic-btn
                            tag="button"
                            size="sm"
                            type="bdr"
                            v-on:click="editCancel('main')">戻る</basic-btn>
                        </btn-container>
                      </div>
                    </contents-box>
                  </div>
                </li>
              </ul>
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>
      <stack-item>
        <contents-box title="副管理者情報">
          <loader-simple :flag="flag.loader">
            <div v-if="!flag.loader">
              <ul :class="$style.users">
                <li
                  v-for="user in school.subUser"
                  :key="user.id">
                  <div :class="$style.user_wrap">
                    <contents-box type="inner">
                      <div v-if="subValues.id !== user.id">
                        <ul :class="$style.user">
                          <li
                            v-for="item in userItems"
                            :key="item"
                            v-show="item.name !== 'kana'">
                            <p :class="$style.user_value" v-if="user[item.name]"><i :class="item.icon"/>{{ user[item.name] }}<span v-if="item.name === 'username' && user.kana">（{{ user.kana }}）</span></p>
                            <p :class="$style.user_value" v-else><i :class="item.icon"/>登録なし</p>
                          </li>
                        </ul>
                        <spacer :y="2"/>
                        <btn-container align="right">
                          <basic-btn
                            tag="button"
                            size="sm"
                            v-on:click="editStart(user, 'sub')">編集</basic-btn>
                        </btn-container>
                      </div>
                      <div v-else>
                        <ul>
                          <li
                            v-for="item in userItems"
                            :key="item">
                            <div :class="$style.label_wrap">
                              <p :class="$style.label">{{ item.label }}</p>
                            </div>
                            <div :class="$style.detail">
                              <input
                                v-if="item.name === 'email'"
                                type="email"
                                id="subUser"
                                neme="subUser"
                                v-model.trim="$v.subUser.$model"
                                v-model="subValues[item.name]"
                                :class="$style.text">
                              <input
                                v-else
                                type="text"
                                v-model="subValues[item.name]"
                                :class="$style.text">
                              <p v-if="item.name === 'email'
                                && $v.subUser.$dirty
                                && $v.subUser.email.$invalid"
                                :class="$style.required">正しいメールアドレスの形式で入力してください</p>
                            </div>
                          </li>
                        </ul>
                        <spacer :y="2"/>
                        <btn-container>
                          <basic-btn
                            tag="button"
                            size="sm"
                            v-on:click="updateUser('sub')">更新</basic-btn>
                          <spacer :x="2"/>
                          <basic-btn
                            tag="button"
                            size="sm"
                            type="bdr"
                            v-on:click="editCancel('sub')">戻る</basic-btn>
                          <spacer :x="2"/>
                          <basic-btn
                            tag="button"
                            size="sm"
                            addClass="danger"
                            v-on:click="deleteSubUser('sub')">削除</basic-btn>
                        </btn-container>
                      </div>
                    </contents-box>
                  </div>
                </li>
                <li v-if="!school.subUser.length">
                  <div :class="$style.user_wrap">
                    <contents-box type="inner">
                      <div v-if="!flag.registSubUser">
                        <basic-btn
                          tag="button"
                          size="sm"
                          v-on:click="editStart(null, 'sub')">登録する</basic-btn>
                      </div>
                      <div v-else>
                        <ul>
                          <li
                            v-for="item in userItems"
                            :key="item">
                            <div :class="$style.label_wrap">
                              <p :class="$style.label">{{ item.label }}</p>
                            </div>
                            <div :class="$style.detail">
                              <input
                                type="text"
                                v-model="subValues[item.name]"
                                :class="$style.text">
                            </div>
                          </li>
                        </ul>
                        <spacer :y="2"/>
                        <btn-container>
                          <basic-btn
                            tag="button"
                            size="sm"
                            v-on:click="updateUser('sub')">更新</basic-btn>
                          <spacer :x="2"/>
                          <basic-btn
                            tag="button"
                            size="sm"
                            type="bdr"
                            v-on:click="editCancel('sub')">戻る</basic-btn>
                        </btn-container>
                      </div>
                    </contents-box>
                  </div>
                </li>
              </ul>
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>
      <!-- 学年未登録者ダウンロード -->
<!--
      <stack-item>
        <unregist-school-year
          :school="school"/>
      </stack-item>
-->
    </ul>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import { Address } from '@/views/components/parts/Form';
import Spacer from '@/views/components/Spacer.vue';
import LimitCounter from '@/views/components/LimitCounter4BillingAccount.vue';
import WordSearchForm from '@/views/components/WordSearchForm.vue';
import UserBox from '@/views/components/UserBox.vue';
import PrevBtn from '@/views/components/PrevBtn.vue';
// import UnregistSchoolYear from '@/views/components/UnregistSchoolYear.vue';
import cf from '@/mixins/commonFunctions.js';
import moment from 'moment';

export default {
  name: 'operator-updateSchool',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    StackItem,
    ContentsBox,
    LoaderSimple,
    Spacer,
    Address,
    LimitCounter,
    WordSearchForm,
    UserBox,
    PrevBtn,
    // UnregistSchoolYear,
  },
  data() {
    return {
      flag: {
        loader: true,
        isConfirm: false,
        readySubmit: false,
        registSubUser: false,
        csvForm: false,
        changeSchoolMemo: false,
        openBulkCountHistory: false,
      },
      validationFlags: {
        mail: false,
        mailUser: false,
        mainUser: false,
        subUser: false,
      },
      invalids: {
        mail: false,
        mailUser: true,
        mainUser: true,
        subUser: true,
      },
      school: null,
      schoolYear: null,
      searchSchoolYear: null, // 学年検索機能追加のため
      schoolMemo: null,
      billing_type: 'default',
      usersFile: null,
      userList: [],
      creditTargetFile: null,
      creditTargets: [],
      creditTargetIds: [],
      bulkCountHistorys: [],
      bulkChek: false,
      registSituation: {
        completion: [],
        temporary: [],
      },
      courseOptions: {
        account: [
          {
            name: 'account-1',
            label: '心理士 年10回',
            value: 1,
          },
          {
            name: 'account-2',
            label: '医師　年２回',
            value: 2,
          },
        ],
      },
      is_company: 0,
      organizationTypes: [
        {
          type: 'radio',
          name: 'is_company',
          label: '組織タイプ',
          required: true,
          value: 0,
          options: [
            {
              name: 'school',
              label: '学校',
              value: 0,
            },
            {
              name: 'company',
              label: '企業',
              value: 1,
            },
          ],
        },
      ],
      contents: [
        // contents[0]は課金モデルで固定
        {
          type: 'radio',
          name: 'billing_type',
          label: '課金モデル',
          required: true,
          value: 1,
          datatype: 2,
          options: [
            {
              name: 'default',
              label: '従量課金',
              value: 1,
            },
            {
              name: 'account',
              label: 'アカウント課金',
              value: 2,
            },
          ],
        },
        {
          type: 'radio',
          name: 'school_type',
          label: '学校タイプ',
          required: true,
          value: 1,
          datatype: 1,
          options: [
            {
              name: 'elementary',
              label: '小学校',
              value: 1,
            },
            {
              name: 'juniorHigh',
              label: '中学校',
              value: 2,
            },
            {
              name: 'high',
              label: '高校',
              value: 3,
            },
            {
              name: 'univ',
              label: '専門・短大・大学',
              value: 4,
            },
            {
              name: 'free',
              label: 'フリースクール',
              value: 5,
            },
          ],
        },
        {
          type: 'text',
          name: 'name',
          label: '学校名',
          clabel: '企業名',
          required: true,
          placeholder: '例：〇〇高校',
          cplaceholder: '例：〇〇株式会社',
          value: '',
          datatype: 1,
        },
        {
          type: 'text',
          name: 'kana',
          label: 'フリガナ',
          placeholder: '例：◯◯コウコウ',
          cplaceholder: '例：◯◯カブシキガイシャ',
          value: '',
          datatype: 1,
        },
        {
          type: 'text',
          name: 'tel',
          label: '電話番号',
          required: true,
          value: '',
          datatype: 1,
        },
        // {
        //   type: 'text',
        //   name: 'fax',
        //   label: 'fax',
        //   value: '',
        //   datatype: 1,
        // },
        {
          type: 'email',
          name: 'mail',
          label: '学校メールアドレス',
          clabel: '企業メールアドレス',
          value: '',
          datatype: 999,
        },
      ],
      items: [
        {
          name: 'zip',
          type: 'text',
          label: '郵便番号',
          value: null,
          required: true,
          placeholder: '1020074',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'pref',
          type: 'select',
          label: '都道府県',
          value: null,
          options: [],
          required: true,
          comment: '',
        },
        {
          name: 'city',
          type: 'text',
          label: '市区町村',
          value: null,
          required: true,
          placeholder: '千代田区九段南',
        },
        {
          name: 'address',
          type: 'text',
          label: '番地',
          value: null,
          required: false,
          placeholder: '1-5-6',
        },
        {
          name: 'building',
          type: 'text',
          label: '建物名・部屋番号',
          placeholder: 'りそな九段ビル5階 KSフロア',
          value: null,
        },
      ],
      address: {},
      schoolID: null,
      measuredDatas: [
        {
          inputType: 'text',
          label: '契約名',
          name: 'contractName',
          value: null,
          required: false,
        },
        {
          inputType: 'number',
          label: '契約年月日',
          name: 'contractDate',
          values: {
            year: null,
            month: null,
            day: null,
          },
          required: true,
        },
        {
          cycle: [
            {
              label: '月次',
              value: 1,
              checked: true,
            },
            {
              label: '年次',
              value: 2,
              checked: false,
            },
          ],
          inputType: 'radio',
          label: '契約サイクル',
          naem: 'cycle',
          value: null,
          required: true,
        },
        {
          inputType: 'number',
          label: 'テキスト基本回数',
          name: 'textDefaultCount',
          value: null,
          required: true,
        },
        // {
        //   inputType: 'number',
        //   label: 'オンライン基本回数',
        //   naem: 'onlineDefaultCount',
        //   value: null,
        //   required: true,
        // },
      ],
      userItems: [
        {
          label: '名前',
          name: 'username',
          icon: 'fa-solid fa-user',
        },
        {
          label: 'カナ',
          name: 'kana',
          icon: '',
        },
        {
          label: 'メールアドレス',
          name: 'email',
          icon: 'fa-regular fa-envelope',
        },
      ],
      mainValues: {
        id: null,
        username: null,
        kana: null,
        email: null,
      },
      subValues: {
        id: null,
        username: null,
        kana: null,
        email: null,
      },
      updateCount: null,
      unregistUsers: [],
      downloadUrl: null,
      fileName: null,
      planeFileName: null,
      credit: null,
      textCredit: null,
      selectedFilename: 0,
      importFiles: null,
      defectColumn: [],
      userCounts: {
        unregist: 0,
        registed: 0,
      },
      failedReminds: [],
    };
  },
  setup() {
    const mail = ref('');
    const mainUser = ref('');
    const subUser = ref('');

    const rules = {
      // メールはemail形式
      mail: { email },
      mailUser: { required, email },
      mainUser: { email },
      subUser: { email },
    };

    const $v = useVuelidate(rules, {
      mail,
      mainUser,
      subUser,
    });

    return {
      mail,
      mainUser,
      subUser,
      $v,
    };
  },
  created() {
    if (this.helper.master.prefs) {
      this.items[1].options = this.helper.master.prefs;
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putMaster') {
          this.items[1].options = mutation.payload.prefs;
        }
      });
    }
    this.schoolId = cf.convert2Num(this.$route.params.slug);
    this.getSchool();
  },
  watch: {
    bulkChek() {
      if (this.bulkChek) {
        this.filteredUsers.forEach((elem) => {
          this.creditTargets.push(elem);
          this.creditTargetIds.push(elem.id);
        });
      } else {
        this.creditTargets = [];
        this.creditTargetIds = [];
      }
      this.updateTargets();
    },
  },
  computed: {
    ...mapState(['user', 'helper']),
    /**
     * 学校の保持オンラインクレジット
     *   該当校の課金タイプが
     *     1 => 従量課金: school.count
     *     2 => アカウント課金: school.mainUser[0].count.credit
     */
    schoolCredit() {
      const billingType = this.school.billing_type.billing_type;
      let credit = 0;
      if (billingType === 1) credit = this.school.count;
      else if (billingType === 2 && this.school.mainUser[0].count) credit = this.school.mainUser[0].count.credit;
      return credit;
    },
    /**
     * 学校のテキスト利用可能回数
     */
    schoolTextCredit() {
      const billingType = this.school.billing_type.billing_type;
      let credit = 0;
      if (billingType === 1) credit = this.school.textCount;
      return credit;
    },
    /**
     * 該当csvの本登録送信失敗件数
     */
    countFailedActivate() {
      let result = 0;
      if (!this.selectedFilename) return 0;
      const csvUserTotal = this.selectedFilename.users.length;
      const failedUsers = this.selectedFilename.users.filter((u) => !u.sendActivateMail);
      if (csvUserTotal !== failedUsers.length) {
        // 全件未送信の場合は失敗ではないため除外
        result = failedUsers.length;
        // 全件送信済みの場合はfalseを返して送信ボタンをdisabled
        if (failedUsers.length === 0) result = false;
      }
      return result;
    },
    // 検索した条件に該当する利用者のみを絞りこみ
    filteredUsers() {
      const targetIds = this.creditTargets.map((item) => item.id);
      return this.users.filter((user) => !targetIds.includes(user.id));
    },
  },
  methods: {
    judgeDisplay(args) {
      let result = true;
      if (args.cycle) {
        if (this.contents[0].value === 2) result = false;
      }
      return result;
    },
    dragover(e) {
      e.preventDefault();
    },
    optionYear(n) {
      const year = Number(this.formatTimestamp(new Date(), 'YYYY')) + 2 - n;
      return year;
    },
    /** csvファイル追加時 */
    updateFile(e) {
      let file;

      if (e.type === 'change') {
        file = e.target.files[0];
      } else if (e.type === 'drop') {
        file = e.dataTransfer.files[0];
      }

      if (file.type === 'text/csv') {
        this.usersFile = file;
        this.planeFileName = file.name;
      } else {
        alert('ファイル形式が正しくありません、.csv形式のファイルを選択してください。');
        return;
      }


      // 所属タイプが学校の時はファイル名に学年必須
      if (!this.school.is_company && !this.usersFile.name.match(/_\d\./)) {
        alert('ファイル名が正しくありません。\n所属タイプが学校であればファイル名に学年を追加してください。\n（例）~school_1.csv');
        this.deleteSchoolList();
        return false;
      }

      // 所属組織が学校であれば学年をファイル名から取得し利用者に登録
      if (!this.school.is_company) this.schoolYear = this.usersFile.name.match(/_\d\./)[0].replace('_', '').replace('.', '');
      const reader = new FileReader();
      reader.readAsText(this.usersFile);
      reader.onload = this.createList;
    },
    /** CSVファイルからユーザー一覧の配列を作成 */
    createList(e) {
      const usersFileText = e.target.result;
      this.defectColumn = [];

      if (usersFileText.includes('�')) { // 文字コードがshift-jisで文字化けを起こしていたときの再エンコード
        const reader = new FileReader();
        reader.readAsText(this.usersFile, 'shift-jis');
        reader.onload = this.createList;
      }

      const userList = [];
      const usersFileList = usersFileText.split(/\r\n|\r|\n/); // csvファイルから配列を作成

      usersFileList.forEach((user, i) => {
        if (i !== 0) { // ヘッダ行は除外
          const singleData = user.split(',');
          const emptyEelem = singleData.filter((elem) => elem === ''); // 名前、カナ、メールアドレスの項目が一つでもなかったらその行は登録スルー

          if (emptyEelem.length) {
            this.defectColumn.push(i + 1); // あとで登録スキップした行数をアラートで表示するのでここで記録
          } else if (singleData.length > 3) { // 名前、カナ、emeil以外の余計なデータがあった際にもスルー
            this.defectColumn.push(i + 1);
          } else { // データ漏れのない行のみ仮登録へ進める
            userList.push(singleData);
          }
        }
      });

      this.usersFile = userList;
    },

    /** csvよりインポートされたユーザーリストを登録 */
    async registSchoolList() {
      // ↓登録の際にはデータ欠落のない人数＋登録をスキップする人数を表示
      const flag = confirm(`${this.planeFileName}に登録の${this.usersFile.length + this.defectColumn.length}人の利用者を登録します\nよろしいですか？`);
      if (!flag) {
        this.deleteSchoolList();
        return true;
      }
      const data = {
        userList: this.usersFile.reverse(), // フロントでは登録日時順に利用者をソートしているのでファイル逆順に登録してファイルと同じ並び順で見えるようにする
        // 連関テーブル(organizationData)
        organizationData: {
          organization_type: 1, // 利用者は学校に紐づくので
          foreign_id: this.school.id,
        },
      };

      let importResult;

      // importsを登録
      try {
        const params = {
          user_id: this.user.id,
          school_id: this.school.id,
          name: this.planeFileName,
          target_count: this.usersFile.length, // ImportRemindsで使用
        };

        await this.axios({
          method: 'POST',
          url: '/v1/import/set/register',
          params,
        })
          .then((response) => {
            const res = response.data;
            importResult = res;
          })
          .catch((error) => {
            if (error.message) console.log(error.message);
            else console.log(error);
          });
      } catch (error) {
        alert('CSVファイルの登録に失敗しました');
        return true;
      }

      // 所属組織が学校であれば登録情報に学年を追加
      if (!this.school.is_company) data.schoolYear = Number(this.schoolYear);

      this.showLoading();

      data.import_id = importResult.ImportModel.id; // usersテーブルとimportファイルの紐付け

      this.axios({
        method: 'POST',
        url: '/v1/user/set/temporaryRegist',
        data,
      })
        .then((response) => {
          let mes = '選択したcsvファイルに記載された利用者のインポートが完了しました';
          const result = response.data;
          if (result.registUsers.length) { // 登録完了
            mes += `新規仮登録者数: ${result.registUsers.length}名`;
          } else {
            mes += '\n新たに追加された利用者様はいませんでした';
          }
          if (this.defectColumn.length) {
            mes += '\n';
            this.defectColumn.forEach((elem) => {
              mes += `\n${elem}行目に空欄または不要データがあったため登録をスキップしました`;
            });
          }
          if (result.existsUsers.length) { // 登録済み
            mes += '\n\n※以下利用者様は登録済みです';
            result.existsUsers.forEach((u) => { mes += `\n${u.username}さん`; });
          }
          alert(mes);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          alert('csvファイルのユーザー登録に失敗しました');
        })
        .finally(() => {
          // 登録、または失敗どちらでも選択されたファイルを初期化する
          this.deleteSchoolList();
          this.hideLoading();
          // 仮登録者数の更新
          this.countUsers();
          // インポートファイルの更新
          this.getImportFiles(this.school.id);
        });
    },

    // 子コンポーネントから受け取ったデータを格納
    wordReceiveValue(data) {
      const userIdRegex = new RegExp(/^[0-9０-９]{4}(-|ー|−|―|‐|-|ｰ)[0-9０-９]{1,}(-|ー|−|―|‐|-|ｰ)[0-9０-９]{4}$/);
      if (userIdRegex.test(data.value)) {
        const userId = data.value.split(/[-|ー|−|―|‐|-|ｰ]/);
        this.keyword = String(Number(this.toHalfNumber(userId[2]))); // user.id指定
        this.targetColumn = ['id', 'old_id', 'tel']; // 0120-12-3456のtelも引っかかる
      } else {
        this.keyword = data.value;
        this.targetColumn = ['username', 'kana', 'id'];
      }
    },

    // アカウント利用者ごとに
    async getUsers() {
      let newUsers = [];
      const params = {
        id: this.school.id,
        targetColumn: this.keyword && this.keyword !== '' ? this.targetColumn : null,
        keyword: this.keyword && this.keyword !== '' ? this.keyword : null,
        school_year: this.schoolYear,
        noTemporary: true,
      };
      await this.axios({
        method: 'GET',
        url: '/v1/school/get/subject',
        params,
      })
        .then((response) => {
          const res = response.data.subject;
          newUsers = res.users.filter((user) => user.account_type < 11);
          this.users = newUsers;
          if (this.flag.initial && res.billing_type.billing_type === 2) {
            this.allUsers = res.users.filter((user) => user.account_type < 11);
            this.countTemporaryUser(); // 組織の契約が従量課金であれば仮登録の利用者をカウント
            this.flag.initial = false;
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          // 検索をかけた時に、this.usersから値がなくなってしまうと、this.creditTargetsにも保持できないために追加
          const creditTargetIds = this.creditTargets.map((item) => item.id);
          const newUserIds = newUsers.map((user) => user.id);
          const idsToAdd = creditTargetIds.filter((id) => !newUserIds.includes(id));
          const usersToAdd = this.creditTargets.filter((user) => idsToAdd.includes(user.id));
          newUsers = [...newUsers, ...usersToAdd];
          this.users = newUsers;
          if (newUsers.some((user) => user.billing_type && user.billing_type.billing_type === 2)) {
            this.countTemporaryUser(); // 組織の契約が従量課金であれば仮登録の利用者をカウント
          }
          this.flag.loader = false;
          this.flag.exeSearch = true;
          this.updateTargets();
        });
    },

    /** アカウント課金のみ */
    countTemporaryUser() {
      this.allUsers.forEach((user) => {
        if (user.temporary) this.registSituation.temporary.push(user);
        else this.registSituation.completion.push(user);
      });
      this.flag.countTemporaries = false;
    },

    /**
     * アカウント課金　利用者ごとにクレジット追加時に
     */
    updateTargets() {
      // 配列を想定して書き換え
      const disabled = !this.creditTargetIds.length || !this.creditTargetIds.every((id) => typeof id === 'number');
      if (!disabled) {
        this.creditTargets = this.users.filter((user) => this.creditTargetIds.includes(user.id));
      } else {
        this.creditTargets = [];
      }
    },

    // アカウント課金、利用可能回数更新後の回数取得用
    async regetTargets() {
      const params = { // チェックのついている利用者の情報を取得
        ids: [],
      };

      this.creditTargetIds.forEach((id) => {
        params.ids.push(id);
      });

      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.users.data;
          this.creditTargets = [];
          res.forEach((user) => {
            this.creditTargets.push(user);
          });
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    deleteSchoolList() {
      this.planeFileName = null;
      this.usersFile = null;
      this.userList = [];
      const file = document.getElementById('file');
      file.value = '';
    },

    //** 仮登録ユーザー数を取得 */
    async countUsers() {
      this.showLoading();

      try {
        // 仮登録ユーザ
        const unregist = await this.axios({
          method: 'GET',
          url: '/v1/user/get/search',
          params: {
            school_id: this.school.id,
            temporaryOnly: 1,
            accountTypes: [1, 2, 3],
            isSchool: 0,
          },
        });
        const users = unregist.data.users;
        this.unregistUsers = users.data;
        this.userCounts.unregist = users.data.length;
        // this.generateCsv();
      } catch (error) {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      }

      try {
        // 登録済ユーザ
        const registed = await this.axios({
          method: 'GET',
          url: '/v1/user/get/search',
          params: {
            school_id: this.school.id,
            noTemporary: 1,
            accountTypes: [1, 2, 3],
            isSchool: 0,
          },
        });
        const users = registed.data.users;
        this.userCounts.registed = users.data.length;
      } catch (error) {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      }

      this.hideLoading();
    },

    /** 本登録お願いメール配信 */
    activate(users = null) {
      this.showLoading();
      if (users && users.length) {
        this.sendRemindMail(users);
      } else {
        alert('選択したcsvファイルから登録された利用者は存在しませんでした');
        this.hideLoading();
      }
    },

    /** リマインドメール配信 */
    unregistCheck(users = null) {
      this.showLoading();

      // 引数で利用者リストが渡ってきたらリストの利用者のみにリマインド送信
      if (users && users.length) {
        this.sendRemindMail(users, true);
      } else {
        alert('選択したcsvファイルには、仮登録の利用者は存在しませんでした');
        this.hideLoading();
      }

      if (this.unregistUsers.length && !users) {
        this.sendRemindMail(this.unregistUsers, true);
      } else if (!users) {
        alert(`${this.school.name}には仮登録中の利用者が存在しません`);
        this.hideLoading();
      }
    },

    /**
     * 本登録リマインドメールを仮登録ユーザーに送信
     * @params
     *   users:   obj
     *   remind: bool
     */
    sendRemindMail(users, remind) {
      const data = {
        customerList: [],
        school_id: this.school.id,
        isRemind: remind || false,
        import_id: this.selectedFilename.id, // リマインドの送信失敗に必要
      };

      // 対象利用者の抽出（flag === 1[有効]の条件を追加）
      if (remind) data.customerList = users.filter((user) => user.temporary === 1 && user.flag === 1);
      else data.customerList = users.filter((user) => !user.sendActivateMail);

      if (!data.customerList.length) {
        alert('送信対象の利用者さまが見つかりませんでした。');
        this.hideLoading();
        return;
      }

      if (remind) {
        // flag === 1[有効]の条件を追加
        const tempUsers = users.filter((user) => user.temporary === 1 && user.flag === 1);
        const registUsers = users.filter((user) => user.temporary === 0);
        const mes = `本登録: ${registUsers.length}\n仮登録: ${tempUsers.length}\n\n仮登録の利用者さま${tempUsers.length}名へリマインドメールを送信します。よろしいですか？\n`;
        const conf = confirm(mes);
        if (!conf) {
          this.hideLoading();
          return;
        }
      } else {
        const conf = confirm(`選択したcsvファイルの対象利用者さま（本登録お願いメール未送信: ${data.customerList.length}名）へ「本登録お願い」のメールを配信します。よろしいですか？`);
        if (!conf) {
          this.hideLoading();
          return;
        }
      }

      alert('csvファイルへの記載人数によってはメールの送信に時間がかかる場合があります。\nしばらくお待ちください。');

      this.axios({
        method: 'POST',
        url: '/v1/contact/officialRegist/user',
        data,
      })
        .then((response) => {
          const res = response.data;
          console.log(res);
          const mes = `メールの配信が完了しました\n送信成功: ${res.counts.success}\n送信失敗: ${res.counts.failed}`;
          alert(mes);
          // ユーザー数更新
          this.getImportFiles(this.school.id);
          this.countUsers();
          // リマインドメール送信数・日時記録
          if (remind) this.saveImportReminds();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
          this.selectedFilename = 0;
        });
    },

    /** csvダウンロードデータを生成 */
    generateCsv() {
      let csvData = '名前, カナ, メールアドレス\n';
      const unregistUserList = this.selectedFilename.users.filter((user) => user.temporary === 1).reverse(); // 登録時のCSVファイルと並び順揃えるため逆順
      unregistUserList.forEach((user) => {
        csvData += `${user.username},`;
        csvData += `${user.kana},`;
        csvData += `${user.email || '無し'}\n`;
      });

      const now = moment().format('YYYY-MM-DD');
      this.fileName = `${now}_unregistered.csv`;
      const BOM = new Uint8Array([0xEF, 0xBB, 0xBF]); // 文字化け対策
      const rowData = new Blob([BOM, csvData], { type: 'text/csv' });
      this.downloadUrl = (window.URL || window.webkitURL).createObjectURL(rowData);
    },

    /** CSVごとのリマインドメール送信数、日時を保存 */
    async saveImportReminds() {
      if (!this.selectedFilename) return true;
      const tempNum = this.selectedFilename.users.filter((user) => user.temporary === 1).length;
      const params = {
        import_id: this.selectedFilename.id,
        user_id: this.user.id,
        target_count: tempNum,
      };
      this.axios({
        method: 'POST',
        url: '/v1/importReminds/set/register',
        params,
      })
        .then((response) => {
          const res = response.data;
          console.log(res);
          this.getImportFiles(this.school.id);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    fromatDay(day) {
      return moment(day).format('YYYY年MM月DD日 HH時mm分');
    },

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** 子から値を受け取る */
    receiveValue(values) {
      this.flag.readySubmit = values.readySubmit;
      this.address = values.address;
    },

    updateBillingTypeValue(e) {
      this.billing_type = e.target.id;
      // 課金モデルを変更したら
      // 学校タイプを初期（小学校）化してる
      this.contents[1].value = 1;
      if (this.billing_type === 'account') {
        this.flag.csvForm = true;
        this.school.billing_type.billing_type = 2;
      } else {
        this.flag.csvForm = false;
        this.school.billing_type.billing_type = 1;
      }
    },

    // オンライン利用可能回数をファイルに紐づく利用者全員に割り振るため、ファイル内の利用者全員を取得
    updateCreditTargets() {
      this.flag.exeSearch = false;
      this.creditTargets = this.creditTargetFile.users;
    },

    getSchool() {
      const params = {
        id: this.schoolId,
        includeDeleted: 1,
        isUsers: 1, // 事務局より一斉付与の際に学校に紐ずく利用者の情報が必要
      };
      this.axios({
        method: 'GET',
        url: '/v1/school/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.school = res.detail;
          if (this.school.billing_type.billing_type === 2) {
            this.flag.csvForm = true; // csvファイル登録フォーム表示フラグを更新
            this.measuredDatas.pop();
            this.getImportFiles(this.school.id);
            // 各登録数を使用するのはアカウント課金のみ
            this.countUsers();
          }
          if (this.school.contract && this.school.contract.cycle && this.measuredDatas && this.measuredDatas[2]) {
            this.measuredDatas[2].value = this.school.contract.cycle;
          }
          this.setSchoolData();
          this.getContract();
          this.getSchoolMemo();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    // 学校ごとにインポートしたCSVファイルを取得（アカウント課金のみ）
    getImportFiles(schoolId) {
      this.axios({
        method: 'GET',
        url: '/v1/import/get/list',
        params: { school_id: schoolId },
      })
        .then((response) => {
          const res = response.data;
          this.importFiles = res.imports.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    getContract() {
      this.axios({
        method: 'GET',
        url: '/v1/contract/get/detail',
        params: { school_id: this.schoolId },
      })
        .then((response) => {
          this.measuredDatas.forEach((elem) => {
            if (elem.name === 'contractName') {
              elem.value = response.data.contract.name;
            } else if (elem.name === 'contractDate') {
              const date = response.data.contract.contract_date;
              elem.values.year = moment(date).format('YYYY');
              elem.values.month = Number(moment(date).format('MM'));
              elem.values.day = Number(moment(date).format('DD'));
            } else if (elem.name === 'cycle') {
              elem.value = response.data.contract.cycle;
            } else if (elem.name === 'textDefaultCount') {
              elem.value = response.data.contract.base_text;
            }
          });
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    async getSchoolMemo() {
      const params = { school_id: this.schoolId };

      this.axios({
        method: 'GET',
        url: '/v1/schoolMemo/get/detail',
        params,
      })
        .then((response) => {
          if (response.data.detail.data.length) {
            const res = response.data.detail.data[0];
            this.schoolMemo = res.memo;
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    setSchoolData() {
      this.is_company = this.school.is_company;
      this.contents.forEach((row) => {
        if (row.name === 'billing_type' || row.name === 'course') {
          row.value = this.school.billing_type[row.name];
        } else if (row.name === 'mail') {
          // contents.mailはschool.email
          this.mail = this.school.email;
        } else {
          row.value = this.school[row.name];
        }
      });
      if (this.school.address) this.$refs.address.setCustomerData(this.school.address);
      this.flag.loader = false;

      if (this.school.billing_type && this.school.billing_type.billing_type === 2) {
        this.billing_type = 'account';
      } else {
        this.billing_type = 'default';
      }
    },

    setSchoolYear() {
      if (this.user.school) {
        const school = this.school;
        const yearIndex = Object.keys(this.helper.master.schoolYear).findIndex((elem) => this.helper.master.schoolYear[elem].label === school.school_type);
        const schoolYear = this.helper.master.schoolYear[yearIndex].value;
        return schoolYear + 2;
      }
    },

    /** 必須項目の入力をチェック */
    changeFlag() {
      let filledRequired = true;
      this.contents.forEach((row) => {
        if (row.required) {
          if (row.name === 'address' || row.name === 'emailUser') {
            filledRequired = true;
          } else if (row.value === null || row.value === '' || row.value === 0) {
            filledRequired = false;
          }
        }
      });

      if (this.contents[0].value === 1) { // 従量課金の時は契約内容の入力を必須化
        this.measuredDatas.forEach((elem) => {
          if (elem.required) {
            if (elem.name === 'contractDate') {
              Object.keys(elem.values).forEach((data) => {
                if (!elem.values[data]) filledRequired = false;
              });
            } else if (!elem.value) filledRequired = false;
          }
        });
      }

      if (!filledRequired) {
        alert('必須項目が未入力です');
        return;
      }
      if (this.invalids.mail && this.mail !== '') {
        alert('学校のメールアドレスを正しく入力してください。');
        return;
      }
      if (!this.flag.readySubmit) {
        alert('住所の項目に不備があります。');
        return;
      }

      this.flag.isConfirm = !this.flag.isConfirm;
      if (this.flag.isConfirm) {
        const elem = document.getElementById('confirm');
        const position = elem.getBoundingClientRect().top;
        scrollBy(0, position - 77);
      }
    },

    /** 登録 */
    update() {
      this.showLoading();
      const data = {};
      this.contents.forEach((row) => {
        if (row.name !== 'billing_type' && row.name !== 'course') {
          data[row.name] = row.value;
        }
      });
      data.email = this.mail;
      delete data.mail;
      data.id = this.schoolId;
      data.is_company = this.is_company;

      this.axios({
        method: 'POST',
        url: '/v1/school/set/updater',
        data,
      })
        .then((response) => {
          this.schoolID = response.data.schoolID;
          this.updateAddress();
          this.updateBillingType();
          this.updateContract();
          if (this.flag.changeSchoolMemo) this.updateSchoolMemo();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.hideLoading();
        });
    },


    updateAddress() {
      const data = this.address;
      data.id = this.school.address ? this.school.address.id : null;
      data.foreign_id = this.school.id;
      data.model_type = 2;

      const endpoint = data.id ? 'updater' : 'register';
      this.axios({
        method: 'POST',
        url: `/v1/address/set/${endpoint}`,
        data,
      })
        .then(() => {
          alert('更新が完了しました。');
          this.hideLoading();
          this.$router.push('/operator/?tab=schools');
        })
        .catch((error) => {
          console.log(error);
          alert('住所情報の更新に失敗しました。');
          this.hideLoading();
        });
    },

    updateBillingType() {
      const data = {
        id: this.school.billing_type.id,
        billing_type: this.contents[0].value,
        // course: null, // 現在はコースは登録時以外、変動させない
        school_id: this.school.id,
      };
      this.axios({
        method: 'POST',
        url: '/v1/school/billingtype/set/updater',
        data,
      })
        .then(() => {
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          alert('課金モデルの登録に失敗しました。');
        });
    },

    updateContract() {
      const contractDate = moment(`${this.measuredDatas[1].values.year}-${this.measuredDatas[1].values.month}-${this.measuredDatas[1].values.day}`).format('YYYY-MM-DD');
      let expiry;
      if (this.measuredDatas[2].value === 1) {
        expiry = moment(contractDate).add(30, 'd').format('YYYY-MM-DD');
      } else {
        expiry = moment(contractDate).add(1, 'year').format('YYYY-MM-DD');
      }
      const data = {
        school_id: this.schoolID,
        name: this.measuredDatas[0].value,
        contract_date: contractDate,
        expiry,
        cycle: this.measuredDatas[2].value,
      };

      if (this.school.billing_type.billing_type === 1) {
        data.base_text = this.measuredDatas[3].value;
        // data.base_online = this.measuredDatas[4].value;
      } else {
        data.base_text = 0;
        // data.base_online = 0;
      }

      this.axios({
        method: 'POST',
        url: '/v1/contract/set/updater',
        data,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.hideLoading();
          alert('契約情報の更新に失敗しました');
        });
    },

    /**
     * 学校メモを更新/保存
     * すでに学校メモが登録されていれば更新なければ新規作成
     */
    async updateSchoolMemo() {
      const params = {
        school_id: this.school.id,
        memo: this.schoolMemo,
      };

      this.axios({
        method: 'POST',
        url: '/v1/schoolMemo/set/updater',
        params,
      })
        .then((response) => {
          const res = response.data;
          console.log(res);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    editStart(user, name) {
      if (!user) {
        this.editCancel('sub');
        this.flag.registSubUser = true;
        return;
      }
      this.flag.registSubUser = false;
      Object.keys(this[`${name}Values`]).forEach((key) => {
        this[`${name}Values`][key] = user[key];
      });
    },

    editCancel(name) {
      this.flag.registSubUser = false;
      Object.keys(this[`${name}Values`]).forEach((key) => {
        this[`${name}Values`][key] = null;
      });
    },

    updateUser(name) {
      this.showLoading();
      const data = this[`${name}Values`];
      const url = name === 'main' ? 'user' : 'user/sub';
      const endpoint = data.id ? 'update' : 'register';
      const label = data.id ? '更新' : '登録';
      if (!data.username || data.username === '') {
        alert('名前を入力してください');
        this.hideLoading();
        return;
      }
      if (name === 'sub' && (!data.kana || data.kana === '')) {
        alert('カナを入力してください');
        this.hideLoading();
        return;
      }
      if (data.email === '' || !data.email) {
        alert('メールアドレスを入力してください。');
        this.hideLoading();
        return;
      }
      if (!data.id) data.school_id = this.school.id;

      this.axios({
        method: 'POST',
        url: `/v1/${url}/set/${endpoint}`,
        data,
      })
        .then(() => {
          alert(`${label}が完了しました。`);
          this.getSchool();
          this.editCancel(name);
          this.hideLoading();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.hideLoading();
        });
    },

    deleteSubUser(name) {
      this.showLoading();
      const userData = this[`${name}Values`];
      const data = {
        id: userData.id,
        flag: 999,
      };
      console.log(userData);
      this.axios({
        method: 'POST',
        url: '/v1/user/sub/set/update',
        data,
      })
        .then((response) => {
          console.log(response);
          alert('副管理者の削除が完了しました');
          this.getSchool();
          this.hideLoading();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          alert('副管理者の削除に失敗しました。お手数ですが管理者までお知らせください');
          this.hideLoading();
        });
    },

    changeOpenBulkCountHistory() {
      this.flag.openBulkCountHistory = !this.flag.openBulkCountHistory;
      this.getBulkCountHistorys();
    },

    async getBulkCountHistorys() {
      const target = this.school;

      const params = {
        school_id: target.id,
        isImport: 1,
      };

      this.axios({
        method: 'GET',
        url: '/v1/bulkCount/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.data.data;
          this.bulkCountHistorys = [];
          res.forEach((data) => {
            const insertData = {
              date: moment(data.created_at).format('YYYY年MM月DD日'),
              counselor_type: data.counselor_type === 21 ? '医師' : '心理士',
              content: `${data.count}回${data.is_add ? '追加' : '削除'}`,
              import_file: data.import ? data.import.name : '全体',
            };
            this.bulkCountHistorys.push(insertData);
          });
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /**
     * 学校へのオンラインクレジットを付与
     */
    setCredit(addFlag) {
      if (!this.credit || this.credit < 0) {
        this.credit = 1;
        return alert('1以上の値を設定してください');
      }

      if (this.credit > this.schoolCredit && !addFlag) {
        return alert('削除するクレジットが保有クレジットを超えています');
      }

      const label = addFlag ? '追加' : '削除';
      const message = {
        confirm: `「${this.school.name}」へ${this.credit}オンラインクレジット${label}しますか？`,
        done: `「${this.school.name}」へ${this.credit}オンラインクレジット${label}しました`,
      };
      const confirm = window.confirm(message.confirm);
      if (!confirm) return;

      this.showLoading();

      const billingType = this.school.billing_type.billing_type;
      // 従量課金は学校ID、アカウント課金は管理者userId
      const foreignId = billingType === 1
        ? this.school.id
        : this.school.mainUser[0].id;
      const data = {
        billing_type: billingType,
        foreign_id: foreignId,
        count: this.credit,
        is_add: addFlag, // 1:特別加算、0:通常
        credit_user_id: this.user.id,
      };

      this.axios({
        method: 'POST',
        url: '/v1/count/history/set/register',
        data,
      })
        .then(() => {
          this.getSchool();
          this.credit = null;
          if (data.billing_type === 2) { // アカウント課金タイプなら事務局→学校のクレジット編集履歴を記録
            this.registSustainableAddCredit(data); // 記録するのは付与情報なのでdataそのままでOK
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          alert(message.done);
          this.hideLoading();
        });
    },

    setTextCredit(addFlag) {
      if (!this.textCredit || this.textCredit < 0) {
        this.textCredit = 1;
        return alert('1以上の値を設定してください');
      }

      if (this.textCredit > this.schoolTextCredit && !addFlag) {
        return alert('削除するカウントがテキスト利用可能回数を超えています');
      }

      const label = addFlag ? '追加' : '削除';
      const message = {
        confirm: `「${this.school.name}」へテキスト利用可能回数を${this.textCredit}回${label}しますか？`,
        done: `「${this.school.name}」へテキスト利用可能回数を${this.textCredit}回${label}しました`,
      };
      const confirm = window.confirm(message.confirm);
      if (!confirm) return;

      this.showLoading();

      const data = {
        school_id: this.school.id,
        count: this.textCredit,
        is_add: addFlag, // 1:特別加算、0:通常
      };

      this.axios({
        method: 'POST',
        url: '/v1/count/historyText/set/register',
        data,
      })
        .then(() => {
          this.getSchool();
          this.textCredit = null;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          alert(message.done);
          this.hideLoading();
        });
    },

    /**
     * csv選択時にリマインドメールの
     * 送信失敗数とユーザーを取得
     * 本登録お願いメールはcomputedで直接取れる
     */
    async getRemindFailedCount() {
      this.failedReminds = [];
      const params = {
        import_id: this.selectedFilename.id,
      };
      this.axios({
        method: 'GET',
        url: '/v1/remindFailed/get/list',
        params,
      })
        .then((response) => {
          const data = response.data.data.data;
          if (data.length) {
            data.forEach((row) => {
              this.failedReminds.push(row);
            });
          }
          this.generateCsv();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** リマインドメール再送信 */
    resendRemind() {
      const conf = confirm('表示されている利用者さまへリマインドメールの再送信を実行します。よろしいですか？');
      if (!conf) return;

      this.showLoading();

      this.axios({
        method: 'POST',
        url: '/v1/contact/remindResend/user',
        data: {
          failedReminds: this.failedReminds,
          school_id: this.school.id,
          import_id: this.selectedFilename.id,
        },
      })
        .then((response) => {
          const data = response.data;
          const mes = `メールの配信が完了しました\n送信成功: ${data.counts.success}\n送信失敗: ${data.counts.failed}`;
          alert(mes);
          // ユーザー数更新
          this.getImportFiles(this.school.id);
          this.countUsers();
          // 送信失敗件数更新
          this.getRemindFailedCount();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    /** サステナブルクレジット編集を保存 */
    registSustainableAddCredit(datas) {
      const params = {
        regist_user_id: datas.credit_user_id,
        school_id: this.school.id,
        is_add: datas.is_add,
        credit: datas.count,
      };

      this.axios({
        method: 'post',
        url: '/v1/sustainableAddCredit/set/register',
        params,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** サステナブル利用可能回数更新時 */
    updateTargetCount() {
      this.creditTargetFile = null; // 更新が完了したら編集対象のセレクトボックス初期化
    },

    /** userBox背景色指定用 */
    userBoxColor(user) {
      let color;
      if (user.flag !== 1) {
        color = 'gray';
      } else if (user.temporary) {
        color = 'silver';
      } else {
        color = 'white';
      }
      return color;
    },

    /**
     * 調整履歴をクリックした際に、
     * 変更を加えた利用者一覧を表示した状態で利用者検索画面を開く
     */
    async jumpUserList(fileName) {
      const targetFile = this.importFiles.find((elem) => elem.name === fileName);

      if (targetFile) {
        const targetIds = [];
        targetFile.users.forEach((user) => {
          targetIds.push(user.id);
        });
        this.$store.state.search_targets = targetIds;
        const resolvedRoute = await this.$router.resolve({
          name: 'Operator',
        });
        resolvedRoute.href += '/?tab=users&page=1';
        console.log(this.$store.state.search_targets);
        // window.open(resolvedRoute.href, '_blank');
        window.open(resolvedRoute.href);
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" module>
.prev_right {
  display: flex;
  justify-content: right;
}
.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}
.text, .select {
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.notes {
  font-size: 12px;
  color: red;
}
.remind_history {
  span {
    font-size: .85em;
  }

  &_row {
    padding-left: 2em;
  }
  td {
    text-align: center;
  }
}
.bold {
  font-size: 13px;
  font-weight: bold;
}
.required {
  font-size: 12px;
  color: red;
}
.textarea {
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--gray-sub);
  border-radius: 10px;
  resize: vertical;
  padding: 20px;
}
.assistant {
  font-size: 12px;
}
.space {
  padding-top: 6px;
}
.text {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
  &_count {
    width: 20%;
    padding: 13px;
    border-radius: 8px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    $background: var(--gray-sub);
    text-align: right;
  }
}
.icon_gray {
  color: #808080;
  cursor: pointer;
}
.radio {
  padding-top: 8px;
  display: flex;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.select {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  appearance: none;

  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &_box {
    width: 100px;
    padding: 13px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
.tel {
  width: 70px;
  padding: 13px;
}
.users {
  margin-top: -20px;
}
.download {
  // text-align: center;
  &BTN {
    // margin-top: 10px;
    padding: 8px 25px;
    font-weight: bold;
    font-size: 16px;
    background-color: #666;
    color: white;
    border-radius: 22px;
  }
}
.read_csv {
  display: block;
  text-align: center;
  background-color: var(--gray-sub);
  border-radius: 5px;
  position: relative;
  button {
    margin-top: 10px;
  }
  .assistance {
    position: absolute;
    top: 72%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: transparent;
    z-index: 0;
  }
}
.file_input {
  width: 100%;
  padding: 4% 35% 6%;
  background-color: transparent;
  position: relative;
  z-index: 1;
}
.file_input:hover {
  cursor: pointer;
}
.file_name {
  text-align: center;
}
.unregistered {
  font-weight: bold;
  &_large {
    font-size: 1.4em;
  }
}
.search_wrap {
  display: flex;
  .search_word {
    flex: 8.5;
  }
  .search_button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1.5;
  }
}
.check_user {
  display: flex;
  &_box {
    flex: 1;
  }
}
.user_check {
  display: flex;
  &_box {
    margin-right: 1em;
  }
  &_user_box {
    flex-grow: 1;
  }
}
.userCounts {
  dl {
    font-weight: bold;
    display: flex;
    margin: 0;
    line-height: 1.8;
    dt {
      min-width: 6.7em;
      &:after {
        content: ":";
      }
    }
    dd {
      margin-left: 0;
      min-width: 4em;
      text-align: right;
      &:after {
        content: "名";
        margin-left: .2em;
      }
      span {
        font-size: 12px;
      }
    }
    span {
      font-size: 12px;
    }
  }
}
.flex_elem {
  display: flex;
}
.user {
  &_wrap {
    margin-top: 20px;
  }
  li {
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
  &_value {
    i {
      margin-right: 12px;
      color: var(--orange-main);
    }
  }
}

.count {
  display: flex;
  align-items: baseline;
}

.assistance {
  font-size: 14px;
  &.danger {
    color: red;
  }
}
.contract {
  padding-left: 1em;
  &_list {
    padding-top: 20px;
  }
}
.categories {
  $mgn: 40px;
  $gry: #ccc;
  $bdr: 1px solid $gry;
  $pd: .5em;
  width: 75%;
  .category {
    font-size: .8em;
    padding: $mgn/5 0;
    border-bottom: $bdr;
    display: flex;
    margin-block-start: 0;
    margin-block-end: 0;
    // width: 90%;
    &:first-child {
      border-top: $bdr;
    }
     > dt, dd {
      padding: 0 $pd;
      margin-inline-start: 0;
    }
   > dt {
      width: 45%;
    }
    > dd {
      flex: 1;
    }
    .link {
      margin-left: auto;
      padding-left: 1em;
    }

    &.pointer {
      cursor: pointer;
    }

    .width_break {
      word-break: break-word;
    }
  }
  &.max_width {
    width: 100%;
  }
}
.birth {
  flex: 1;
  dl {
    display: flex;
    align-items: center;
    margin: 0;
  }
  dt {
    width: 7em;
    i {
      margin-right: .5em;
    }
  }
  dd {
    flex: 1;
    display: flex;
    margin-left: 1em;
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        margin-left: 1em;
        &:before {
          content: "~";
          margin-right: 1em;
        }
      }
    }
  }
  @include sm-view {
    dl {
      display: block;
      margin: 1em 0 .4em;
    }
    dt {
      font-size: 14px;
    }
    dd {
      margin: 0;
    }
  }
}

.downloadBTN {
  &.disabled {
    opacity: .6;
    cursol: default;
    pointer-events: none;
  }
}
</style>

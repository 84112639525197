<template>
  <div>
    <spacer
      v-if="!flag.enabledBtn"
      :y="2.5"
    />
    <loader-simple :flag="!flag.enabledBtn">
      <div
        v-if="flag.enabledBtn"
        :class="$style.riskApplicationIndicator"
      >
        <basic-btn
          tag="button"
          addClass="risk"
          v-on:click="clickApply"
          :disabled="flag.disabledApply">リスク評価申請
        </basic-btn>
        <div>
          <p
            v-if="riskStatus.currentStatusLabel"
            :class="$style.status"
          ><span>{{ riskStatus.currentStatusLabel }}</span></p>
          <p
            v-if="riskStatus.finalRiskTypeLabel"
            :class="$style.status"
          ><span>{{ riskStatus.finalRiskTypeLabel }}</span></p>
        </div>
      </div>
    </loader-simple>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';

import raf from '@/mixins/riskApplicationFunctions';

export default {
  name: 'risk-application-btn',
  mixins: [
    raf,
  ],
  components: {
    LoaderSimple,
    Spacer,
    BasicBtn,
  },
  props: {
    counseling_type: {
      type: String, // counseling_type.counseling_type
      // 1 => オンライン健康相談
      // 2 => テキスト健康相談
      // 3 => バーチャル健康相談
    },
    foreign_id: {
      type: String, // counseling_type.foreign_id
    },
    target_user_id: {
      type: String,
    },
    callback: {
      type: Function,
    },
  },
  data() {
    return {
      flag: {
        enabledBtn: false,
        disabledApply: false,
      },
      riskStatus: { // riskApplicationFunctions.js#getRiskStatus の返却値
        currentRiskType: null,
        currentStatus: null,
        currentStatusLabel: null,
        finalRiskType: null,
        finalRiskTypeLabel: null,
      },
      myDraftId: null, // 下書きがある場合にセット（riskApplicationId）
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
    canApply() {
      return !this.flag.isHighestLevel && !this.flag.hasOtherDraft;
    },
    childWindowName() {
      return raf.getChildWindowName({
        counselingType: this.counseling_type,
        foreignId: this.foreign_id,
      });
    },
  },
  async created() {
    this.initialize();
  },
  mounted() {
    // コールバック関数をセット
    const functionName = raf.getEmitFunctionName(this.childWindowName);
    if (!window[functionName]) {
      window[functionName] = this.postUpdateRequest;
    }
  },
  beforeUnmount() {
    // コールバック関数を解除
    const functionName = raf.getEmitFunctionName(this.childWindowName);
    if (window[functionName]) {
      delete window[functionName];
    }
  },
  methods: {
    async initialize() {
      // 変数初期化
      this.myDraftId = null;
      this.flag.disabledApply = false;

      // 対象者に関連するリスク評価サマリを取得する
      let response;
      try {
        response = await this.axios({
          method: 'GET',
          url: '/v1/riskApplications/get/summary',
          params: {
            target_user_id: this.target_user_id,
          },
        });
      } catch (e) {
        console.log('Failed to get risk application summary.');
        return;
      }
      if (response.data.data.targetUser.flag === 999) {
        // 対象者が無効化済みの場合はボタンを出さない
        console.log('The user is deleted.');
        return;
      }
      const riskApplications = response.data.data.riskApplications;

      // 最新状態を取得、表示する
      this.riskStatus = raf.getRiskStatus({
        labels: this.helper.master.labels,
        risks: response.data.data.targetUser.risk,
        riskApplications,
      });

      // 新規の申請行為を禁止するか
      this.setDisabledApply({
        riskApplications,
        riskStatus: this.riskStatus,
      });

      // 自分の下書きデータがあるか
      const STATUS_APPLYER_DRAFTED = this.helper.master.labels.riskApplication.status.APPLYER_DRAFTED;
      const myDraft = riskApplications.find((row) => row.applyer_user_id === this.user.id
        && row.status === STATUS_APPLYER_DRAFTED
        && row.counseling_type === Number(this.counseling_type)
        && row.foreign_id === this.foreign_id
        && row.target_user_id === this.target_user_id);
      if (myDraft) {
        this.myDraftId = myDraft.id;
      }
      this.flag.enabledBtn = true;
    },
    setDisabledApply({
      riskApplications = [],
      riskStatus,
    }) {
      const RISK_TYPE = this.helper.master.labels.riskApplication.riskType;
      const RISK_APPLICATION_STATUS = this.helper.master.labels.riskApplication.status;

      // 申請を行わせない条件
      // 1. 他人が下書き中
      // 2. 他人がハイリスク申請中
      // 3. ハイリスク報告済み
      // 4. 自分の申請が進行中

      // 1. 他人が下書き中
      const hasOtherDraft = riskApplications.some((riskApplication) => riskApplication.applyer_user_id !== this.user.id && riskApplication.status === RISK_APPLICATION_STATUS.APPLYER_DRAFTED);
      if (hasOtherDraft) {
        this.flag.disabledApply = true;
        return;
      }
      // 2. 他人がハイリスク申請中
      const hasOtherApplied = riskApplications.some((riskApplication) => riskApplication.applyer_user_id !== this.user.id && RISK_APPLICATION_STATUS.APPLIED <= riskApplication.status && riskApplication.status < RISK_APPLICATION_STATUS.APPROVED);
      if (hasOtherApplied && RISK_TYPE[riskStatus.currentRiskType] && RISK_TYPE[riskStatus.currentRiskType].isHighestLevel) {
        this.flag.disabledApply = true;
        return;
      }
      // 3. ハイリスク報告済み
      if (RISK_TYPE[riskStatus.finalRiskType] && RISK_TYPE[riskStatus.finalRiskType].isHighestLevel) {
        this.flag.disabledApply = true;
      }
      // 4. 自分の申請が進行中
      const myApplications = riskApplications.filter((riskApplication) => riskApplication.applyer_user_id === this.user.id);
      if (myApplications.some((row) => RISK_APPLICATION_STATUS.APPLIED <= row.status && row.status < RISK_APPLICATION_STATUS.APPROVED)) {
        this.flag.disabledApply = true;
      }
    },
    clickApply() {
      // 別タブでフォームを開く
      if (this.myDraftId) {
        // 下書きデータがある場合は下書きをもとに申請フォームを開く
        window.open(`/counselor/risk-applications/apply/?draft_id=${this.myDraftId}`, this.childWindowName);
      } else {
        // 新規申請
        window.open(`/counselor/risk-applications/apply/?counseling_type=${this.counseling_type}&foreign_id=${this.foreign_id}`, this.childWindowName);
      }
    },
    /**
     * このメソッドは子windowでロードされたRiskApplicationFormコンポーネントから実行される。
     * RiskApplicationForm上でリスク報告申請書へのアクションが行われた時、このメソッドを呼び出すことで情報の最新化を行う。
     */
    postUpdateRequest() {
      this.initialize(); // 再初期化する
    },
  },
};
</script>

<style lang="scss" module>
.riskApplicationIndicator {
  margin-top: 2em;
  display: flex;
  align-items: center;
  .status {
    margin-left: 1em;
  }
}
</style>

<template>
  <div>
    <spacer :y="3.5" :class="$style.topSpace" />
    <loader-simple :flag="flag.loader">
      <ul v-if="flag.ready">
        <stack-item>
          <contents-box
            title="リスク報告書"
            id="risk_application_form_top"
          >
            <hr>
            <spacer :y="3" />
            <ul :class="$style.items">
              <stack-item>
                <div :class="$style.column2">
                  <div>
                    <label-box
                      label="利用者ID"
                      color="gray"
                      size="sm"
                      :class="$style.label"
                      smAlign="left"
                    />
                    <p :class="$style.twoCol">
                      <router-link
                        :to="getHistoryLink(client.id)"
                        target="_blank"
                      >
                        <span>{{ [1, 2, 3, 11, 12].includes(client.account_type) ? client.customer_id : `ID: ${client.id}` }}</span>
                      </router-link>
                    </p>
                  </div>
                  <div>
                    <label-box
                      label="ステータス"
                      color="gray"
                      size="sm"
                      :class="$style.label"
                      smAlign="left"
                    />
                    <!-- 承認または差し戻し後 -->
                    <p v-if="isAfterApproval">
                      <span>{{ getFinalStatusLabel() }}</span>
                    </p>
                    <!-- 承認より前 -->
                    <p v-else>
                      <span>{{ riskStatus.currentStatusLabel }}</span>
                      <span
                        v-if="riskStatus.currentStatusLabel && riskStatus.finalRiskTypeLabel"
                        :class="$style.sepalator"
                      >/</span>
                      <span>{{ riskStatus.finalRiskTypeLabel }}</span>
                    </p>
                  </div>
                </div>
              </stack-item>
              <stack-item>
                <div :class="$style.column2">
                  <div>
                    <label-box
                      label="相談回数"
                      color="gray"
                      size="sm"
                      :class="$style.label"
                      smAlign="left"
                    />
                    <p :class="$style.twoCol">
                      <span>{{ `${counselingAmount.text + counselingAmount.online}回（テキスト${counselingAmount.text}回 / オンライン${counselingAmount.online}回）` }}</span>
                    </p>
                  </div>
                  <div>
                    <label-box
                      label="リスク判断"
                      color="gray"
                      size="sm"
                      :class="$style.label"
                      smAlign="left"
                    />
                    <p>
                      <span>{{ getCounselingTypeLabel() }}（{{ displayDate }}）</span>
                    </p>
                  </div>
                </div>
              </stack-item>
              <stack-item>
                <label-box
                  label="WHO-5"
                  color="gray"
                  size="sm"
                  :class="$style.label"
                  smAlign="left"
                />
                <div :class="$style.who5answers">
                  <p
                    v-for="(answer, i) in who5AnserDataList"
                    :key="i"
                    :class="$style.answerItem"
                  >{{ `${answer.count}回目：${answer.score}点（${getFormatedDate(answer.created_at)}）` }}</p>
                </div>
              </stack-item>
              <stack-item>
                <label-box
                  label="報告理由"
                  color="gray"
                  size="sm"
                  :class="$style.label"
                  smAlign="left"
                />
                <div :class="$style.riskType">

                  <!-- 申請時の入力項目 -->
                  <template v-if="showRiskTagInput">
                    <ul :class="$style.radio">
                      <li
                        v-for="(type, key) in selectableRiskType"
                        :key="key"
                      >
                        <div>
                          <input
                            type="radio"
                            :name="`riskType-${key}`"
                            :id="`riskType-${key}`"
                            :value="key"
                            :disabled="isReadonly('riskType')"
                            v-model="input.riskType"
                            @change="changeRiskType"
                          >
                          <label :for="`riskType-${key}`">{{ type.label }}</label>
                        </div>
                      </li>
                    </ul>
                    <ul :class="$style.riskTypeTags">
                      <li
                        v-for="tag in RISK_TYPE_TAGS.STANDARDS"
                        :key="tag.value"
                      >
                        <input
                          type="checkbox"
                          :name="`riskTypeTag-${tag.value}`"
                          :id="`riskTypeTag-${tag.value}`"
                          :value="tag.value"
                          :disabled="isReadonly('riskTypeTags')"
                          v-model="input.riskTypeTags">
                        <label :for="`riskTypeTag-${tag.value}`">{{ tag.label }}</label>
                      </li>
                    </ul>
                    <ul
                      v-if="RISK_TYPE_TAGS.OTHER"
                      :class="$style.riskTypeTags"
                    >
                      <li :class="$style.riskTypeTagNoteRow">
                        <input
                          type="checkbox"
                          :name="`riskTypeTag-${RISK_TYPE_TAGS.OTHER.value}`"
                          :id="`riskTypeTag-${RISK_TYPE_TAGS.OTHER.value}`"
                          :value="RISK_TYPE_TAGS.OTHER.value"
                          :disabled="isReadonly('riskTypeTags')"
                          v-model="input.riskTypeTags"
                        >
                        <label :for="`riskTypeTag-${RISK_TYPE_TAGS.OTHER.value}`">{{ RISK_TYPE_TAGS.OTHER.label }}</label>
                        <input
                          v-show="needRiskTypeTagNote"
                          type="text"
                          name="riskTypeTagNote"
                          :class="$style.text"
                          :disabled="isReadonly('riskTypeTagNote')"
                          placeholder="評価基準を記入してください"
                          v-model.trim="input.riskTypeTagNote"
                        >
                      </li>
                    </ul>
                  </template>

                  <!-- 申請後の表示項目 -->
                  <template v-else>
                    <ul
                      class="n2br"
                      :class="$style.riskReportReasonTitle"
                    >
                      <li>{{ reportReasonTitle }}</li>
                    </ul>
                  </template>

                </div>
              </stack-item>
              <contents-box
                v-if="isReadonly('reportReason')"
                type="inner"
              >
                <div class="n2br"><p>{{ input.reportReason || '-' }}</p></div>
              </contents-box>
              <textarea
                v-else
                name="reportReason"
                id="reportReason"
                rows="8"
                :class="$style.textarea"
                v-model="input.reportReason"
              />

              <spacer :y="3"/>
              <div>
                <label-box
                  label="専門家コメント"
                  color="gray"
                  size="sm"
                  :class="$style.label"
                  smAlign="left"
                />
                <spacer :y="1"/>
                <contents-box
                  v-if="isReadonly('medicalComment')"
                  type="inner"
                >
                  <div class="n2br"><p>{{ input.medicalComment || '-' }}</p></div>
                </contents-box>
                <textarea
                  v-else
                  name="medicalComment"
                  id="medicalComment"
                  rows="8"
                  :class="$style.textarea"
                  v-model="input.medicalComment"
                />
              </div>

              <template v-if="showReviewerComment">
                <spacer :y="3"/>
                <div>
                  <label-box
                    label="事務局補足"
                    color="gray"
                    size="sm"
                    :class="$style.label"
                    smAlign="left"
                  />
                  <spacer :y="1"/>
                  <contents-box
                    v-if="isReadonly('reviewerComment')"
                    type="inner"
                  >
                    <div class="n2br"><p>{{ input.reviewerComment || '（なし）' }}</p></div>
                  </contents-box>
                  <textarea
                    v-else
                    name="reviewerComment"
                    id="reviewerComment"
                    rows="8"
                    :class="$style.textarea"
                    v-model="input.reviewerComment"
                  />
                </div>
              </template>

              <template v-if="showApproverComment">
                <spacer :y="3"/>
                <div>
                  <label-box
                    label="承認者コメント"
                    color="gray"
                    size="sm"
                    :class="$style.label"
                    smAlign="left"
                  />
                  <spacer :y="1"/>
                  <contents-box
                    v-if="isReadonly('approverComment')"
                    type="inner"
                  >
                    <div class="n2br"><p>{{ input.approverComment || '-' }}</p></div>
                  </contents-box>
                  <textarea
                    v-else
                    name="approverComment"
                    id="approverComment"
                    rows="8"
                    :class="$style.textarea"
                    v-model="input.approverComment"
                  />
                </div>
              </template>

              <template v-if="showMessages">
                <spacer :y="3"/>
                <label-box
                  :label="commentLabel"
                  color="gray"
                  size="sm"
                  :class="$style.label"
                  smAlign="left"
                />
                <spacer :y="1"/>
                <loader-simple :flag="flag.loaderCommentSubmitting">
                  <div :class="$style.commentArea">
                    <textarea
                      type="text"
                      :class="$style.input"
                      v-model.trim="inputAfterApproved.managerComment"
                      :disabled="flag.loaderCommentSubmitting"
                    />
                  </div>
                  <basic-btn
                    tag="button"
                    size="xs"
                    v-on:click="clickSubmitMessage"
                  >投稿する</basic-btn>
                </loader-simple>
                <spacer :y="1"/>
                <div :class="$style.riskApplicationMessages">
                  <ul>
                    <li
                      v-for="message in displayMessages"
                      :key="message.id"
                      :class="[$style.message, $style[getCommentClass(message)]]"
                    >
                      <div
                        v-if="message.showNew"
                        :class="[$style.boxTag, $style.new]"
                      >NEW</div>
                      <p>{{ getPostUserDisplayName(message) }}　[{{ formatTimestamp(message.created_at, 'YYYY/MM/DD HH:mm') }}]</p>
                      <div class="n2br">
                        <p>{{ message.message }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </template>

              <spacer :y="3"/>
              <div>
                <label-box
                  label="申請者"
                  color="gray"
                  size="sm"
                  :class="$style.label"
                  smAlign="left"
                />
                <spacer :y="1"/>
                <user-box
                  type="inner"
                  :showId="false"
                  :user="applyerUser"
                  :showAge="false"
                />
              </div>

              <template v-if="isAfterApproval">
                <spacer :y="3"/>
                <div>
                  <label-box
                    label="承認者"
                    color="gray"
                    size="sm"
                    :class="$style.label"
                    smAlign="left"
                  />
                  <spacer :y="1"/>
                  <user-box
                    type="inner"
                    :showId="false"
                    :user="base.approverUser"
                    :showAge="false"
                  />
                </div>
              </template>

              <spacer :y="3"/>

              <!-- 申請者用ボタン -->
              <div v-if="isApplyMode">
                <btn-container v-if="flag.confirm">
                  <basic-btn
                    tag="button"
                    :class="$style.btn"
                    v-on:click="clickBack"
                  >編集画面に戻る</basic-btn>
                  <spacer :x="2"/>
                  <basic-btn
                    tag="button"
                    :class="$style.btn"
                    v-on:click="clickSubmit"
                  >入力内容を確定する</basic-btn>
                </btn-container>
                <btn-container v-else>
                  <basic-btn
                    v-if="myDraftId"
                    tag="button"
                    :class="$style.btn"
                    v-on:click="clickCancel"
                  >申請キャンセル</basic-btn>
                  <spacer v-if="myDraftId" :x="2" />
                  <basic-btn
                    tag="button"
                    :class="$style.btn"
                    v-on:click="clickDraft"
                  >下書き保存</basic-btn>
                  <spacer :x="2"/>
                  <basic-btn
                    tag="button"
                    :class="$style.btn"
                    v-on:click="clickConfirm"
                  >入力内容を確認する</basic-btn>
                </btn-container>
              </div>

              <!-- 事務局用ボタン -->
              <div v-if="isReviewMode">

                <!-- 初期表示時 -->
                <btn-container v-if="!flag.needReviewWithFix">
                  <basic-btn
                    tag="button"
                    :class="$style.btn"
                    v-on:click="clickNeedFix"
                  >申請内容を変更</basic-btn>
                  <spacer :x="2"/>
                  <basic-btn
                    tag="button"
                    :class="$style.btn"
                    v-on:click="clickReviewerSubmit(true)"
                  >内部評価申請</basic-btn>
                </btn-container>

                <!-- 「申請内容を変更」するケース -->
                <template v-else>
                  <btn-container v-if="flag.confirm">
                    <basic-btn
                      tag="button"
                      :class="$style.btn"
                      v-on:click="clickBack"
                    >編集画面に戻る</basic-btn>
                    <spacer :x="2"/>
                    <basic-btn
                      tag="button"
                      :class="$style.btn"
                      v-on:click="clickReviewerSubmit(false)"
                    >内部評価申請</basic-btn>
                  </btn-container>
                  <btn-container v-else>
                    <basic-btn
                      tag="button"
                      :class="$style.btn"
                      v-on:click="clickReviewerDraft"
                    >下書き保存</basic-btn>
                    <spacer :x="2"/>
                    <basic-btn
                      tag="button"
                      :class="$style.btn"
                      v-on:click="clickConfirm"
                    >入力内容を確認する</basic-btn>
                  </btn-container>
                </template>
              </div>

              <!-- 承認者用ボタン -->
              <div v-if="isApprovalMode">

                <!-- 確認モード -->
                <btn-container v-if="flag.confirm">
                  <basic-btn
                    tag="button"
                    :class="$style.btn"
                    v-on:click="clickBack"
                  >編集画面に戻る</basic-btn>
                  <spacer :x="2"/>
                  <basic-btn
                    tag="button"
                    :class="$style.btn"
                    v-on:click="clickApprove"
                  >承認</basic-btn>
                </btn-container>
                <btn-container v-else>

                  <!-- 差し戻しモード -->
                  <template v-if="flag.needInputApproverComment">
                    <basic-btn
                      tag="button"
                      :class="$style.btn"
                      v-on:click="clickCancelDeny"
                    >キャンセル</basic-btn>
                    <spacer :x="2"/>
                    <basic-btn
                      tag="button"
                      :class="$style.btn"
                      v-on:click="clickDeny"
                    >差し戻し</basic-btn>
                  </template>

                  <!-- ノーマルモード -->
                  <template v-else>
                    <basic-btn
                      tag="button"
                      :class="$style.btn"
                      v-on:click="clickApplovalDraft"
                    >下書き保存</basic-btn>
                    <spacer :x="2"/>
                    <basic-btn
                      tag="button"
                      :class="$style.btn"
                      v-on:click="clickConfirm"
                    >入力内容を確認する</basic-btn>
                    <spacer :x="2"/>
                    <basic-btn
                      tag="button"
                      :class="$style.btn"
                      v-on:click="clickDenyReady"
                    >差し戻し</basic-btn>
                  </template>

                </btn-container>
              </div>
            </ul>
          </contents-box>
        </stack-item>
        <spacer :y="3.5"/>
      </ul>
    </loader-simple>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import StackItem from '@/views/components/StackItem.vue';
import UserBox from '@/views/components/UserBox.vue';

import cf from '@/mixins/commonFunctions.js';
import raf from '@/mixins/riskApplicationFunctions';

/** 動作モード */
const MODE = {
  /** 申請（医師心理士） */
  apply: {
    value: 'apply',
    /** 入力可能項目 */
    inputColumns: [
      'riskType',
      'riskTypeTags',
      'riskTypeTagNote',
      'reportReason',
      'medicalComment',
    ],
  },
  /** 調整（事務局） */
  review: {
    value: 'review',
    /** 入力可能項目 */
    inputColumns: [
      'reportReason',
      'medicalComment',
      'reviewerComment',
    ],
  },
  /** 承認|差し戻し（承認者） */
  approval: {
    value: 'approval',
    /** 入力可能項目 */
    inputColumns: [
      'reportReason',
      'medicalComment',
      'approverComment',
    ],
  },
  /** 参照（医師心理士、事務局、承認者） */
  refer: {
    value: 'refer',
    /** 入力可能項目 */
    inputColumns: [],
    validate: () => {}, // 定義不要
  },
};

/** 健康診断種別 */
const COUNSELING_TYPE = {
  /** テキスト健康相談 */
  TEXT: 2,
};

const DATE_FORMAT = 'YYYY/MM/DD HH:mm';

export default {
  name: 'risk-application-form',
  mixins: [
    cf,
    raf,
  ],
  components: {
    BasicBtn,
    BtnContainer,
    ContentsBox,
    LabelBox,
    LoaderSimple,
    Spacer,
    StackItem,
    UserBox,
  },
  props: {
    mode: {
      type: String,
      validator: (value) => Object.keys(MODE).includes(value),
    },
    clientId: {
      type: String,
    },
    counselingType: {
      type: String,
    },
    foreignId: {
      type: String,
    },
    draftId: {
      type: String,
    },
    /** 呼び出し元から受け渡された基礎データ(riskApplication) */
    base: {
      type: Object,
    },
  },
  data() {
    return {
      flag: {
        loader: true,
        ready: false,
        confirm: false, // 情報確定前の確認モード
        loaderCommentSubmitting: false, // コメント送信中
        needReviewWithFix: false, // 内部評価者（事務局）が「申請内容を変更」するか
        needInputApproverComment: false, // 承認者が承認者コメントを入力する必要があるか（＝差し戻しをしようとしているか）
      },
      input: {
        riskType: 1, // 初期選択値=ミドルリスク
        riskTypeTags: [],
        riskTypeTagNote: '',
        reportReason: '',
        medicalComment: '',
        reviewerComment: '',
        approverComment: '',
      },
      inputAfterApproved: { // 承認後のみ使用
        managerComment: '',
      },
      riskStatus: { // riskApplicationFunctions.js#getRiskStatus の返却値
        currentRiskType: null,
        currentStatus: null,
        currentStatusLabel: null,
        finalRiskType: null,
        finalRiskTypeLabel: null,
      },
      selectableRiskType: {}, // 画面上で選択可能なリスク種別
      // APIで取得した情報
      summary: null,
      client: null,
      riskApplications: null,
      counselingAmount: {
        text: 0,
        online: 0,
      },
      who5AnserDataList: null, // 全件
      applyerUser: null,
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
    RISK_TYPE_TAGS() {
      if (!this.input.riskType) {
        return {};
      }
      const RISK_TYPE = this.helper.master.labels.riskApplication.riskType;
      const tags = RISK_TYPE[this.input.riskType].tags;
      return {
        STANDARDS: tags.filter((tag) => !tag.needInput),
        OTHER: tags.find((tag) => tag.needInput),
      };
    },
    needRiskTypeTagNote() {
      if (!this.RISK_TYPE_TAGS.OTHER) {
        return false;
      }
      return this.input.riskTypeTags.includes(this.RISK_TYPE_TAGS.OTHER.value);
    },
    isApplyMode() {
      return this.mode === MODE.apply.value;
    },
    isReviewMode() {
      return this.mode === MODE.review.value;
    },
    isApprovalMode() {
      return this.mode === MODE.approval.value;
    },
    isReferMode() {
      return this.mode === MODE.refer.value;
    },
    myDraftId() {
      // 申請前の下書き状態の場合に有意な値を返却する
      if (!this.isApplyMode) {
        return null;
      }
      return this.base ? this.base.id : null;
    },
    isReadonly() {
      return (propName) => {
        // 読み込み中、あるいは確認状態の場合はすべてreadonly
        if (this.flag.loader || this.flag.confirm) {
          return true;
        }
        // 特別対応: 内部評価者
        if (this.isReviewMode && !this.flag.needReviewWithFix) {
          // 「申請内容を変更」が押されるまではreadonly
          return true;
        }
        // モードごとの入力可能項目によって切り替える
        if (!MODE[this.mode].inputColumns.includes(propName)) {
          // もともと入力可能項目ではないためreadonly
          return true;
        }
        // 特別対応: 承認者の差し戻しモード
        if (this.flag.needInputApproverComment) {
          const allowedPropNames = [
            'approverComment', // 承認者コメントのみ入力許可
          ];
          return !allowedPropNames.includes(propName);
        }
        // 上記以外は入力可能
        return false;
      };
    },
    showRiskTagInput() {
      return this.isApplyMode && !this.flag.loader && !this.flag.confirm;
    },
    reportReasonTitle() {
      // 申請時
      if (this.isApplyMode) {
        return raf.getRiskReportReasonTitle({
          labels: this.helper.master.labels,
          riskType: this.input.riskType,
          riskTypeTags: this.input.riskTypeTags,
          riskTypeTagNote: this.input.riskTypeTagNote,
        });
      }
      // それ以外（情報は確定している）
      return raf.getRiskReportReasonTitle({
        labels: this.helper.master.labels,
        riskType: this.base.risk_type,
        riskTypeTags: JSON.parse(this.base.risk_type_tags),
        riskTypeTagNote: this.base.risk_type_tag_note,
      });
    },
    isTeacher() {
      return this.user && [11, 12].includes(this.user.account_type);
    },
    isCounselor() {
      return this.user && [21, 22].includes(this.user.account_type);
    },
    isOperator() {
      return this.user && [31].includes(this.user.account_type);
    },
    isApprover() {
      if (!this.isCounselor) {
        return false;
      }
      return this.user && this.user.medicalWorker && this.user.medicalWorker.is_risk_application_approver;
    },
    isAfterApproval() {
      return this.base && this.base.status >= this.helper.master.labels.riskApplication.status.APPROVED;
    },
    showReviewerComment() {
      if (!this.base) {
        return false;
      }
      // 事務局コメントの入力時
      if (this.isReviewMode && this.flag.needReviewWithFix) {
        return true;
      }
      // 承認か差し戻しかを判断する際は見せてOK
      if (this.isApprovalMode) {
        return true;
      }
      // 閲覧時はアクターとステータス次第
      if (this.isReferMode) {
        // 事務局と承認者以外には見せない
        if (!this.isOperator && !this.isApprover) {
          return false;
        }
        // 内部評価申請後であれば見せてOK
        return this.base.status >= this.helper.master.labels.riskApplication.status.REVIEWED;
      }
      // 上記以外には見せない
      return false;
    },
    showApproverComment() {
      // 承認者コメント
      if (this.isApprovalMode && this.flag.needInputApproverComment) {
        // 承認アクション、かつ差し戻しの場合は表示する
        return true;
      }
      if (!this.base || this.base.status !== this.helper.master.labels.riskApplication.status.DENIED) {
        // 差し戻し以外の状態では表示しない
        return false;
      }
      // 学校管理サイトでは非表示
      return !this.isTeacher;
    },
    showMessages() {
      if (!this.isReferMode) {
        return false;
      }
      if (this.base.status !== this.helper.master.labels.riskApplication.status.APPROVED) {
        // 承認後以外は非表示
        return false;
      }
      return this.isTeacher || this.isOperator; // 学校管理者または事務局
    },
    displayMessages() {
      if (!this.showMessages) {
        return [];
      }
      if (this.base.messages && this.base.messages.length > 0) {
        const messages = this.base.messages.toReversed(); // created_atの降順に変換
        // 既読フラグを整理して返却
        return messages.map((message) => {
          const clone = cloneDeep(message);
          clone.showNew = !this.user.readflags.riskApplicationMessages.includes(message.id) && this.user.id !== message.user_id; // 既読フラグが立っていない、かつ自分のメッセージではない
          return clone;
        });
      }
      return [];
    },
    commentLabel() {
      return this.isOperator ? '管理者宛コメント' : '事務局宛コメント';
    },
    displayDate() {
      if (!this.summary) {
        return null;
      }
      // キー情報を確定する
      let counselingType;
      let foreignId;
      if (this.counselingType && this.foreignId) {
        counselingType = Number(this.counselingType);
        foreignId = Number(this.foreignId);
      }
      if (this.base) {
        counselingType = this.base.counseling_type;
        foreignId = this.base.foreign_id;
      }
      if (!counselingType || !foreignId) {
        return null;
      }
      // テキスト健康相談
      if (counselingType === COUNSELING_TYPE.TEXT) {
        const target = this.summary.messageGroups.data.find((row) => row.id === foreignId);
        if (!target) {
          return null;
        }
        return moment(target.created_at).format(DATE_FORMAT);
      }
      // オンライン・バーチャル健康相談
      const target = this.summary.reserves.data.find((row) => row.id === foreignId);
      if (!target) {
        return null;
      }
      return moment(target.schedule.date).format(DATE_FORMAT);
    },
  },
  async beforeCreate() {
    // 対象クライアントに関連する既存のリスク評価申請情報を取得する
    const response = await this.axios({
      method: 'GET',
      url: '/v1/riskApplications/get/summary',
      params: {
        target_user_id: this.clientId,
      },
    });

    // 取得情報を表示用に詰める
    await this.setInitialData(response.data.data);

    // 描画
    this.flag.ready = true;
    this.flag.loader = false;

    // 既読管理
    this.registReadFlag();
    this.registMessageReadFlag();
  },
  methods: {
    async setInitialData(summary) {
      // 初期値
      this.summary = summary;
      this.client = summary.targetUser;
      this.riskApplications = summary.riskApplications;
      this.counselingAmount.text = summary.couneslingAmountText;
      this.counselingAmount.online = summary.couneslingAmountOnline;
      this.who5AnserDataList = this.getDisplayWho5Answers(summary);
      this.applyerUser = this.isApplyMode ? this.user : this.base.applyerUser;

      this.riskStatus = raf.getRiskStatus({
        labels: this.helper.master.labels,
        risks: this.client.risk,
        riskApplications: this.riskApplications,
        excludeRiskApplicationId: (() => {
          // 表示中の申請が承認、差し戻し確定している場合は自身を除外しない
          if (this.base && this.base.status >= this.helper.master.labels.riskApplication.status.APPROVED) {
            return null;
          }
          // 上記以外は表示中の申請書を除外してステータス判定する
          if (this.isApplyMode && this.draftId) {
            return this.draftId;
          }
          if (this.base) {
            return this.base.id;
          }
          return null;
        })(),
      });

      // その他、呼び出し元から申請書情報が渡されたときはその値を初期値とする
      if (this.base) {
        this.input = {
          riskType: this.base.risk_type,
          riskTypeTags: JSON.parse(this.base.risk_type_tags),
          riskTypeTagNote: this.base.risk_type_tag_note,
          reportReason: this.base.report_reason,
          medicalComment: this.base.medical_comment,
          reviewerComment: this.base.reviewer_comment,
          approverComment: this.base.approver_comment,
        };
      }

      // 申請前の初期表示対応
      if (this.isApplyMode) {
        // 対象者に対して現在走っている申請情報
        const progressApplications = this.summary.riskApplications.filter((row) => row.status < this.helper.master.labels.riskApplication.status.APPROVED);
        // 対象者に対しての有効な申請前下書き
        const progressDrafts = progressApplications.filter((row) => row.status === this.helper.master.labels.riskApplication.status.APPLYER_DRAFTED);

        // 自身が他の健康相談を起点として下書き済みの場合、新たな申請はさせない
        if (!this.base) {
          // 下書きからではなく新規申請時
          if (progressDrafts.find((draft) => draft.applyer_user_id === this.user.id && `${draft.counseling_type}|${draft.foreign_id}` !== `${this.counselingType}|${this.foreignId}`)) {
            alert('本アカウントは別ページにて編集中です。リスク判断した相談から申請してください。');
            this.$router.push('/counselor/?tab=riskManagement'); // 医師心理士サイト: リスク管理タブ
          }
        }

        // 選択可能なリスク種別を決定する
        const RISK_TYPE = this.helper.master.labels.riskApplication.riskType;
        const finalStatus = this.riskStatus.finalRiskType ? RISK_TYPE[this.riskStatus.finalRiskType] : null; // これまでリスク認定されていない場合は null
        if (finalStatus && finalStatus.isHighestLevel) {
          // 何らかの経路で誤って申請画面が表示された場合へのケア
          alert('現在のリスク評価が最高レベルのため、あらたな申請は行えません。');
          this.$router.push('/counselor/?tab=riskManagement'); // 医師心理士サイト: リスク管理タブ
        }

        // 進行中の申請情報のなかで最も高いリスクレベル
        let highestProgressRiskType = null;
        progressApplications.forEach((row) => {
          // いままさに編集しようとしている下書きはのぞく
          if (this.base && this.base.id === row.id) {
            return;
          }
          const current = row.risk_type;
          if (!highestProgressRiskType || highestProgressRiskType < current) {
            highestProgressRiskType = current;
          }
        });

        Object.keys(RISK_TYPE).forEach((key) => {
          const riskType = RISK_TYPE[key];
          // 選択不可は除外
          if (!riskType.selectable) {
            return;
          }
          // 進行中の申請がある場合
          if (highestProgressRiskType) {
            // 進行中のリスクレベルより下位のレベルは選択不可とする
            if (key <= highestProgressRiskType) {
              return;
            }
          }
          // リスク認定されている場合
          if (finalStatus) {
            // 現在のリスクレベルより下位のレベルは選択不可とする
            if (key <= this.riskStatus.finalRiskType && finalStatus.selectable) {
              return;
            }
          }
          this.selectableRiskType[key] = riskType;
        });

        const selectableRiskTypeKeys = Object.keys(this.selectableRiskType);
        if (selectableRiskTypeKeys.length === 0) {
          alert('進行中のリスク評価申請が最高レベルで行われているため、あらたな申請は行えません。');
          this.$router.push('/counselor/?tab=riskManagement'); // 医師心理士サイト: リスク管理タブ
        }

        // ミドルリスク認定されている場合はハイリスクを初期選択値にする
        if (!selectableRiskTypeKeys.includes(`${this.input.riskType}`)) {
          this.input.riskType = selectableRiskTypeKeys[0];
          this.changeRiskType();
        }
      }

      // 事務局の初期表示対応
      if (this.isReviewMode) {
        // 事務局による下書き状態の場合は「申請内容を変更」を表示する必要がない（入力必須）
        this.flag.needReviewWithFix = this.base.status === this.helper.master.labels.riskApplication.status.REVIEWER_DRAFTED;
      }
    },
    getFormatedDate(date) {
      return moment(date).format(DATE_FORMAT);
    },
    getDisplayWho5Answers(summary) {
      const max = 6; // 表示する最大履歴数

      const who5Ansers = summary.who5Answer;

      // 基点となった健康相談が生じた日付を確定する
      const counselingType = this.base ? this.base.counseling_type : this.counselingType;
      const foreignId = this.base ? this.base.foreign_id : this.foreignId;
      let counselingAt;
      if (counselingType === COUNSELING_TYPE.TEXT) {
        // テキスト健康相談
        const targetMessageGroup = summary.messageGroups.data.find((row) => row.id === Number(foreignId));
        counselingAt = targetMessageGroup ? moment(targetMessageGroup.created_at) : moment(); // テキスト健康相談の場合は相談を受け付けた時刻を基準とする
      } else {
        // オンライン・バーチャル健康相談
        const targetReserve = summary.reserves.data.find((row) => row.id === Number(foreignId));
        counselingAt = targetReserve && targetReserve.schedule ? moment(targetReserve.schedule.date) : moment(); // オンライン・バーチャル健康相談の場合は相談実施の開始時刻を基準とする
      }

      // 表示用に整形
      const targets = cloneDeep(who5Ansers).map((row, index) => {
        const target = {
          score: this._getgWho5AnswerScore(row),
          created_at: row.created_at,
          count: index + 1,
        };
        return target;
      }).filter((row) => moment(row.created_at).isSameOrBefore(counselingAt)); // 基点となった健康相談「以前」のWHO-5に限る

      // 表示対象情報が6件を超えない場合
      if (targets.length <= max) {
        return targets;
      }
      // 表示対象情報が6件を超える場合
      const firstThree = targets.slice(0, 3); // 先頭3件
      const lastThree = targets.slice(-3); // 末尾3件
      return firstThree.concat(lastThree);
    },
    _getgWho5AnswerScore(who5Anser) {
      let result = 0;
      who5Anser.answers.forEach((answer) => {
        result += answer;
      });
      return result;
    },
    getCounselingTypeLabel() {
      if (this.base) {
        return this.helper.master.labels.counselingTypes[this.base.counseling_type];
      }
      return this.helper.master.labels.counselingTypes[this.counselingType];
    },
    changeRiskType() {
      // リスク評価基準をクリアする
      this.input.riskTypeTags = [];
      this.input.riskTypeTagNote = '';
    },
    clickConfirm() {
      // バリデーションチェック
      if (!this._validate()) {
        return;
      }
      this.flag.confirm = true;
      this.scrollTop();
    },
    clickBack() {
      this.flag.confirm = false;
      this.scrollTop();
    },
    clickNeedFix() {
      this.flag.needReviewWithFix = true;
      this.scrollTop();
    },
    async clickDraft() {
      // バリデーションチェック: なし
      // 送信
      this.showLoading();
      try {
        const response = await this.axios({
          method: 'POST',
          url: '/v1/riskApplications/draft',
          data: this._getRequestData(this.myDraftId),
        });
        this.$router.replace(`/counselor/risk-applications/apply/?draft_id=${response.data.data.id}`);
        this.postUpdateRequestToOpener(); // 呼び出し元の表示情報を更新する
        alert('申請内容を下書き保存しました');
      } catch (error) {
        if (error.message) console.log(error.message);
        else console.error(error);
        alert('申請内容の下書き保存に失敗しました');
      } finally {
        this.hideLoading();
      }
    },
    async clickReviewerDraft() {
      // バリデーションチェック: なし
      // 送信
      this.showLoading();
      try {
        await this.axios({
          method: 'POST',
          url: '/v1/riskApplications/review/draft',
          data: this._getReviewInputData(this.base.id),
        });
        this.postUpdateRequestToOpener(); // 呼び出し元の表示情報を更新する
        alert('内部評価内容を下書き保存しました');
      } catch (error) {
        if (error.message) console.log(error.message);
        else console.error(error);
        alert('内部評価内容の下書き保存に失敗しました');
      } finally {
        this.hideLoading();
      }
    },
    async clickReviewerSubmit(needConfirm = false) {
      if (needConfirm) {
        if (!confirm('申請内容のまま内部評価へ進みます。よろしいですか？')) {
          return;
        }
      }
      // 送信
      this.showLoading();
      try {
        await this.axios({
          method: 'POST',
          url: '/v1/riskApplications/review',
          data: this._getReviewInputData(this.base.id),
        });
        this.postUpdateRequestToOpener(); // 呼び出し元の表示情報を更新する
        alert('内部評価を申請しました');
        this.finish();
      } catch (error) {
        if (error.message) console.log(error.message);
        else console.error(error);
        alert('内部評価の申請に失敗しました');
      } finally {
        this.hideLoading();
      }
    },
    async clickSubmit() {
      // 送信
      this.showLoading();
      try {
        await this.axios({
          method: 'POST',
          url: '/v1/riskApplications/apply',
          data: this._getRequestData(this.myDraftId),
        });
        this.postUpdateRequestToOpener(); // 呼び出し元の表示情報を更新する
        alert('リスク評価申請が完了しました');
        this.finish();
      } catch (error) {
        if (error.message) console.log(error.message);
        else console.error(error);
        alert('リスク報告書の申請に失敗しました');
      } finally {
        this.hideLoading();
      }
    },
    async clickCancel() {
      if (!confirm('申請をキャンセルします。よろしいですか？')) {
        return;
      }
      this.showLoading();
      try {
        await this.axios({
          method: 'POST',
          url: '/v1/riskApplications/draft/delete',
          data: {
            id: this.myDraftId,
          },
        });
        this.postUpdateRequestToOpener(); // 呼び出し元の表示情報を更新する
        alert('申請をキャンセルしました');
        this.finish();
      } catch (error) {
        if (error.message) console.log(error.message);
        else console.error(error);
        alert('申請のキャンセルに失敗しました');
      } finally {
        this.hideLoading();
      }
    },
    async clickApplovalDraft() {
      // バリデーションチェック: なし
      // 送信
      this.showLoading();
      try {
        await this.axios({
          method: 'POST',
          url: '/v1/riskApplications/approval/draft',
          data: this._getApprovalInputData(this.base.id),
        });
        this.postUpdateRequestToOpener(); // 呼び出し元の表示情報を更新する
        alert('承認内容を下書き保存しました');
      } catch (error) {
        if (error.message) console.log(error.message);
        else console.error(error);
        alert('承認内容の下書き保存に失敗しました');
      } finally {
        this.hideLoading();
      }
    },
    clickDenyReady() {
      // 報告理由・専門家コメントをもとの状態に戻す
      this.input.reportReason = this.base.report_reason;
      this.input.medicalComment = this.base.medical_comment;
      // 差し戻しモードに移行
      this.flag.needInputApproverComment = true;
      this.scrollTop();
    },
    clickCancelDeny() {
      this.input.approverComment = '';
      this.flag.needInputApproverComment = false;
      this.scrollTop();
    },
    async clickDeny() {
      if (!confirm('申請を差し戻します。よろしいですか？')) {
        return;
      }
      this.showLoading();
      try {
        await this.axios({
          method: 'POST',
          url: '/v1/riskApplications/deny',
          data: this._getApprovalInputData(this.base.id),
        });
        this.postUpdateRequestToOpener(); // 呼び出し元の表示情報を更新する
        alert('申請を差し戻しました');
        this.finish();
      } catch (error) {
        if (error.message) console.log(error.message);
        else console.error(error);
        alert('申請の差し戻しに失敗しました');
      } finally {
        this.hideLoading();
      }
    },
    async clickApprove() {
      this.showLoading();
      try {
        await this.axios({
          method: 'POST',
          url: '/v1/riskApplications/approve',
          data: this._getApprovalInputData(this.base.id),
        });
        this.postUpdateRequestToOpener(); // 呼び出し元の表示情報を更新する
        alert('申請を承認しました');
        this.finish();
      } catch (error) {
        if (error.message) console.log(error.message);
        else console.error(error);
        alert('申請の承認に失敗しました');
      } finally {
        this.hideLoading();
      }
    },
    _getRequestData(id) {
      return {
        id,
        counseling_type: this.counselingType,
        foreign_id: this.foreignId,
        target_user_id: this.clientId,
        risk_type: this.input.riskType,
        risk_type_tags: this.input.riskTypeTags.sort(), // 選択値を一応昇順にしておく
        risk_type_tag_note: this.input.riskTypeTagNote,
        report_reason: this.input.reportReason,
        medical_comment: this.input.medicalComment,
        applyer_user_id: this.user.id,
      };
    },
    _getReviewInputData(id) {
      return {
        id,
        report_reason: this.input.reportReason,
        medical_comment: this.input.medicalComment,
        reviewer_user_id: this.user.id,
        reviewer_comment: this.input.reviewerComment,
      };
    },
    _getApprovalInputData(id) {
      return {
        id,
        approver_user_id: this.user.id,
        report_reason: this.input.reportReason,
        medical_comment: this.input.medicalComment,
        approver_comment: this.input.approverComment,
      };
    },
    getHistoryLink(userId) {
      if (this.isTeacher) {
        return `/teacher/history/${userId}`;
      }
      if (this.isCounselor) {
        return `/counselor/history/user/${userId}`;
      }
      if (this.isOperator) {
        return `/operator/history/${userId}`;
      }
      return null;
    },
    postUpdateRequestToOpener() {
      const myWindowName = window.name;
      const emitFunctionName = raf.getEmitFunctionName(myWindowName);
      if (window.opener && window.opener[emitFunctionName]) {
        window.opener[emitFunctionName]();
      }
    },
    scrollTop() {
      const elem = document.getElementById('risk_application_form_top');
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },
    finish() {
      // MEMO 自画面（タブ）を閉じる対応は一律なしとした
      // // 起動元ウィンドウがある（＝自分は子画面）の場合はウィンドウを閉じる
      // if (window.opener) {
      //   const parentOrigin = new URL(window.opener.location.href).origin; // 親のオリジン
      //   const currentOrigin = window.location.origin; // 現在のウィンドウのオリジン
      //   if (parentOrigin === currentOrigin) {
      //     // 同じドメインからの子画面（当画面）起動 -> 当画面を閉じてOK
      //     window.close();
      //     return;
      //   }
      // }

      // モードごとの遷移元画面に遷移する
      if (this.isApplyMode) {
        this.$router.push('/counselor/?tab=riskManagement'); // 医師心理士サイト: リスク管理タブ
      } else if (this.isReviewMode) {
        this.$router.push('/operator/?tab=riskManagement'); // 事務局サイト: リスク管理タブ
      } else if (this.isApprovalMode) {
        this.$router.push('/counselor/?tab=approval'); // 医師心理士サイト: 評価タブ
      }
    },
    getPostUserDisplayName(message) {
      if (message.user.account_type === 31) {
        return '事務局';
      }
      return message.user.username;
    },
    getCommentClass(message) {
      return message.user.account_type === 31 ? 'operatorsMessage' : 'teachersMessage';
    },
    async clickSubmitMessage() {
      if (this.flag.loaderCommentSubmitting || !this.inputAfterApproved.managerComment) {
        return;
      }
      if (!confirm('コメントを投稿します。よろしいですか？')) {
        return;
      }
      // 投稿
      this.flag.loaderCommentSubmitting = true;
      try {
        await this.axios({
          method: 'POST',
          url: '/v1/riskApplications/message',
          data: {
            risk_application_id: this.base.id,
            user_id: this.user.id,
            message: this.inputAfterApproved.managerComment,
          },
        });
        this.$emit('risk-application-message-posted'); // 呼び出し元の表示情報を更新する
        alert('コメントを投稿しました');
        this.inputAfterApproved.managerComment = '';
      } catch (error) {
        if (error.message) console.log(error.message);
        else console.error(error);
        alert('コメントの投稿に失敗しました');
      } finally {
        this.flag.loaderCommentSubmitting = false;
      }
    },
    async registReadFlag() {
      if (!this.isReferMode) {
        // 参照モード以外では既読管理しない
        return;
      }
      if (!this.isOperator && !this.isTeacher) {
        // 事務局および学校管理者に対してのみ既読管理を機能させる
        return;
      }
      const readflagManagementTargets = [ // 既読管理対象
        // 事務局用
        this.helper.master.labels.riskApplication.status.REVIEWED,
        this.helper.master.labels.riskApplication.status.APPROVER_DRAFTED,
        // 事務局/学校管理者用
        this.helper.master.labels.riskApplication.status.APPROVED,
        this.helper.master.labels.riskApplication.status.DENIED,
      ];
      if (!readflagManagementTargets.includes(this.base.status)) {
        return;
      }
      // 既読状態の場合はリクエスト不要
      if (this.user.readflags.riskApplications.includes(this.base.id)) {
        return;
      }
      // 既読フラグセット
      const data = {
        model_type: 7, // riskApplications
        user_id: this.user.id,
        foreign_id: this.base.id,
      };
      this.axios({
        method: 'POST',
        url: '/v1/readflag/set/register',
        data,
      })
        .then(() => {
          this.$store.dispatch('user/update');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    async registMessageReadFlag() {
      if (this.displayMessages.length === 0) {
        return;
      }
      const targetIds = this.displayMessages.filter((message) => message.showNew).map((message) => message.id);
      if (targetIds.length === 0) {
        return;
      }
      // 既読フラグセット
      await Promise.all(targetIds.map(async (messageId) => {
        const data = {
          model_type: 8, // riskApplicationMessages
          user_id: this.user.id,
          foreign_id: messageId,
        };
        try {
          await this.axios({
            method: 'POST',
            url: '/v1/readflag/set/register',
            data,
          });
        } catch (e) {
          console.error(e);
        }
      }));
    },
    getFinalStatusLabel() {
      if (this.base.status === this.helper.master.labels.riskApplication.status.APPROVED) {
        return '承認（リスク報告済）';
      }
      if (this.base.status === this.helper.master.labels.riskApplication.status.DENIED) {
        return '差し戻し';
      }
      return null;
    },
    _validate() {
      // 申請
      if (this.isApplyMode) {
        // MEMO: 下書きの際は当メソッドは呼ばれない想定
        if (this.input.riskTypeTags.length === 0) {
          alert('リスク報告基準が未入力です。');
          return false;
        }
        if (this.needRiskTypeTagNote && !this.input.riskTypeTagNote) {
          alert('リスク報告基準「その他」の具体的な内容が未入力です。');
          return false;
        }
        if (!this.input.reportReason) {
          alert('報告理由が未入力です。');
          return false;
        }
        if (!this.input.medicalComment) {
          alert('専門家コメントが未入力です。');
          return false;
        }
      }
      // 内部評価申請
      if (this.isReviewMode) {
        if (!this.input.reportReason) {
          alert('報告理由が未入力です。');
          return false;
        }
        if (!this.input.medicalComment) {
          alert('専門家コメントが未入力です。');
          return false;
        }
        // MEMO: 事務局補足は未入力でもOKとする
      }
      // 承認
      if (this.isApprovalMode) {
        if (!this.input.reportReason) {
          alert('報告理由が未入力です。');
          return false;
        }
        if (!this.input.medicalComment) {
          alert('専門家コメントが未入力です。');
          return false;
        }
        // MEMO: 承認者コメントは差し戻し時のみ入力可能のためここではチェックしない
      }
      return true;
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
@include sm-view {
  .topSpace {
    height: 0em;
  }
}

.column2 {
  display: flex;
  width: 100%;
  @include sm-view {
    display: inherit;
    width: inherit;
  }
  >div {
    display: flex;
    width: 50%;
    @include sm-view {
      display: inherit;
      width: inherit;
    }
  }
}
.items {
  li {
    display: flex;
    align-items: flex-start;
    @include sm-view {
      font-size: 14px;
      display: block;
    }
    .content {
      margin-top: 3px;
      flex: 1;
    }
  }
}
.label {
  display: flex;
  width: 120px;
  min-width: 120px;
  margin-right: 20px;
    align-items: center;
    justify-content: center;
  @include sm-view {
    width: 100%;
    margin-bottom: 8px;
  }
}
.who5answers {
  display: flex;
  flex-wrap: wrap;
  .answerItem {
    width: 17rem;
  }
}
.radio {
  padding-top: 4px;
  display: flex;
  li {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.riskType {
  display: block !important;
  width: 100%;
}
.riskTypeTags {
  display: flex;
  flex-wrap: wrap;
  row-gap: .5em;
  margin: .5em 0 .5em .5em;
  li {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.textarea {
  width: 100%;
  resize: vertical;
  border: none;
  outline: none;
  background-color: var(--gray-sub);
  border-radius: 8px;
  padding: 1em;
  line-height: 1.8;
}
.text {
  width: 100%;
  padding: 4px;
  margin: 0 .25em;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.riskReportReasonTitle {
  margin-bottom: .8em;
  > li {
    display: block;
  }
}
@-moz-document url-prefix() {
  // firefox向け
  .riskReportReasonTitle {
    > li {
      width: calc(100% - 140px); // 「報告理由」の幅調整
      @include sm-view {
        width: inherit;
      }
    }
  }
}
.sepalator {
  margin: 0 .2em;
}
.twoCol {
  padding-right: 1.5rem;
  @include sm-view {
    padding-right: inherit;
  }
}
.riskApplicationMessages {
  li {
    display: block;
    padding: 12px;
    border-radius: 4px;
    @include sm-view {
      font-size: 13px;
    }
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    &.operatorsMessage {
      background-color: rgba(var(--blue-main-rgb), 0.2);
    }
    &.teachersMessage {
      background-color: rgba(var(--orange-main-rgb), .15);
    }
  }
}
.commentArea {
  display: flex;
  align-items: center;
  @include sm-view {
    margin-top: 10px;
    text-align: right;
  }
  .input {
    width: 100%;
    padding: 13px;
    border-radius: 8px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
  }
  .messagePen {
    font-size: 25px;
    font-weight: bold;
    color: var(--gray-side);
    cursor: pointer;
    margin-left: 20px;
    @include sm-view {
      margin-top: 10px;
    }
  }
}
.riskTypeTagNoteRow {
  width: 100%;
  >label {
    white-space: nowrap;
  }
}
.message {
  position: relative;
}
.boxTag {
  position: absolute;
  top: -3px;
  left: auto;
  right: -8px;
  z-index: 1;
  font-weight: bold;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 4px;

  @include sm-view {
    padding: 3px 7px 2px;
    font-size: 11px;
    right: -4px;
  }

  &.new {
    background-color: var(--pink);
    color: #fff;
  }
}
</style>

<template>
  <div>
    <spacer :y="3.5" :class="$style.topSpace" />
    <loader-simple :flag="flag.loader">
      <div v-if="!flag.loader">
        <tab-nav
          :items="adjustTabItems"
          :isActive="isActive"
          v-on:sendIsActiveValue="receiveIsActiveValue"/>
        <div>
          <div
            v-for="(tab, key) in adjustTabItems"
            :key="tab.name"
            :id="`panel-${tab.name}`"
            :aria-labelledby="`tab-${tab.name}`"
            :aria-hidden="key !== isActive"
          >
            <component v-if="key === isActive" :is="`${tab.name}`" :tab="tab" />
          </div>
        </div>
      </div>
    </loader-simple>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import RoundImg from '@/views/components/RoundImg.vue';
import TabNav from '@/views/components/TabNav';
import Spacer from '@/views/components/Spacer.vue';
import Home from '@/views/pages/Operator/tabs/Home.vue';
import Inquiries from '@/views/pages/Operator/tabs/Inquiries.vue';
import Information from '@/views/pages/Operator/tabs/Information.vue';
import OnlineCounseling from '@/views/pages/Operator/tabs/OnlineCounseling.vue';
import TextCounseling from '@/views/pages/Operator/tabs/TextCounseling.vue';
import UserList from '@/views/pages/Operator/tabs/UserList.vue';
import SchoolList from '@/views/pages/Operator/tabs/SchoolList.vue';
import CounselorList from '@/views/pages/Operator/tabs/CounselorList.vue';
import Counseling from '@/views/pages/Operator/tabs/Counseling.vue';
import History from '@/views/pages/Operator/tabs/History.vue';
import Holidays from '@/views/pages/Operator/tabs/Holidays.vue';
import AnalyticsSelect from '@/views/pages/Operator/tabs/AnalyticsSelect.vue';
import Message from '@/views/pages/Operator/tabs/Message.vue';
import Units from '@/views/pages/Operator/tabs/Units.vue';
import RiskManagement from '@/views/pages/Operator/tabs/RiskManagement.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'operator',
  mixins: [cf],
  components: {
    LoaderSimple,
    RoundImg,
    TabNav,
    Spacer,
    Home,
    Inquiries,
    Information,
    OnlineCounseling,
    TextCounseling,
    UserList,
    SchoolList,
    CounselorList,
    Counseling,
    History,
    Holidays,
    Message,
    Units,
    AnalyticsSelect,
    RiskManagement,
  },
  data() {
    return {
      flag: {
        loader: true,
      },
      tabItems: {
        home: {
          label: 'ホーム',
          name: 'home',
          new: false,
        },
        online: {
          label: '予約',
          name: 'online-counseling',
          new: false,
        },
        history: {
          label: 'オンライン',
          name: 'history',
          new: false,
          allowViewAccountingAccount: true,
        },
        text: {
          label: 'テキスト',
          name: 'text-counseling',
          new: false,
        },
        users: {
          label: 'アカウント',
          name: 'user-list',
          new: false,
        },
        riskManagement: {
          label: 'リスク管理',
          name: 'risk-management',
          new: false,
        },
        units: {
          label: 'グループ',
          name: 'units',
          new: false,
        },
        schools: {
          label: '学校',
          name: 'school-list',
          new: false,
        },
        counselors: {
          label: '医師・心理士',
          name: 'counselor-list',
          new: false,
        },
        counseling: {
          label: 'シフト登録',
          name: 'counseling',
          new: false,
        },
        information: {
          label: 'お知らせ',
          name: 'information',
          new: false,
        },
        message: {
          label: 'メッセージ',
          name: 'message',
          new: false,
        },
        holidays: {
          label: '祝日',
          name: 'holidays',
          new: false,
        },
        analyticsselect: {
          label: 'アナリティクス',
          name: 'analyticsSelect',
          new: false,
        },
      },
      isActive: 'home',
    };
  },
  watch: {
    $route() {
      this.changeTab();
    },
    adjustTabItems() {
      this.changeTab();
    },
  },
  created() {
    if (this.user.email) {
      if (this.user.account_type < 30) this.redirect2top();
      else this.changeTab();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          if (this.user.account_type < 30) this.redirect2top();
          else this.changeTab();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
    adjustTabItems() {
      const isAccountingAccount = this.helper.isAccountingAccount || null;

      // 会計アカウントの場合、会計アカウントのみのタブを表示
      if (isAccountingAccount) {
        return Object.fromEntries(
          Object.entries(this.tabItems).filter(([tabName, tabObj]) => tabObj.allowViewAccountingAccount) // eslint-disable-line
        );
      }
      return this.tabItems;
    },
  },
  methods: {
    changeTab() {
      // クエリでどのタブを表示させるか判定
      const query = this.$route.query;
      const tabNames = Object.keys(this.adjustTabItems);
      if (query.tab && tabNames.includes(query.tab)) this.isActive = query.tab;
      else if (this.helper.isAccountingAccount) this.receiveIsActiveValue('history');
      else this.isActive = 'home';
      this.flag.loader = false;
    },
    receiveIsActiveValue(name) {
      this.isActive = name;
      this.$router.replace(`/operator/?tab=${name}`);
    },
    /** ログイン済みユーザはトップリダイレクト */
    redirect2top() {
      const ls = cf.getLocalStorage('wtt');
      // ログアウト時にログインへリダイレクトされた場合の対応
      if (!ls.jwt) return;

      const accountType = this.user.account_type;
      const customerTypes = [1, 2, 3];
      const counselorTypes = [21, 22];
      const teacherTypes = [11, 12];
      const operatorTypes = [31];
      let path = '';
      if (this.user.account_type) {
        if (customerTypes.includes(accountType)) {
          path = { path: '/' };
        } else if (counselorTypes.includes(accountType)) {
          path = { path: '/counselor/' };
        } else if (teacherTypes.includes(accountType)) {
          path = { path: '/teacher/' };
        } else if (operatorTypes.includes(accountType)) {
          path = { path: '/operator/' };
        }
      }
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" module>
@include sm-view {
  .topSpace {
    height: 0em;
  }
}
</style>
